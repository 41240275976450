.vod-card {
    @include light-card;
    display: flex;
    align-items: center;
    padding: 15px;

    .vod-label-container {
        display: flex;
        flex-direction: column;
        padding-right: 15px;

        .vod-label {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            font-weight: $weight-medium;
        }

        .vod-link {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            color: $blue;
            margin-top: 3px;
            text-decoration: none;
            word-break: break-all;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .circle-button {
        width: 30px;
        height: 30px;
        margin-left: auto;
        flex-shrink: 0;

        .icon {
            width: 16px;
            height: 16px;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .vod-label-container {
            display: flex;
            flex-direction: column;
            padding-right: 15px;

            .vod-label {
                @include font-size(14);
                @include line-height(14);
            }

            .vod-link {
                @include font-size(12);
                @include line-height(12);
            }
        }
    }
}
