.partners-navigation {
    display: flex;

    .partners-link {
        display: flex;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        width: 220px;
        height: 70px;
        @include headerFont;
        @include font-size(42);
        @include line-height(42);
        color: $white;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include transition;

        &:hover {
            color: $blue;
        }

        &.active {
            color: $base-dark-tone;
            background-color: $off-white;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .partners-link {
            width: 150px;
            @include font-size(32);
            @include line-height(32);
            padding: 10px;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .partners-link {
            height: 60px;
            @include font-size(28);
            @include line-height(28);
        }
    }

    @media screen and (max-width: 400px) {
        .partners-link {
            height: 50px;
            @include font-size(24);
            @include line-height(24);
        }
    }
}
