.subscription-referral-popup-form {
    display: flex;
    flex-direction: column;

    .referral-options {
        padding: $base-padding-half;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        max-height: calc(100vh - 62px - #{$base-padding} - 90px);
        overflow: auto;
        .referral-option {
            padding: 16px 20px;
            border: $border-thickness solid $off-white;
            border-radius: $border-radius;
            @include transition;
            cursor: pointer;

            &:hover,
            &.active {
                border-color: $blue;
                background-color: $light-blue;
            }

            .title {
                @include font-size(32);
                @include line-height(32);
                font-family: $header-font-family;
                margin: 0;
                text-align: center;
            }

            .description {
                @include font-size(14);
                @include line-height(18);
                margin: 0;
                margin-top: 3px;
                text-align: center;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            padding: 10px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 5px;

            .referral-option {
                padding: 6px 10px;

                .title {
                    @include font-size(24);
                    @include line-height(24);
                }

                .description {
                    @include font-size(12);
                    @include line-height(16);
                }
            }
        }
    }

    .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $base-padding-half;
        border-top: $border-thickness solid $off-white;

        .button {
            @include font-size(16);
            width: 250px;
            height: 50px;
            border-radius: 25px;
        }
    }
}
