.whats-next-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $base-padding-half;
    width: calc(100% - (#{$base-grid-padding} * 2));
    max-width: 980px;
    margin: $base-grid-padding;
    margin-top: 0;

    .form-header {
        grid-column: span 4;
        display: flex;
        flex-direction: column;

        .form-heading {
            margin: 0;
            text-transform: uppercase;
            font-weight: $weight-black;
            @include font-size(18);
            @include line-height(24);
        }
    }

    @import "../whats-next-card.scss";

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .form-header {
            grid-column: span 2;
        }

        .whats-next-card {
            .thumbnail {
                aspect-ratio: 2/1;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        gap: 10px;
        width: calc(100% - (#{$base-padding-half} * 2));
        margin: $base-padding-half;
        margin-top: 0;

        .form-header {
            .form-heading {
                @include font-size(16);
                @include line-height(20);
            }
        }

        .whats-next-card {
            .thumbnail {
                aspect-ratio: 16/9;

                .icon {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .form-header {
            grid-column: span 1;
        }

        .whats-next-card {
            .thumbnail {
                aspect-ratio: unset;
                height: 120px;
            }
        }
    }
}
