.scouting-report-search-card {
    @include light-card;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding: $base-padding-half;
    margin: $base-grid-padding;
    margin-bottom: 0;

    .title {
        @include headerFont;
        @include font-size(28);
        @include line-height(28);
        margin: 0;
    }

    .scouting-report-search-form {
        display: flex;
        flex-direction: row;

        .fields-container {
            margin-top: $base-padding-half;
            width: calc(100% - 220px);
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: $base-padding-half;

            .span-2 {
                grid-column: span 2;
            }

            .input-field {
                border-color: $off-white;
                @include font-size(14);
            }

            .select__control {
                border-color: $off-white;
            }
        }

        .button {
            width: 100%;
            max-width: 200px;
            margin-left: $base-padding-half;
            height: 50px;
            border-radius: 25px;
            align-self: flex-end;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        margin: $base-padding-half;
        margin-bottom: 0;

        .title {
            @include font-size(24);
            @include line-height(24);
        }

        .scouting-report-search-form {
            flex-direction: column;
            .fields-container {
                width: 100%;
                grid-template-columns: repeat(1, minmax(0, 1fr));

                .span-2 {
                    grid-column: span 1;
                }
            }

            .button {
                margin-top: $base-padding-half;
                height: 40px;
                margin-left: 0;
                align-self: flex-start;
            }
        }
    }
}
