.navigation-bar {
    width: 100%;
    height: $navigation-height;
    background-color: transparent;
    position: fixed;
    @include transition;
    top: 0;
    z-index: 1000;
    @include box-shadow(rgba(8, 8, 14, 0), 10px, 5px, 0px, 0px);

    &.active {
        background-color: $white;
        @include baseShadowOnWhite;
    }

    .container {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .mobile-nav-button {
            width: 40px;
            height: 40px;
            background: transparent;
            outline: 0;
            border: 0;
            cursor: pointer;
            display: none;
            align-items: center;
            justify-content: center;
            -webkit-tap-highlight-color: transparent;
            margin-right: 20px;
            .icon {
                fill: $black;
                @include transition;
            }
            &:hover {
                .icon {
                    fill: $blue;
                }
            }
            @media screen and (max-width: $mobile-nav-breakpoint) {
                display: flex;
            }
        }

        .brand {
            flex-shrink: 0;
            margin-right: $base-padding-half;

            .spin-logo {
                fill: $base-dark-tone;

                &.mobile {
                    display: none;
                }
            }
        }

        @import './navigation-links.scss';
        @import './user-controls.scss';
        @import './navigation-media-queries.scss';
        @import './nav-dropdown.scss';
    }

    @media screen and (max-width: $mobile-breakpoint) {
        height: $mobile-navigation-height;
    }
}

.navigation-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: $base-dark-tone;
    z-index: 1001;
    opacity: 0.8;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    cursor: pointer;

    @include transition;

    &.active {
        display: block;
        @include fade-in-modal;
    }
}

@import './mobile-navigation.scss';
