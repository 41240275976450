.pricing-card {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 100%;
    background-color: $white;
    border-radius: $border-radius;

    &.margin-top {
        margin-top: 60px;
    }

    .card-highlight {
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        background-color: $white;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        height: 60px;

        @include headerFont;
        @include font-size(32);
        @include line-height(60);
    }

    .card-highlight + .pricing-card-head {
        border-radius: 0;
    }

    @import "./pricing-card-head.scss";
    @import "./pricing-features-list.scss";

    .button-container {
        width: 100%;
        padding: $base-padding-half;
        margin-top: auto;

        .button {
            width: 100%;
            height: 60px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin: 0 auto;
        max-width: 500px;

        &.margin-top {
            margin-top: 0;
        }

        .button-container {
            .button {
                max-width: 300px;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .button-container {
            padding-top: 0;
            .button {
                max-width: 240px;
                height: 50px;
                @include font-size(14);
                @include line-height(14);
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .button-container {
            .button {
                height: 40px;
                @include font-size(12);
                @include line-height(12);
            }
        }
    }
}
