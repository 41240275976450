.invite-response-content {
    display: grid;
    gap: $base-padding-half;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .team-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .team-name {
            font-family: $header-font-family;
            @include font-size(24);
            @include line-height(28);
            margin: 0;
        }

        .game {
            display: flex;
            gap: 5px;
            align-items: center;
        }

        .high-school {
            a {
                @include font-size(16);
                font-weight: $weight-medium;
                @include transition;
                color: $blue;
                text-decoration: none;

                &:hover {
                    color: $blue-hover;
                    text-decoration: underline;
                }
            }
        }
    }

    .medium-button {
        margin-left: auto;
        max-width: 240px;
        width: 100%;
    }

    .button-container {
        display: grid;
        gap: $base-padding-half;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .full {
            grid-column: span 1;
        }

        .button {
            width: 100%;
            max-width: 250px;
            margin: 0 auto;
            height: 50px;
            border-radius: 25px;
            @include font-size(16);
        }

        @media screen and (max-width: $mobile-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
