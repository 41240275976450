.account-settings-card {
    margin: $base-grid-padding;
    @extend .dashboard-card;
    height: 100%;

    @import "./account/account.scss";
    @import "./notifications/notification-preferences.scss";
    @import "./messages/message-preferences.scss";
    @import "./invites/colleague-invites.scss";

    .title {
        @include font-size(18);
        @include line-height(18);
        margin: 0;
        text-transform: uppercase;
    }

    .content {
        padding: $base-padding-half;
    }

    @media screen and (max-width: $small-breakpoint) {
        margin: $base-padding-half;
        .content {
            padding: $base-padding-half;
        }
        .title {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            text-transform: uppercase;
        }
    }
}
