.feature-card {
    display: flex;
    flex-direction: row;
    border-radius: $border-radius;
    text-decoration: none;
    color: $base-dark-tone;
    @include baseShadowOnWhite;

    @import './card-content.scss';
    @import './info-panel.scss';

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
    }
}
