.page {
    margin-top: $navigation-height;
    padding-top: $base-padding;
    padding-bottom: $base-padding;

    .grid {
        display: grid;
        gap: $base-grid-padding;

        &.one-col {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        &.two-col {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        &.three-col {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        &.four-col {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .span-one {
            grid-column: span 1;
        }
        .span-two {
            grid-column: span 2;
        }
        .span-three {
            grid-column: span 3;
        }
        .span-four {
            grid-column: span 4;
        }

        .align-center {
            align-self: center;
        }

        .justify-center {
            justify-self: center;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .light-card {
        @include light-card;

        .title-container {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: $base-grid-padding;

            .title {
                width: 100%;
                @include headerFont;
                @include font-size(48);
                @include line-height(48);
                margin: 0;
            }
        }

        .generic-content {
            color: $base-text-on-white;
            @import "./generic-formatting.scss";
        }
    }

    .dark-card {
        @extend .light-card;
        @include dark-card;

        .title-container {
            width: 100%;
            display: flex;
            align-items: center;

            .title {
                color: $white;
            }
        }

        .generic-content {
            color: $base-text-on-dark-tone;
        }
    }

    @media screen and (max-width: $large-breakpoint) {
        .grid {
            &.two-col {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            &.three-col {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
            &.four-col {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            .span-two {
                grid-column: span 1;
            }
            .span-four {
                grid-column: span 3;
            }
        }
    }
    @media screen and (max-width: $medium-breakpoint) {
        .grid {
            &.three-col {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            &.four-col {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            .span-three {
                grid-column: span 1;
            }
            .span-four {
                grid-column: span 2;
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        margin-top: $mobile-navigation-height;
        padding-top: $base-padding-half;
        padding-bottom: $base-padding-half;

        .grid {
            gap: $base-padding-half;

            &.four-col {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }

            .span-four {
                grid-column: span 1;
            }
        }

        .light-card {
            .title-container {
                margin-bottom: $base-padding-half;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
    }
}
