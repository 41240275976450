.radio-button-field-container {
    display: flex;
    align-items: flex-start;

    .radio-button-label {
        display: flex;
        align-items: center;
        cursor: pointer;
        @include font-size(14);
        @include line-height(16);
        white-space: pre-line;

        .radio-button {
            appearance: none;
            background-color: $white;
            margin: 0;
            width: 24px;
            height: 24px;
            border: $border-thickness solid $off-white;
            border-radius: 100%;
            margin-right: 10px;
            display: grid;
            place-content: center;
            flex-shrink: 0;

            &::before {
                content: "";
                width: 14px;
                height: 14px;
                border-radius: 100%;
                transform: scale(0);
                @include transition;
                box-shadow: inset 14px 14px $blue;
            }

            &:checked {
                &::before {
                    transform: scale(1);
                }
            }
        }
    }
}
