.event-page-card {
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    margin-bottom: $base-vertical-padding;

    .title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
    }

    .event-description {
        margin: 0;
        margin-top: 20px;
        @include font-size(15);
        @include line-height(22);
    }

    @import "./formatting.scss";

    @media screen and (max-width: $large-breakpoint) {
        margin-bottom: $large-vertical-padding;
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin-bottom: 0;
    }
}
