.page-header {
    margin-top: $navigation-height;
    width: 100%;
    background-color: $base-dark-tone;
    padding: $base-vertical-padding 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.events-page-header {
        background-image: url('/images/SPIN_Events_Header_Background.jpg');
    }

    &.players-page-header {
        background-image: url('/images/SPIN_Players_Header_Background.jpg');
    }

    &.colleges-page-header {
        background-image: url('/images/SPIN_Colleges_Header_Background.jpg');
    }

    &.leaderboards-page-header {
        background-image: url('/images/SPIN_Leaderboards_Header_Background.jpg');
    }

    &.pricing-page-header {
        background-image: url('/images/SPIN_Pricing_Header_Background.jpg');
        text-align: center;
    }

    &.spinandoah-event-page-header {
        background-image: url('/images/SPINANDOAH-Header-v2.jpg');
        text-align: center;
    }

    &.helix-event-page-header {
        background-image: url('/images/helix-landing-header.jpg');
        text-align: center;
    }

    &.c2c-event-page-header {
        background-image: url('/images/SPIN_Events_Header_Background.jpg');
        text-align: center;
    }

    &.scholarship-page-header {
        background-image: url('/images/Scholarships-Hero.jpg');
        text-align: center;
    }

    &.partners-page-header {
        background-image: url('/images/partners-page-header.jpg');
        text-align: center;
    }

    .page-title {
        @include headerFont;
        color: $white;
        @include font-size(42);
        @include line-height(42);
        margin: 0;

        &.big {
            @include font-size(80);
            @include line-height(80);

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(55);
                @include line-height(55);
            }
        }
    }

    .page-description {
        color: $base-text-on-dark-tone;
        @include font-size(18);
        @include line-height(24);
        margin-bottom: 0;
    }

    @import '../events/events-description-cards.scss';

    @include defaultContainerPadding;

    @media screen and (max-width: $small-breakpoint) {
        .page-title {
            @include font-size(32);
            @include line-height(32);
        }

        .page-description {
            @include font-size(16);
            @include line-height(22);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
        .page-description {
            @include font-size(14);
            @include line-height(20);
        }
    }
}
