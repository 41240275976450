.conversation-container {
    display: flex;
    flex-direction: column;
    flex: 1060;
    border-left: $border-thickness solid $off-white;

    .header-container {
        display: flex;
        height: 60px;
        flex-shrink: 0;
        align-items: center;
        border-bottom: $border-thickness solid $off-white;
        padding: 10px $base-padding-half;

        .image-wrapper {
            @include image-wrapper(40px, 40px, 100%, 5px, 1px);
            flex-shrink: 0;
            margin-right: 10px;
        }

        .username {
            @include headerFont;
            @include font-size(24);
            @include line-height(24);
            margin: 0;
            color: $base-dark-tone;
            text-decoration: none;
            @include transition;
            @include clampLines(2);

            &:hover {
                color: $blue;
            }
        }

        .back-button {
            display: none;
            margin-left: auto;
            flex-shrink: 0;
        }
    }

    .no-conversation-selected-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .title {
            @include font-size(22);
            @include line-height(22);
            margin: 0;
            text-transform: uppercase;
            text-align: center;
        }
        .subtitle {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            margin-top: 5px;
            text-align: center;
        }
    }

    .content {
        display: flex;
        padding: 20px;
        width: 100%;
        height: 100%;
        overflow: auto;
        @include customScrollbarOnWhite;
        @import "./chat-bubble.scss";
    }

    @import "./conversation-controls.scss";

    @media screen and (max-width: $small-breakpoint) {
        .header-container {
            padding: 10px 15px;
            .username {
                @include font-size(20);
                @include line-height(20);
            }
        }
    }
}
