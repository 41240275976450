.colleague-list {
    align-self: flex-start;
    display: grid;
    gap: 10px;

    .colleague-card {
        display: flex;
        height: 60px;
        border: $border-thickness solid $off-white;
        border-radius: $border-radius;
        padding: 10px;
        align-items: center;

        .colleague-info {
            display: flex;
            align-items: center;
        }

        .picture {
            width: 40px;
            height: 40px;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            background-color: $blue;
            border-radius: 100%;
            @include font-size(18);
            @include line-height(18);
            color: $white;
            text-transform: uppercase;
            border: $border-thickness solid $white;
            @include box-shadow($base-shadow, 4px, 1px, 0px, 0px);
        }

        .email {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            margin-left: 10px;
            word-break: break-all;
        }

        .control-status {
            flex-shrink: 0;
            width: 120px;
            margin-left: auto;

            &.pending {
                display: flex;
                background-color: $light-blue;
                height: 30px;
                border-radius: $border-radius;
                text-align: center;
                align-items: center;
                justify-content: center;
                @include font-size(12);
                @include line-height(12);
                text-transform: uppercase;
                font-weight: $weight-bold;
                color: $blue;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .colleague-card {
            flex-direction: column;
            height: auto;
            align-items: flex-start;

            .control-status {
                margin-top: 10px;
                margin-right: auto;
            }
        }
    }
}
