.high-school-my-teams-container {
    display: flex;
    flex-direction: column;
    gap: $base-grid-padding;
    padding: $base-grid-padding;


    .my-teams-list {
        display: flex;
        flex-direction: column;
        gap: $base-grid-padding;

        .my-teams-container {
            display: flex;
            flex-direction: column;
            gap: $base-grid-padding;
    
            .high-school-team-card {
                display: flex;
                flex-direction: column;
                gap: $base-padding-half;
                
                @import "../../high-schools/profile/high-school-our-team-card.scss";
            
                .team-controls-container {
                    width: 100%;
                    display: flex;
                    gap: $base-padding-half;
        
                    .medium-button {
                        max-width: 240px;
                        width: 100%;
                    }

                    @media screen and (max-width: $small-breakpoint) {
                        flex-direction: column;
                        
                        .medium-button {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }


    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        gap: $base-padding-half;

        .my-teams-container {
            gap: $base-padding-half;
        }
    }
}