.article-content {
    position: relative;
    z-index: 1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $weight-black;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 2rem;
    }
    h1 {
        @include font-size(42);
        @include line-height(42);
    }
    h2 {
        @include font-size(32);
        @include line-height(32);
    }
    h3 {
        @include font-size(24);
        @include line-height(24);
    }
    h4 {
        @include font-size(18);
        @include line-height(18);
    }
    h5 {
        @include font-size(16);
        @include line-height(16);
    }
    h6 {
        @include font-size(14);
        @include line-height(14);
    }

    a {
        text-decoration: none;
        color: $blue;
        @include transition;

        &:hover {
            color: $blue-hover;
            text-decoration: underline;
        }
    }

    b,
    strong {
        font-weight: $weight-bold;
    }

    p {
        @include font-size(18);
        @include line-height(24);
        margin-top: 0;
        margin-bottom: 2rem;
    }

    li {
        @include font-size(18);
        @include line-height(24);
        margin-bottom: 1rem;
    }

    *:last-child {
        margin-bottom: 0;
    }

    @import "./article-content-media-queries.scss";
}
