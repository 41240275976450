.navigation-links {
    display: flex;
    align-items: center;

    .navigation-link {
        padding: $base-padding-half;
        text-transform: uppercase;
        color: $base-dark-tone;
        text-decoration: none;
        font-weight: $weight-bold;
        @include font-size(16);
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
        @include transition;

        &:hover,
        &.active {
            color: $blue;
        }
    }
    @media screen and (max-width: $mobile-nav-breakpoint) {
        display: none;
    }
    @media screen and (max-width: $mobile-nav-breakpoint) {
        display: none;
    }
}
