.home-header {
    width: 100%;
    background-color: $white;
    padding: $base-vertical-padding 0;
    padding-top: 240px;

    .container {
        display: flex;
        flex-direction: column;
        align-content: center;
        position: relative;

        .header-title {
            @include headerFont;
            @include font-size(72);
            @include line-height(72);
            margin: 0;
            max-width: 980px;
            z-index: 2;
            text-shadow: 0px 0px 100px rgba(255, 255, 255, 0.5);

            span {
                color: $blue;
            }

            &.standalone {
                margin-bottom: 100px;
            }
        }

        .button {
            margin-top: $base-padding;
            z-index: 2;
        }

        .featured-image {
            position: absolute;
            top: -240px;
            z-index: 1;
            right: 0;
        }
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        .container {
            .featured-image {
                right: 40px;
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .container {
            .header-title {
                @include font-size(60);
                @include line-height(60);
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-vertical-padding 0;
        padding-top: 240px;
        overflow: hidden;
        .container {
            .header-title {
                @include font-size(48);
                @include line-height(48);
                text-align: center;
                text-shadow: none;
                &.standalone {
                    margin: 50px 0;
                }
            }

            .button {
                margin: $base-padding auto;
                margin-bottom: 0;
                height: 50px;
                width: 200px;
            }

            .featured-image {
                top: -190px;
                right: calc(50% - 336px);
                width: 672px;
                max-width: unset;
                margin: 0;
                opacity: 0.3;
            }
        }
    }

    @media screen and (max-width: 650px) {
        padding: $large-vertical-padding 0;
        padding-top: 200px;
        .container {
            .featured-image {
                top: -140px;
                right: calc(50% - 275px);
                width: 550px;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: $large-vertical-padding 0;
        padding-top: 180px;

        .container {
            .header-title {
                @include font-size(36);
                @include line-height(36);
            }

            .featured-image {
                top: -110px;
                right: calc(50% - 225px);
                width: 450px;
            }
        }
    }

    @media screen and (max-width: 450px) {
        padding: $medium-vertical-padding 0;
        padding-top: 150px;
        .container {
            .featured-image {
                top: -80px;
            }
        }
    }

    @media screen and (max-width: 400px) {
        padding: 50px 0;
        padding-top: 120px;
        .container {
            .featured-image {
                top: -50px;
            }
        }
    }
}
