.questions-container {
    display: flex;
    flex-direction: column;
    margin-top: $base-vertical-padding;
    align-items: center;
    justify-content: center;

    .questions-title {
        @include font-size(32);
        @include line-height(38);
        margin: 0;
        font-weight: $weight-black;
        text-transform: uppercase;
        color: $white;
        text-align: center;
    }

    .button {
        @include font-size(24);
        margin-top: $base-padding-half;
        width: 270px;
        height: 70px;
        border-radius: 35px;
    }

    @media screen and (max-width: $large-breakpoint) {
        margin-top: $large-vertical-padding;
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin-top: $medium-vertical-padding;
    }

    @media screen and (max-width: $small-breakpoint) {
        margin-top: $small-vertical-padding;

        .questions-title {
            @include font-size(28);
            @include line-height(32);
        }

        .button {
            @include font-size(18);
            margin-top: $base-padding-half;
            width: 220px;
            height: 60px;
            border-radius: 30px;
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $small-vertical-padding;

        .questions-title {
            @include font-size(24);
            @include line-height(28);
        }

        .button {
            @include font-size(14);
            margin-top: $base-padding-half;
            width: 180px;
            height: 50px;
            border-radius: 30px;
        }
    }
}
