.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: $modal-color;
    padding: 10px;
    z-index: 1001;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include transition;
    @include fade-in-modal;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
        width: 100%;
        max-width: 500px;
        background-color: $white;
        border-radius: $border-radius;
        position: relative;
        display: flex;
        flex-direction: column;
        @include baseShadowOnBlack;
        @include fade-in-and-move(0.25s);
        max-height: 100%;

        &.coke-allstars {
            flex-direction: row;
            max-width: 1200px;

            @media screen and (max-width: $small-breakpoint) {
                flex-direction: column;
            }
        }

        &.sign-up-popup {
            max-width: 520px;
        }

        &.compose-group-message-popup {
            max-width: 510px;

            .popup-content {
                padding: 0;
            }
        }

        &.find-colleges-popup,
        &.find-players-popup {
            max-width: 780px;

            .popup-content {
                padding: 0;
            }
        }

        &.subscription-referral-popup {
            max-width: 700px;

            .popup-content {
                padding: 0;
            }
        }

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;

            .coke-allstars {
                max-height: 300px;
                width: auto;

                @media screen and (max-width: $medium-breakpoint) {
                    max-height: 200px;
                }
                @media screen and (max-width: $small-breakpoint) {
                    max-height: 150px;
                }
            }

            .logo-close-button {
                @include button;
                display: none;
                position: absolute;
                flex-shrink: 0;
                top: 20px;
                right: 20px;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background-color: transparent;
                border: $border-thickness solid $off-white;

                .icon {
                    fill: $base-dark-tone;
                }

                &:hover {
                    background-color: $blue;
                    border-color: $blue;

                    .icon {
                        fill: $white;
                    }
                }

                @media screen and (max-width: $small-breakpoint) {
                    display: block;
                }
            }
        }

        .promo-popup-content {
            padding: $base-padding-half;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
        }

        @import './popup-header.scss';

        .popup-description {
            margin: 0 auto;
            padding-top: 10px;
            color: $red;
            font-weight: $weight-medium;
        }

        .popup-content {
            padding: $base-padding-half;
            display: flex;
            flex-direction: column;
            @import '../account-select-modal.scss';
            @import '../dashboard/add-or-edit-game-modal.scss';
            @import '../generic-confirmation-popup.scss';
            @import '../dashboard/subscription-referral-popup.scss';
            @import '../dashboard/find-colleges/find-colleges-popup.scss';
            @import '../dashboard/find-players/find-players-popup.scss';
            @import '../dashboard/compose-group-message-popup.scss';
            @import '../promo-coke-allstars.scss';
            @import "../dashboard/invite-response-popup.scss";
            @import "../dashboard/create-team-popup.scss";

            max-height: calc(100% - 72px);
            overflow-y: auto;

            @media screen and (max-width: $small-breakpoint) {
                padding: 10px;
            }
        }

        .popup-button-container {
            border-top: $border-thickness solid $off-white;
            padding: $base-padding-half;
            display: flex;

            @import '../dashboard/find-colleges/find-colleges-cta.scss';
            @import '../dashboard/find-players/find-players-cta.scss';
        }
    }
}
