.gallery {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $base-padding-half;

    .gallery-image-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        border: $border-thickness solid transparent;
        @include transition;

        .gallery-image-wrapper {
            width: 100%;
            height: 100px;
            border-radius: $border-radius;
            z-index: 1;
            position: relative;

            img {
                border: $border-thickness solid $white !important;
                border-radius: $border-radius;
            }
        }

        .button {
            position: absolute;
            top: 10px;
            right: 10px;
            margin-left: 5px;
            z-index: 1000;

            &:hover {
                background-color: $red;
                border-color: $red;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        gap: 10px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
