.dollar-card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    overflow: hidden;

    .card-head {
        display: flex;
        position: relative;
        margin: 0 auto;
        padding: 10px 0;
        border-bottom: $border-thickness solid $off-white;
        width: 100%;
        min-height: 100px;
        max-height: 100px;
        align-items: center;
        // max-height: 75px;

        &.dark {
            background-color: $base-dark-tone;
        }

        .background-wrapper-logo {
            background-color: transparent;
            margin: 0 auto;
            text-align: center;

            &.needs-padding {
                padding: 10px;
            }

            .background-image-logo {
                @include transition;
                background-color: transparent;
            }
        }
    }

    .card-body {
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: $base-padding-half;
        height: 100%;

        .card-description {
            @include font-size(16);
            @include line-height(20);
            margin: 0;
            margin-top: $base-padding-half;
            color: $base-dark-tone;
            text-align: left;
            padding-bottom: $base-padding-half;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(14);
                @include line-height(18);
            }
        }

        .card-tagline {
            display: flex;
            @include font-size(30);
            @include line-height(30);
            color: $base-dark-tone;
            text-transform: uppercase;
            font-weight: 600;
            text-align: left;
            margin: 0;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(25);
                @include line-height(25);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(20);
                @include line-height(20);
            }
        }

        .button {
            margin-top: auto;
            padding: 15px 0;
            width: 70%;
            align-self: center;
            height: auto;
        }
    }
}
