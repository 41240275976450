.event-page {
    @import "./event-header.scss";
    @import "./event-page-container.scss";

    .event-register-container {
        @import "./event-registration-successful.scss";
        @import "./event-sign-up.scss";
        @import "./event-registration-form.scss";

        @media screen and (max-width: $medium-breakpoint) {
            padding: 0;
        }
    }
}
