.college-preview-card-skeleton {
    border-radius: $border-radius;
    height: 84px;
}

.college-preview-card {
    display: flex;
    border-radius: $border-radius;
    text-decoration: none;
    color: $base-dark-tone;
    background-color: $white;
    border: $border-thickness solid $off-white;
    padding: 10px;
    align-items: center;
    @include transition;

    &:hover {
        border-color: $blue;
    }

    .college-icon {
        @include image-wrapper(60px, 60px, 100%);
        flex-shrink: 0;
    }

    .college-info-container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .program-name {
            @include headerFont;
            @include font-size(20);
            @include line-height(20);
            margin: 0;
        }

        .program-location {
            @include font-size(12);
            @include line-height(16);
            margin: 0;
            color: $base-text-on-white;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        // .college-icon {
        //     width: 100px;
        //     height: 100px;
        // }
        // .program-name {
        //     @include font-size(20);
        //     @include line-height(20);
        // }
    }
}
