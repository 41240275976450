.card-horizontal {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    padding-bottom: $base-padding;

    .image-container {
        display: flex;
        position: relative;

        &.border {
            border: 2px solid $dark-off-white;
            border-radius: $border-radius;
        }

        .background-wrapper {
            position: relative;
            background-color: transparent;

            &.needs-padding {
                padding: 10px;
                border-radius: $border-radius;
                background-color: $white;
            }

            .background-image {
                @include transition;
                background-color: transparent;

                // padding: 10px;
                // position: relative;
                // width: 100%;
                // height: 100%;
            }
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        padding: 20px 0;

        &.left {
            text-align: left;
            justify-self: start;
            padding-left: $base-padding;
        }

        &.right {
            text-align: left;
            justify-self: end;
            padding-right: $base-padding;
        }

        .title {
            @include headerFont;
            @include font-size(40);
            @include line-height(40);
            margin: 0;
            color: $base-dark-tone;

            span {
                color: $blue;
            }
        }

        .description {
            @include font-size(18);
            @include line-height(24);
            padding-bottom: $base-padding-half;
        }
    }

    @media screen and (max-width: $large-breakpoint) {
        .content-container {
            max-width: 90%;

            .title {
                @include font-size(35);
                @include line-height(35);
            }

            .description {
                @include font-size(16);
                @include line-height(22);
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        display: flex;
        flex-direction: column;
        max-width: 80%;
        margin-top: $base-padding;
        justify-self: center;

        &:first-child {
            margin-top: 0;
        }

        &.reverse {
            flex-direction: column-reverse;
        }

        .image-container {
            width: 100%;
            justify-self: center;
        }

        .content-container {
            padding: 0;
            margin-top: $base-padding-half;

            &.right {
                text-align: left;
                justify-self: start;
                padding-left: $base-padding-half;
            }

            .title {
                @include font-size(30);
                @include line-height(30);
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        max-width: 100%;
        align-items: flex-start;
        justify-items: flex-start;

        .content-container {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin-top: $base-padding-half;

            &.left {
                text-align: left;
                justify-self: start;
                padding-left: 15px;
            }

            &.right {
                text-align: left;
                justify-self: start;
                padding-left: 15px;
            }

            .title {
                @include font-size(25);
                @include line-height(25);
            }

            .description {
                @include font-size(14);
                @include line-height(20);
            }

            .button {
                margin: $base-padding-half 0;
                margin-bottom: 0;
                height: 50px;
                width: 200px;
            }
        }
    }
}
