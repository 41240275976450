.requirements-container {
    ul {
        padding-left: 17px;
        li {
            @include line-height(28);
        }
    }
    color: $base-text-on-dark-tone;
    @include font-size(14);
    @include line-height(20);

    p {
        margin: 0;
    }
}
