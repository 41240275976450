.event-info-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    color: $white;
    padding-right: 530px;

    .title {
        @include headerFont;
        @include font-size(60);
        @include line-height(60);
        margin: 0;
        text-transform: uppercase;
    }

    .date {
        @include font-size(18);
        @include line-height(18);
        margin: 0;
        margin-top: 10px;
        font-weight: $weight-medium;
    }

    .button {
        margin-top: 10px;
        width: 190px;
    }

    @import './event-games.scss';

    .prize-pool {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .prize-label {
            @include font-size(15);
            @include line-height(15);
            font-weight: $weight-medium;
            text-transform: uppercase;
            margin: 0;
        }

        .prize-value {
            @include font-size(32);
            @include line-height(32);
            font-weight: $weight-black;
            text-transform: uppercase;
            color: $green;
            margin: 0;
            margin-top: 5px;
        }
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        padding-right: 570px;
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding-right: $base-padding;
        .title {
            @include font-size(50);
            @include line-height(50);
        }

        .date {
            @include font-size(16);
            @include line-height(16);
            margin-top: 5px;
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        .button {
            height: 34px;
        }

        .title {
            @include font-size(40);
            @include line-height(40);
        }

        .date {
            @include font-size(14);
            @include line-height(14);
        }

        .prize-pool {
            .prize-label {
                @include font-size(14);
                @include line-height(14);
            }

            .prize-value {
                @include font-size(28);
                @include line-height(28);
            }
        }
    }
}
