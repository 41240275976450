.promo-coke-allstars {
    display: grid;
    gap: $base-padding-half;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    p {
        @include font-size(18);
        @include line-height(24);
        margin: 0;
        span {
            color: $blue;
            font-weight: $weight-bold;
        }
    }

    .link-text {
        @include font-size(18);
        @include line-height(24);
        @include transition;
        margin: 0;
        font-weight: $weight-medium;
        color: $blue;
        &:hover {
            color: $red;
        }
    }
}
