.activations-description {
    @include defaultContainerPadding;

    .container {
        display: flex;
        flex-direction: column;

        .event-descrip {
            @include font-size(24);
            @include line-height(30);
            font-weight: $weight-bold;
            width: 85%;
            margin: 0 auto;
            text-align: center;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(20);
                @include line-height(26);
                width: 90%;
            }

            @media screen and (max-width: $small-breakpoint) {
                width: 95%;
                text-align: left;
                margin: 0;
            }

            @media screen and (max-width: $mobile-breakpoint) {
                @include font-size(18);
                @include line-height(24);
            }
        }

        .button-row {
            margin: $base-padding 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .items-grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: $base-grid-padding;
            // margin-top: $base-padding;

            .item {
                display: flex;
                flex-direction: row;
                background-color: $white;
                border-radius: $border-radius;
                padding: $base-padding;

                .icon-box {
                    display: flex;
                    margin-right: $base-padding-half;

                    .icon {
                        fill: $base-dark-tone;
                        width: 40px;
                        height: 40px;
                    }
                }

                .content-group {
                    display: flex;
                    flex-direction: column;

                    .heading {
                        @include font-size(20);
                        @include line-height(26);
                        text-transform: uppercase;
                        font-weight: $weight-medium;
                        margin: 0;
                    }

                    .descrip {
                        @include font-size(16);
                        @include line-height(22);
                        margin: 0;
                        margin-top: 10px;
                    }
                }
            }

            @media screen and (max-width: $small-breakpoint) {
                grid-template-columns: repeat(1, minmax(0, 1fr));

                .item {
                    padding: $base-padding-half;
                    .content-group {
                        .heading {
                            @include font-size(20);
                            @include line-height(26);
                        }

                        .descrip {
                            @include font-size(14);
                            @include line-height(20);
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
