.player-card-content {
    width: 100%;
    padding: 15px;
    background-color: $white;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    .player-profile-image {
        @include image-wrapper(90px, 90px, 100%);
        flex-shrink: 0;
    }

    @import "./player-card-bio.scss";
    @import "./player-card-rank.scss";

    @media screen and (max-width: $mobile-breakpoint) {
        border-bottom-left-radius: 0;
        border-top-right-radius: $border-radius;
        .player-profile-image {
            width: 70px;
            height: 70px;
        }
    }
}
