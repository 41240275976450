.event-card {
    box-shadow: none;
    border: $border-thickness solid $off-white;

    .event-card-header {
        padding-top: 20px;
        height: 140px;

        .event-icon {
            width: 100px;
            height: 100px;
        }
    }

    .event-card-content {
        padding-bottom: $base-padding-half;
        .event-title {
            @include font-size(14);
            @include line-height(14);
        }
        .event-description {
            @include font-size(12);
            @include line-height(16);
            margin-top: 0px;
        }
    }
    @import "./dashboard-events-queries.scss";
}
