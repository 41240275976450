.social-controls-container {
    display: flex;
    align-self: flex-start;
    margin-left: auto;
    margin-bottom: $base-padding-half;

    .circle-button {
        margin-right: $base-padding-half;
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        margin-top: $base-padding-half;
        margin-left: 0;
        margin-bottom: 0;
    }

    @media screen and (max-width: $small-breakpoint) {
        width: 100%;
        .circle-button {
            margin-right: 10px;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
    }
}
