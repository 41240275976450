.subscriptions {
    width: 100%;
    position: relative;
    background-color: $base-dark-tone;
    background-image: url("/images/SPIN_Pricing_Background.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top left;
    padding: $base-padding 0;

    .limited-time-offer {
        margin-top: $base-grid-padding;
        background-color: $blue;
        min-height: 60px;
        color: white;
        border-radius: $border-radius;
        border: $border-thickness solid #29acdb;
        display: flex;
        align-items: center;
        padding: 15px;
        font-weight: bold;
        @include font-size(18);
        text-transform: uppercase;
        justify-content: center;
    }

    @import "./referral-code.scss";

    .background-icon {
        width: 2000px;
        height: 2000px;
        position: absolute;
        top: -200px;
        left: -300px;
        transform: rotate(15deg);
        fill: $purple;
        opacity: 0.1;
    }

    @import "../../journey/journey-pricing-feature-comparison.scss";
    @import "../../journey/journey-questions.scss";

    @import "./subscriptions-pricing-options.scss";
    @import "./subscriptions-pricing-feature-comparison.scss";

    .cancel-container {
        width: 100%;
        max-width: 1110px;
        padding: 0 $base-padding-half;
        margin: 0 auto;
        display: flex;
        .cancel-form {
            display: flex;
            width: 100%;
            .cancel-subscription {
                @include button;
                margin-left: auto;
                width: 100%;
                max-width: 250px;
                text-transform: none;
                background-color: transparent;
                color: $base-text-on-dark-tone;
                font-weight: $weight-regular;
                height: 40px;

                &:hover {
                    color: $red;
                }
            }
        }
    }

    @import "./subscriptions-questions-container.scss";

    @media screen and (max-width: $xmedium-breakpoint) {
        .cancel-container {
            padding: 0;
            .cancel-form {
                .cancel-subscription {
                    width: 50%;
                    max-width: none;
                }
            }
        }
    }
}
