.dashboard-cta-card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    color: $white;
    overflow: hidden;

    .header {
        overflow: hidden;

        &.blue {
            background-color: $base-dark-tone;
        }

        &.green {
            background-color: $green;
        }

        .title {
            @include headerFont;
            @include font-size(30);
            @include line-height(30);
            margin: 10px auto;
            text-align: center;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        background-color: $white;
        color: $base-dark-tone;

        .description {
            flex-basis: 70%;
            @include font-size(14);
            @include line-height(18);
            color: $base-dark-tone;
            padding: 10px $base-padding-half;
            margin: 0;
        }

        .button {
            flex-basis: 25%;
            align-self: center;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: $border-radius;
        color: $white;
        overflow: hidden;

        .header {
            background-color: $blue;
            overflow: hidden;

            .title {
                @include headerFont;
                @include font-size(30);
                @include line-height(30);
                margin: 10px auto;
                text-align: center;
            }
        }

        .content {
            display: flex;
            flex-direction: row;
            background-color: $white;
            color: $base-dark-tone;

            .description {
                flex-basis: 70%;
                @include font-size(16);
                @include line-height(20);
                color: $base-dark-tone;
                padding: 10px $base-padding-half;
                margin: 0;
            }

            .button {
                flex-basis: 25%;
                align-self: center;
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
    }
}
