&.newsletter {
    width: 35%;

    .newsletter-form {
        display: grid;
        margin-top: 20px;
        gap: 20px;
        width: 100%;
        grid-template:
            "first-name last-name"
            "email email"
            "submit submit";

        @import "./newsletter-input-field.scss";

        .first-name {
            grid-area: first-name;
        }
        .last-name {
            grid-area: last-name;
        }

        .email {
            grid-area: email;
        }

        .medium-button {
            grid-area: submit;
            width: 200px;
            height: 50px;
            border-radius: 25px;
            @include font-size(16);
            @include line-height(16);
        }
    }
    @media screen and (max-width: $medium-breakpoint) {
        width: 100%;
        margin-top: 80px;
    }

    @media screen and (max-width: $small-breakpoint) {
        margin-top: 40px;

        .newsletter-form {
            grid-template:
                "first-name first-name"
                "last-name last-name"
                "email email"
                "submit submit";
        }
    }
}
