.nasef-logo {
    &.light {
        .cls-1 {
            fill: none;
        }
        .cls-2 {
            fill: #fff;
        }
    }
}

.nace-logl {
    &.dark {
        .cls-1 {
            fill: #005cb9;
        }
        .cls-2 {
            fill: #888b8d;
        }
    }
}
