.cards-section {
    display: flex;
    flex-direction: column;
    padding: $base-vertical-padding 0;
    background-color: $base-dark-tone;
    background-image: url('/images/SPIN_Pricing_Background.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top left;
    color: $white;

    &.scholastic {
        background-color: $off-white;
        color: $base-dark-tone;
    }

    .section-head {
        margin-bottom: $base-padding;

        .partners-tagline {
            @include headerFont;
            @include font-size(50);
            @include line-height(50);
            margin: 0;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(45);
                @include line-height(45);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(40);
                @include line-height(40);
            }

            // @media screen and (max-width: $mobile-breakpoint) {
            //     @include font-size(20);
            //     @include line-height(40);
            // }
        }

        .partners-description {
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            margin-top: 10px;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(14);
                @include line-height(20);
            }
        }
    }

    .section-cards {
        display: grid;
        gap: $base-grid-padding;

        @import './card-dark.scss';
    }
}
