.button {
    @include button;
}

.dreamseat-blue-button {
    @include dreamseat-blue-button;
}

.f1-red-button {
    @include f1-red-button;
}

.brightspeed-orange-button {
    @include brightspeed-orange-button;
}

.coke-red-button {
    @include coke-red-button;
}

.green-button {
    @include green-button;
}

.black-button {
    @include black-button;
}

.blue-button {
    @include blue-button;
}

.outline-button {
    @include outline-button;
}

.light-blue-button {
    @include light-blue-button;
}

.red-button {
    @include red-button;
}

.grey-button {
    @include grey-button;
}

.purple-button {
    @include purple-button;
}

.current-button {
    @include current-button;
    cursor: not-allowed;
}

.small-button {
    width: 80px;
    height: 30px;
    border-radius: 15px;
    @include font-size(12);
    @include line-height(12);
}

.medium-button {
    width: 120px;
    height: 40px;
    border-radius: 20px;
    @include font-size(14);
    @include line-height(14);
}

.circle-button {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $white;
    border: $border-thickness solid $off-white;

    .icon {
        fill: $base-dark-tone;
    }

    &:hover {
        background-color: $blue;
        border-color: $blue;

        .icon {
            fill: $white;
        }
    }
}

.blue-circle-button {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: transparent;
    border: $border-thickness solid $off-white;

    &:hover,
    :active {
        background-color: $blue;
    }

    &.fill {
        background-color: $blue;
    }
}

.red-circle-button {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $white;
    border: $border-thickness solid $white;

    .icon {
        fill: $base-dark-tone;
    }

    &:hover {
        background-color: $red;
        border-color: $red;

        .icon {
            fill: $white;
        }
    }
}

.large-button {
    width: 250px;
    height: 60px;
    border-radius: 30px;
    @include font-size(18);
    @include line-height(18);
}

.info-button {
    @include button;
    @include font-size(14);
    @include line-height(14);
    background-color: transparent;
    border: $border-thickness solid $off-white;
    font-weight: $weight-medium;
    color: $base-dark-tone;
    margin: 0;
    height: 30px;
    padding: 0 15px;
    text-transform: none;

    &.dark-theme {
        border-color: $purple;
        background-color: $off-dark-tone;
        color: $white;
        &:hover {
            border-color: $purple;
            background-color: $purple;
        }
    }

    .icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        fill: $base-dark-tone;
    }

    &:hover {
        border-color: $base-dark-tone;
    }
}

.filter-button {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: $border-thickness solid $off-white;
    border-radius: 25px;

    &.dark-theme {
        border-color: $off-dark-tone;

        .icon {
            fill: $white;
        }
    }

    &:hover,
    &.active {
        background-color: $base-dark-tone;
        border-color: $base-dark-tone;

        .icon {
            fill: $white;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        width: 40px;
        height: 40px;
    }
}
