.account-select-content {
    display: flex;
    flex-direction: column;
    gap: $base-padding-half;
    padding: 10px;

    .title-container {
        display: flex;
        flex-direction: column;

        .title-description {
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            font-weight: $weight-black;
            text-transform: uppercase;
        }

        .description {
            @include font-size(14);
            @include line-height(20);
            margin: 0;
            color: $base-text-on-white;
        }
    }

    .type-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @import "./sign-up/account-type-container.scss";
    }

    .button {
        width: 100%;
        height: 60px;
        border-radius: 30px;
        @include font-size(18);
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 0;

        .title-container {
            .title-description {
                @include font-size(16);
                @include line-height(22);
            }

            .description {
                @include font-size(12);
                @include line-height(18);
            }
        }

        .button {
            @include font-size(16);
            height: 50px;
        }
    }
}

.sign-in {
    background-color: transparent;
    font-weight: $weight-regular;
    color: $blue;
    margin-top: $base-padding-half;
    margin-bottom: 10px;
    text-transform: none;

    &:hover {
        color: $blue-hover;
        text-decoration: underline;
    }
}
