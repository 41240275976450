.pricing-card-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $base-padding $base-padding-half;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: $white;
    color: $base-dark-tone;

    &.blue-background {
        color: $white;
        background-color: $blue;

        .pricing-background-icon {
            fill: $blue-hover;
        }

        .button {
            border: $border-thickness solid $white;
            color: $white;

            &:hover {
                background-color: $white;
                color: $blue;
            }
        }
    }

    &.green-background {
        color: $white;
        background-color: $green;

        .pricing-background-icon {
            fill: $green-hover;
        }

        .button {
            border: $border-thickness solid $white;
            color: $white;

            &:hover {
                background-color: $white;
                color: $green;
            }
        }
    }

    &.purple-background {
        color: $white;
        background-color: $purple;

        .pricing-background-icon {
            fill: $purple-hover;
        }

        .button {
            border: $border-thickness solid $white;
            color: $white;

            &:hover {
                background-color: $white;
                color: $purple;
            }
        }
    }

    .pricing-background-icon {
        width: 600px;
        height: 600px;
        position: absolute;
        top: calc(50% - 360px);
        left: calc(50% - 300px);
        transform: rotate(15deg);
        fill: $off-white;
    }

    .pricing-label {
        @include headerFont;
        @include font-size(42);
        @include line-height(42);
        margin: 0;
        margin-top: $base-padding-half;
        position: relative;
        z-index: 2;
    }

    .pricing-value {
        @include headerFont;
        @include font-size(120);
        @include line-height(120);
        margin: 0;
        margin-top: 60px;
        position: relative;
        text-align: center;
        z-index: 2;
    }

    .pricing-interval {
        @include font-size(18);
        @include line-height(18);
        margin: 0;
        font-weight: $weight-medium;
        margin-top: $base-padding-half;
        position: relative;
        z-index: 2;
        min-height: 20px;
    }

    .journey-pricing-option-form {
        width: 100%;
    }

    .free-trial-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 41px;

        .free-trial-descriptor {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            color: $base-text-on-white;
            font-weight: $weight-bold;
            position: relative;
            text-transform: uppercase;
            z-index: 2;
            margin-bottom: 5px;
            text-align: center;
        }

        .button {
            margin-top: 0;
            @include font-size(20);
            @include line-height(20);
        }
    }

    .button {
        margin-top: 60px;
        height: 70px;
        width: 100%;
        border-radius: 35px;
        @include font-size(24);
        @include line-height(24);
        font-weight: $weight-black;
        background-color: transparent;
        border: $border-thickness solid $base-dark-tone;
        color: $base-dark-tone;
        position: relative;
        z-index: 2;

        &:hover {
            background-color: $base-dark-tone;
            color: $white;
        }
    }

    &.custom {
        .pricing-value {
            @include font-size(80);
            @include line-height(80);
        }

        .button {
            margin-top: 20px;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .pricing-interval {
            &.mobile-hide {
                display: none;
            }
        }

        .button {
            max-width: 300px;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;

        .pricing-background-icon {
            width: 600px;
            height: 600px;
            position: absolute;
            top: calc(50% - 300px);
            left: calc(50% - 300px);
            transform: rotate(15deg);
            fill: $off-white;
        }

        .pricing-label {
            @include font-size(32);
            @include line-height(32);
            margin-top: 10px;
        }

        .pricing-value {
            @include font-size(90);
            @include line-height(90);
            margin-top: 30px;
            .value-cents {
                @include font-size(32);
                @include line-height(32);
            }
        }
        .pricing-interval {
            @include font-size(16);
            @include line-height(16);
        }
        
        &.custom {
            .button {
                margin-top: 30px;
            }
        }

        .button {
            margin-top: 30px;
            height: 60px;
            width: 100%;
            max-width: 240px;
            border-radius: 35px;
            @include font-size(18);
            @include line-height(18);
        }

        .free-trial-container {
            margin-top: 11px;

            .free-trial-descriptor {
                @include font-size(14);
                @include line-height(14);
                margin-bottom: 5px;
            }

            .button {
                @include font-size(18);
                @include line-height(18);
            }
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;

        .pricing-background-icon {
            width: 600px;
            height: 600px;
            position: absolute;
            top: calc(50% - 300px);
            left: calc(50% - 300px);
            transform: rotate(15deg);
            fill: $off-white;
        }

        .pricing-label {
            @include font-size(28);
            @include line-height(28);
        }

        .pricing-value {
            @include font-size(70);
            @include line-height(70);
            margin-top: 25px;
            .value-cents {
                @include font-size(28);
                @include line-height(28);
            }
        }
        
        &.custom {
            .pricing-value {
                @include font-size(70);
                @include line-height(70);
            }
    
            .button {
                margin-top: 25px;
            }
        }

        .pricing-interval {
            @include font-size(14);
            @include line-height(14);
            margin-top: 10px;
        }

        .free-trial-container {
            .free-trial-descriptor {
                @include font-size(12);
                @include line-height(12);
                margin-bottom: 2px;
            }

            .button {
                @include font-size(14);
                @include line-height(14);
            }
        }

        .button {
            margin-top: 25px;
            height: 50px;
            width: 100%;
            @include font-size(14);
            @include line-height(14);
        }
    }
    @media screen and (max-width: $tiny-breakpoint) {
        width: 100%;
        padding: 10px;

        .pricing-background-icon {
            width: 600px;
            height: 600px;
            position: absolute;
            top: calc(50% - 300px);
            left: calc(50% - 300px);
            transform: rotate(15deg);
            fill: $off-white;
        }

        .pricing-label {
            @include font-size(24);
            @include line-height(24);
        }

        .pricing-value {
            @include font-size(52);
            @include line-height(52);
            margin-top: 20px;

            .value-cents {
                @include font-size(24);
                @include line-height(24);
            }
        }

        &.custom {
            .pricing-value {
                @include font-size(52);
                @include line-height(52);
            }
        }

        .pricing-interval {
            @include font-size(12);
            @include line-height(12);
            margin-top: 5px;
        }

        .button {
            height: 40px;
            width: 100%;
            @include font-size(12);
            @include line-height(12);
        }

        .free-trial-container {
            margin-top: 13px;

            .free-trial-descriptor {
                @include font-size(10);
                @include line-height(10);
            }

            .button {
                @include font-size(12);
                @include line-height(12);
            }
        }
    }
}
