.generic-toggle-switch {
    background-color: $white;
    border: $border-thickness solid $off-white;
    padding: 3px;
    display: flex;
    border-radius: 18px;

    .option {
        display: flex;
        text-align: center;
        align-items: center;
        padding: 0 15px;
        height: 28px;
        background-color: transparent;
        color: $black;
        @include transition;
        @include font-size(12);
        @include line-height(12);
        font-weight: $weight-bold;
        cursor: pointer;
        border-radius: 14px;
        text-transform: uppercase;

        &.active {
            background-color: $blue;
            color: $white;
        }
    }
}
