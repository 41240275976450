.pricing-features-list-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    color: $base-dark-tone;
    padding: $base-padding-half;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    gap: $base-padding-half;

    .pricing-list {
        display: flex;
        flex-direction: row;
        align-items: center;

        .checkmark-container {
            display: flex;
            margin-right: 10px;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            background-color: $off-white;
            border: $border-thickness solid $base-dark-tone;
            border-radius: 100%;
            flex-shrink: 0;

            .icon {
                width: 18px;
                height: 18px;
                fill: $base-dark-tone;
            }
        }

        .pricing-list-text {
            display: flex;

            .item-label {
                @include font-size(16);
                @include line-height(24);
                margin: 0;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .pricing-list {
            .checkmark-container {
                margin-right: 10px;
                width: 24px;
                height: 24px;

                .icon {
                    width: 12px;
                    height: 12px;
                }
            }

            .pricing-list-text {
                .item-label {
                    @include font-size(14);
                    @include line-height(18);
                }
            }
        }
    }
}
