.event-landing-card-image-sponsors {
    background-color: $white;
    border-radius: $border-radius;

    .event-landing-card-image-sponsors-header {
        @include font-size(30);
        @include line-height(30);
        font-family: $base-font-family;
        text-align: center;
        text-transform: uppercase;
        font-weight: $weight-black;
        padding-top: 20px;
    }

    .event-landing-card-image-sponsors-grid {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: $base-grid-padding;
        grid-auto-rows: min-content;
        margin: $base-padding auto;
        border-radius: $border-radius;
        text-decoration: none;
        color: $white;
        @include baseShadowOnWhite;

        .landing-sponsor-item {
            padding-bottom: $base-padding;

            .event-landing-card-image-header {
                @include font-size(24);
                @include line-height(24);
                font-family: $base-font-family;
                text-align: center;
                text-transform: uppercase;
                font-weight: $weight-black;
                padding-top: 20px;
            }

            .container-image {
                display: flex;
                justify-content: center;
                padding: 0 20px;
            
                .image-landing {
                    border-radius: $border-radius;
                }
            
            }

            .event-landing-card-image-description {
                @include font-size(18);
                font-family: $base-font-family;
                color:  $base-dark-tone;
                text-align: center;
                font-style: italic;
                padding-bottom: $base-padding;
            }

            .article-landing-container {
                max-width: 75%;
                margin: 0 auto;
            }
            
        }
        
        @media screen and (max-width: $medium-breakpoint) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin: $base-padding auto;

        }
    }
}