.home-tile-section {
    background-color: $off-white;

    .home-grid {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        position: relative;
        justify-content: center;
        margin: $large-vertical-padding 0;
        gap: $large-vertical-padding;

        @import './horizontal-card.scss';
    }
}
