& {
    --toastify-color-light: #{$white} !important;
    --toastify-color-dark: #{$base-dark-tone} !important;
    --toastify-color-info: #{$blue} !important;
    --toastify-color-success: #{$green} !important;
    --toastify-color-warning: #{$yellow} !important;
    --toastify-color-error: #{$red} !important;
    --toastify-font-family: #{$base-font-family} !important;
    --toastify-text-color-light: #{$base-text-on-white} !important;
    --toastify-text-color-dark: #{$base-text-on-dark-tone} !important;

    .Toastify__toast {
        border-radius: $border-radius;
        @include baseShadowOnWhite;
    }
}
