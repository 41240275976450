.event-landing-card-image {
    display: block;
    border-radius: $border-radius;
    text-decoration: none;
    color: $base-dark-tone;
    // margin-bottom: $base-padding;

    .event-landing-card-image-header {
        @include font-size(24);
        @include line-height(24);
        font-family: $base-font-family;
        text-align: center;
        text-transform: uppercase;
        font-weight: $weight-black;
        padding-top: 20px;
    }

    .container-image {
        display: flex;
        justify-content: center;
        padding: 0 20px;
    
        .image-landing {
            border-radius: $border-radius;
        }
    
    }

    p {
        padding: 0 20px;
    }

    .event-landing-card-image-description {
        @include font-size(18);
        font-family: $base-font-family;
        color:  $base-dark-tone;
        text-align: center;
        font-style: italic;
        padding-bottom: $base-padding;
    }

    .article-landing-container {
        max-width: 75%;
        margin: 0 auto;
    }

    @import "../news/article-card.scss";
    
}
