.default-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border-thickness solid transparent;
    @include transition;
    width: 100%;
    height: 100%;
    background-color: darken($off-white, 10%);
    border-radius: 100%;

    .spin-logo {
        width: 24px;
        height: 24px;
        fill: $base-text-on-black;
    }
}
