.latest-news-card {
    @extend .dashboard-card;

    .content {
        overflow-x: hidden;
        overflow-y: auto;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        @include customScrollbarOnWhite;
        @import "../../news/article-card.scss";

        .article-card {
            .article-content {
                padding: $base-padding-half;

                .article-title {
                    @include font-size(18);
                    @include line-height(18);
                    @include clampLines(2);
                }

                .article-date {
                    @include font-size(14);
                    @include line-height(14);
                    margin-top: 2px;
                }
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        height: auto;
        width: 100%;
        margin-left: 0;

        .content {
            .article-card {
                .article-content {
                    padding: 15px;

                    .article-title {
                        @include font-size(16);
                        @include line-height(16);
                        @include clampLines(2);
                    }

                    .article-date {
                        color: $base-text-on-black;
                        @include font-size(12);
                        @include line-height(12);
                    }
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .content {
            .article-card {
                .article-content {
                    padding: 15px;

                    .article-title {
                        @include font-size(14);
                        @include line-height(14);
                        @include clampLines(2);
                    }

                    .article-date {
                        color: $base-text-on-black;
                        @include font-size(12);
                        @include line-height(12);
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .content {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: $tiny-breakpoint) {
        .content {
            .article-card {
                .article-content {
                    padding: 15px;

                    .article-title {
                        @include font-size(12);
                        @include line-height(12);
                        @include clampLines(3);
                    }

                    .article-date {
                        color: $base-text-on-black;
                        @include font-size(10);
                        @include line-height(10);
                    }
                }
            }
        }
    }
}
