.textarea-container {
    position: relative;

    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }

    .textarea {
        display: block;
        width: 100%;
        min-height: 200px;
        resize: vertical;
        border-radius: $border-radius;
        border: $border-thickness solid transparent;
        background-color: $white;
        padding: $border-radius;
        color: $base-dark-tone;
        outline: 0;
        font-weight: $weight-medium;
        font-family: $base-font-family;
        @include customScrollbarOnWhite;
        @include font-size(18);

        &::placeholder {
            font-weight: $weight-regular;
            color: $base-text-on-white;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border-color: $blue;
            -webkit-text-fill-color: $base-dark-tone;
            -webkit-box-shadow: 0 0 0px 1000px $off-white inset;
        }

        &:active,
        &:focus {
            border-color: $blue;
        }

        &.has-required {
            padding-left: 45px;
        }

        @include transition;
        transition-property: border-color !important;
    }

    &.error {
        .textarea {
            border-color: $red;
            background-color: $light-red;
        }

        .required {
            color: $red;
        }
    }

    .required {
        color: $blue;
        @include font-size(24);
        font-weight: $weight-bold;
        position: absolute;
        left: 20px;
        top: 16px;
    }
}
