.players-list-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $base-grid-padding;
    margin: $base-padding auto;
    margin-bottom: $base-vertical-padding;

    @import "./player-card.scss";

    .subscribe-card {
        border-radius: $border-radius;
        background-color: $light-blue;
        border: $border-thickness solid $blue;
        padding: $base-grid-padding;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        grid-column: span 3;
        max-width: 700px;
        width: 100%;

        .title {
            @include headerFont;
            @include font-size(32);
            @include line-height(32);
            margin: 0;
            text-align: center;
        }

        .subtitle {
            @include font-size(14);
            @include line-height(14);
            color: $base-text-on-white;
            margin: 0;
            margin-top: 5px;
        }

        .button {
            max-width: 180px;
            width: 100%;
            margin-top: 20px;
        }
    }

    .show-more {
        width: 100%;
        max-width: 180px;
        border-radius: 25px;
        height: 50px;
        margin: 0 auto;
        grid-column: span 3;
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        margin: $base-padding auto;
        margin-bottom: $large-vertical-padding;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .subscribe-card,
        .show-more {
            grid-column: span 2;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin: $base-padding-half auto;
        gap: $base-padding-half;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .subscribe-card,
        .show-more {
            grid-column: span 1;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .show-more {
            height: 40px;
        }
    }
}
