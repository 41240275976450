.account-type-container {
    width: 100%;
    background-color: $off-white;
    border: $border-thickness solid transparent;
    border-radius: $border-radius;
    padding: $base-padding-half;
    display: flex;
    align-items: center;
    gap: $base-padding-half;
    cursor: pointer;
    @include transition;

    .info-container {
        width: 100%;

        .type-title {
            @include font-size(18);
            @include line-height(24);
            font-weight: $weight-black;
            margin: 0;
            text-transform: uppercase;
        }

        .type-slogan {
            @include font-size(14);
            @include line-height(20);
            margin: 0;
            color: $base-text-on-white;
        }
    }

    &.player {
        &:hover,
        &.active {
            border-color: $blue;
            background-color: $light-blue;

            .icon-container {
                background-color: $blue;
                border-color: $blue;

                .icon {
                    fill: $white;
                }
            }
        }
    }

    &.college {
        &:hover,
        &.active {
            border-color: $green;
            background-color: $light-green;

            .icon-container {
                background-color: $green;
                border-color: $green;

                .icon {
                    fill: $white;
                }
            }
        }
    }

    &.high-school {
        &:hover,
        &.active {
            border-color: $purple;
            background-color: $light-purple;

            .icon-container {
                background-color: $purple;
                border-color: $purple;

                .icon {
                    fill: $white;
                }
            }
        }
    }

    .icon-container {
        width: 80px;
        height: 60px;
        background-color: transparent;
        border-radius: $border-radius;
        border: $border-thickness solid $base-dark-tone;

        @include transition;
        .icon {
            width: 30px;
            height: 30px;
            fill: $base-dark-tone;
            @include transition;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 10px;
        gap: 10px;

        .type-title {
            @include font-size(16);
            @include line-height(22);
        }

        .type-slogan {
            @include font-size(12);
            @include line-height(18);
        }

        .icon-container {
            width: 50px;
            height: 50px;

            .icon {
                width: 24px;
                height: 24px;
            }
        }
    }
}
