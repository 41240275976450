.event-registration-successful-container {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    top: -80px;
    background-color: $off-white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    margin-bottom: calc(#{$base-vertical-padding} - 80px);

    .title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
    }

    .subtitle {
        @include font-size(14);
        @include line-height(14);
        margin: 0;
        margin-top: 5px;
    }

    @import "./event-ticket.scss";

    .ticket {
        margin-top: $base-grid-padding;
        max-width: 400px;
        cursor: default;
    }

    .success-page-card {
        margin-top: $base-grid-padding;
        padding: $base-grid-padding;
        width: 100%;
        border-radius: $border-radius;
        background-color: $white;
        @include baseShadowOnWhite;

        @import "./formatting.scss";
    }

    @media screen and (max-width: $large-breakpoint) {
        margin-bottom: calc(#{$large-vertical-padding} - 80px);
    }

    @media screen and (max-width: $medium-breakpoint) {
        max-width: 100%;
        margin-bottom: calc(#{$medium-vertical-padding} - #{$border-radius});
        top: -$border-radius;
    }
    @media screen and (max-width: $small-breakpoint) {
        margin-bottom: calc(#{$small-vertical-padding} - #{$border-radius});
        padding: $base-padding-half;
    }
}
