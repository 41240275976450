.questions-container {
    margin-top: $base-padding;

    .questions-title {
        @include font-size(28);
        @include line-height(28);
    }

    .button {
        @include font-size(18);
        width: 180px;
        height: 50px;
        border-radius: 25px;
    }

    @media screen and (max-width: $small-breakpoint) {
        .questions-title {
            @include font-size(24);
            @include line-height(24);
        }

        .button {
            @include font-size(14);
            margin-top: 10px;
            width: 160px;
            height: 40px;
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $small-vertical-padding;

        .questions-title {
            @include font-size(18);
            @include line-height(18);
        }
    }
}
