.scouting-report-player-banner {
    background-color: $light-blue;
    border: $border-thickness solid $blue;
    padding: 15px 10px;
    border-radius: $border-radius;
    margin-top: 10px;
    display: flex;
    align-items: center;

    .description-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .title-container {
            width: 100%;
            display: flex;
            align-items: center;
            .title {
                @include headerFont;
                @include font-size(20);
                @include line-height(20);
                margin: 0;
            }

            .button {
                background-color: $blue;
                margin-left: auto;
                flex-shrink: 0;
                width: 30px;
                height: 30px;
                color: $white;
            }
        }

        .subtitle {
            @include font-size(12);
            @include line-height(16);
            color: $base-text-on-white;
            margin: 0;
            margin-top: 10px;

            .link {
                color: $blue;
                font-weight: $weight-medium;
            }
        }
    }
}
