.partners-feat-colleges {
    display: flex;
    flex-direction: column;
    background-image: url('/images/pattern-spin.jpeg');
    background-repeat: repeat;
    background-size: 33%;
    padding: $large-vertical-padding 0;

    .container {
        .header {
            @include headerFont;
            @include font-size(40);
            @include line-height(40);
            color: $white;
        }

        @import '../../colleges/colleges-list.scss';
    }
}
