.journey-slogan {
    @include defaultContainerPadding;

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .title {
        @include headerFont;
        @include font-size(72);
        @include line-height(72);
        text-align: center;
        margin: 0;
        margin-bottom: $base-padding-half;
        span {
            color: $blue;
        }
    }

    .subtitle {
        @include font-size(32);
        @include line-height(32);
        text-align: center;
        text-transform: uppercase;
        font-weight: $weight-black;
        margin: 0;
        margin-top: $base-grid-padding;
    }

    .button {
        margin-top: 75px;
        width: 360px;
        height: 80px;
        border-radius: 40px;
        @include font-size(28);
    }

    @media screen and (max-width: $medium-breakpoint) {
        .title {
            @include font-size(60);
            @include line-height(60);
        }
        .subtitle {
            @include font-size(28);
            @include line-height(28);
        }
        .button {
            margin-top: 60px;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .title {
            @include font-size(48);
            @include line-height(48);
        }
        .subtitle {
            @include font-size(24);
            @include line-height(24);
            margin-top: $base-padding-half;
        }
        .button {
            margin-top: $base-padding;
            width: 280px;
            height: 60px;
            border-radius: 40px;
            @include font-size(24);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .title {
            @include font-size(36);
            @include line-height(36);
        }
        .subtitle {
            @include font-size(18);
            @include line-height(18);
            margin-top: 10px;
        }
        .button {
            margin-top: $base-padding-half;
            width: 240px;
            height: 50px;
            border-radius: 40px;
            @include font-size(18);
        }
    }
}
