.newsletter-input-field-container {
    max-width: 100%;
    position: relative;

    label {
        display: none;
    }
    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }

    .input-field {
        max-width: 100%;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        border: $border-thickness solid $off-black;
        background-color: $off-black;
        padding: 0 25px;
        @include font-size(15);
        @include line-height(15);
        color: $white;
        outline: 0;
        font-weight: $weight-regular;
        font-family: $base-font-family;
        @include transition;

        &::placeholder {
            color: $base-text-on-black;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border-color: $blue;
            -webkit-text-fill-color: $white;
            -webkit-box-shadow: 0 0 0px 1000px $off-black-hover inset;
        }

        &:active,
        &:focus {
            border-color: $blue;
        }

        &.has-required {
            padding-left: 45px;
        }
    }
    &.error {
        .input-field {
            border-color: $red;
            // background-color: $light-red;
        }

        .required {
            color: $red;
        }
    }

    .required {
        color: $blue;
        @include font-size(24);
        position: absolute;
        left: 20px;
        top: 16px;
    }
}
