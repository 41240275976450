.activations-schedule {
    @include defaultContainerPadding;
    padding-top: 0;

    .container {
        display: flex;
        flex-direction: column;

        .title {
            @include headerFont;
            @include font-size(60);
            @include line-height(60);
            margin: 0 auto;
            text-align: center;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(50);
                @include line-height(50);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(40);
                @include line-height(40);
            }
        }

        .overflow {
            display: flex;
            overflow: hidden;
            overflow-x: auto;
            width: 100%;
            @include customScrollbarOnGray;

            .schedule {
                display: flex;
                flex-direction: column;
                margin-top: $base-padding;
                width: 100%;
                min-width: 800px;

                .schedule-header {
                    display: grid;
                    grid-template-columns: 0.75fr 1.5fr 1fr 1fr 1fr 1fr;
                    background-color: $base-dark-tone;
                    padding: $base-padding-half 0;
                    border-top-left-radius: $border-radius;
                    border-top-right-radius: $border-radius;

                    .info {
                        @include font-size(18);
                        font-weight: $weight-medium;
                        color: $white;
                        text-align: center;
                    }
                }

                .schedule-line-item {
                    display: grid;
                    grid-template-columns: 0.75fr 1.5fr 1fr 1fr 1fr 1fr;
                    border-bottom: $border-thickness solid $off-white;
                    overflow: hidden;

                    .block {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 10px;
                        @include font-size(14);
                        @include line-height(20);
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        text-transform: uppercase;
                        width: 100%;
                        height: 100%;

                        // &:first-of-type {
                        //     padding-left: calc($base-padding-half + 10px);
                        // }

                        // &:last-of-type {
                        //     padding-right: calc($base-padding-half + 10px);
                        // }

                        &.time {
                            background-color: $white;
                        }

                        &.gray {
                            background-color: $darker-off-white;
                        }

                        &.main {
                            background-color: $green;
                        }

                        &.group {
                            background-color: $blue;
                        }
                    }

                    &:last-of-type {
                        border-bottom-left-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                }
            }
        }
    }
}
