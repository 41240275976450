.tooltip-container {
    display: flex;
    position: relative;

    .tooltip-button {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        span {
            position: absolute;
            width: 100%;
            height: 100%;
            visibility: visible;
            line-height: 30px;
            @include transition;
        }

        .icon {
            visibility: hidden;
            opacity: 0;
            fill: $white;
            width: 16px;
            height: 16px;
            @include transition;
        }

        &.show {
            span {
                visibility: hidden;
                opacity: 0;
            }

            .icon {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .tooltip-modal {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 40px;
        visibility: hidden;
        opacity: 0;
        @include transition;
        transition-property: opacity !important;

        &.show {
            visibility: visible;
            opacity: 1;
        }

        .tooltip {
            width: calc(100vw - 40px);
            max-width: 400px;
            background-color: $light-blue;
            border: $border-thickness solid $blue;
            border-radius: $border-radius;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 15px;
            position: relative;

            .tooltip-message {
                @include font-size(16);
                @include line-height(24);
                margin: 0;
                width: calc(100% - 70px);
                text-align: center;
            }

            .button {
                width: 160px;
                height: 40px;
                border-radius: 20px;
                margin-top: 10px;
                @include font-size(14);
                transition-property: opacity !important;
            }

            .close-button {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 30px;
                height: 30px;
                margin: 0;
                display: none;

                .icon {
                    fill: $white;
                    width: 18px;
                    height: 18px;
                }
            }

            @media screen and (max-width: $small-breakpoint) {
                padding: 10px;
                .tooltip-message {
                    @include font-size(14);
                    @include line-height(20);
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        position: unset;

        .tooltip-modal {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1000;
            position: fixed;
            background-color: $modal-color;

            .tooltip {
                .close-button {
                    display: flex;
                }
            }
        }
    }
}
