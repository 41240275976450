.scholar-opportunities {
    background-color: $base-dark-tone;

    .scholar-content {
        display: flex;
        flex-direction: column;
        padding: $base-vertical-padding 0;

        .scholar-tagline {
            @include headerFont;
            @include font-size(50);
            @include line-height(50);
            text-align: center;
            margin: 0 auto;
            padding-bottom: $base-padding-half;
            color: $white;
        }

        .scholar-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: $base-grid-padding;
            margin-top: $base-padding;

            @import './scholar-card.scss';
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .scholar-content {
            .scholar-tagline {
                @include font-size(40);
                @include line-height(40);
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .scholar-content {
            padding: $small-vertical-padding 0;

            .scholar-tagline {
                @include font-size(30);
                @include line-height(30);
            }
            .scholar-grid {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .scholar-content {
            padding: $mobile-vertical-padding 0;
        }
    }
}
