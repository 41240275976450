.latest-news {
    width: 100%;
    padding: $base-vertical-padding $base-padding;
    background-color: $off-white;

    @import '../common/section-header.scss';

    .news-cards {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $base-grid-padding;
        grid-auto-rows: 1fr;

        @import './article-card.scss';
    }

    @media screen and (max-width: $large-breakpoint) {
        padding: $large-vertical-padding $base-padding;
        .news-cards {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            .article-card {
                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding: $medium-vertical-padding $base-padding;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $small-vertical-padding $base-padding-half;
        .news-cards {
            gap: $base-padding-half;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            .article-card {
                &:nth-child(3) {
                    display: flex;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: $mobile-vertical-padding $mobile-base-padding;
    }
}
