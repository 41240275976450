.add-or-edit-game-content {
    display: grid;
    gap: $base-padding-half;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .input-field {
        border-color: $off-white;

        &:focus {
            border-color: $blue;
        }
    }

    .select__control {
        border-color: $off-white;

        &:hover {
            border-color: transparent;
        }

        &.select__control--is-focused,
        &:active,
        &:focus {
            border-color: $blue;
        }
    }

    .primary-game-toggle {
        display: flex;
        align-items: center;
        justify-content: center;

        .description {
            @include font-size(14);
            margin-right: 10px;
            font-weight: $weight-bold;
        }

        .toggle-switch-container {
            margin-left: 10px;
        }
    }

    .button-container {
        display: grid;
        gap: $base-padding-half;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .full {
            grid-column: span 1;
        }

        .button {
            width: 100%;
            max-width: 250px;
            margin: 0 auto;
            height: 50px;
            border-radius: 25px;
            @include font-size(16);
        }

        @media screen and (max-width: $mobile-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
