.upcoming-events {
    width: 100%;
    background-color: $base-dark-tone;
    padding: $base-vertical-padding $base-padding;
    background-image: url('/images/spin-upcoming-events.jpg');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @import '../common/section-header.scss';

    .section-header {
        .section-title {
            color: $white;
        }
        .section-cta {
            svg {
                fill: $white;
            }
        }
    }

    .event-cards {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: $base-grid-padding;
        grid-auto-rows: 1fr;

        @import './event-card.scss';
    }

    @media screen and (max-width: $huge-breakpoint) {
        .event-cards {
            grid-template-columns: repeat(4, minmax(0, 1fr));

            .event-card {
                &:nth-child(5) {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: $large-breakpoint) {
        padding: $large-vertical-padding $base-padding;
        .event-cards {
            grid-template-columns: repeat(3, minmax(0, 1fr));

            .event-card {
                &:nth-child(4) {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding: $medium-vertical-padding $base-padding;
        .event-cards {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            .event-card {
                &:nth-child(4) {
                    display: flex;
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $small-vertical-padding $base-padding-half;
        .event-cards {
            gap: $base-padding-half;
            grid-template-columns: repeat(1, minmax(0, 1fr));

            .event-card {
                &:nth-child(4) {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: $mobile-vertical-padding $mobile-base-padding;
    }
}
