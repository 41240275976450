.pricing-options {
    position: relative;
    z-index: 2;
    align-items: center;
    max-width: 1110px;
    display: flex;
    margin: 0 auto;
    gap: $base-grid-padding;

    .title-container {
        width: 100%;

        .title {
            @include headerFont;
            @include font-size(48);
            @include line-height(48);
            margin: 0;
            color: $white;
            position: relative;
            z-index: 2;
        }
    }

    .payment-intervals {
        display: flex;
        background-color: $off-dark-tone-darker;
        padding: 15px;
        height: 80px;
        width: 100%;
        max-width: 330px;
        margin: 0 auto;
        border-radius: 40px;

        .payment-interval {
            @include button;
            width: 100%;
            height: 50px;
            border-radius: 25px;
            color: $white;
            background-color: transparent;
            @include font-size(14);
            max-width: 115px;
            height: 40px;
            border-radius: 20px;

            &:hover {
                color: $blue;
            }

            &.active {
                background-color: $blue;
                color: $white;
            }
        }
    }

    .payment-intervals {
        padding: 10px;
        height: 60px;
        max-width: 250px;
        border-radius: 30px;
        margin: 0;
        margin-top: $base-padding-half;

        .payment-interval {
            @include font-size(14);
            max-width: 115px;
            height: 40px;
            border-radius: 20px;
        }
    }

    .pricing-options-container {
        width: calc(100% - 390px);
        display: flex;
        gap: $base-grid-padding;
        margin-left: auto;
        flex-shrink: 0;
        justify-content: flex-end;
        @import "./subscriptions-pricing-option.scss";
    }

    @media screen and (max-width: 1500px) {
        padding: 0;
        flex-direction: column;

        .pricing-options-container {
            margin: 0 auto;
            justify-content: center;
            width: 100%;
        }

        .title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .title {
                text-align: center;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        gap: $base-padding-half;

        .pricing-options-container {
            gap: $base-padding-half;
        }

        .title-container {
            .title {
                @include font-size(32);
                @include line-height(32);
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .pricing-options-container {
            flex-direction: column;
        }
    }

    

    &.college {
        display: grid;
        grid-template-columns: 25% repeat(4, minmax(0, 1fr));
        gap: 0;
        
        .pricing-options-container {
            grid-column: span 4;
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            width: auto;
            gap: 0;
            align-items: flex-end;

            .pricing-option-container {
                width: calc(100% - 20px);
                margin: 0 auto;
            }
        }

        @media screen and (max-width: $xlarge-breakpoint) {
            display: flex;
            padding: 0;
            flex-direction: column;
            grid-template-columns: unset;
    
            .pricing-options-container {
                margin: 0 auto;
                justify-content: center;
                width: 100%;
                align-items: flex-end;
            }
    
            .title-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: $base-padding;

                .title {
                    text-align: center;
                }
            }
        }

        @media screen and (max-width: $large-breakpoint) {
            .pricing-options-container {
                width: 100%;
                max-width: 460px;
                gap: $base-padding-half;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            
                .pricing-option-container {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            .pricing-options-container {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }
}
