.high-school-my-player-card {
    @include light-card;
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;

    .player-card-content {
        display: flex;
        flex-direction: column;
        padding: $base-padding-half;
        gap: $base-padding-half;
        
        .player-info-container {
            display: flex;
        
            .image-wrapper {
                @include image-wrapper(60px, 60px, 100%);
                flex-shrink: 0;
            }
        
            .player-info {
                display: flex;
                flex-direction: column;
                align-self: center;
                margin-left: 10px;
        
                .player-name {
                    @include font-size(18);
                    @include line-height(18);
                    margin: 0;
                }
        
                .team-label {
                    @include font-size(12);
                    @include line-height(12);
                    margin: 0;
                    color: $base-text-on-white;
                    margin-top: 5px;
                }
                .team-name {
                    @include font-size(14);
                    @include line-height(14);
                    margin: 0;
                    font-weight: $weight-bold;
                }
            }
        }

        .interested-colleges-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .none-interested,
            .interested-college-label {
                @include font-size(12);
                @include line-height(12);
                margin: 0;
                color: $base-text-on-white;
                margin-bottom: 5px;
                text-transform: uppercase;
            }

            .interested-college-list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .info-button {
                    overflow: hidden;
                    padding: 6px 15px;
                    min-height: 30px;
                    height: auto;
                    &.discord {
                        .icon {
                            fill: $discord;
                        }
                    }
                }
            }
        }

    }
}
