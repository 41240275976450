.generic-confirmation-popup {
    display: grid;
    gap: $base-padding-half;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    p {
        @include font-size(18);
        @include line-height(24);
        margin: 0;
        span {
            color: $blue;
            font-weight: $weight-bold;
        }
    }

    .button-container {
        display: grid;
        gap: $base-padding-half;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .button {
            width: 100%;
            max-width: 250px;
            margin: 0 auto;
            height: 50px;
            border-radius: 25px;
            @include font-size(16);
        }

        @media screen and (max-width: $mobile-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
