$black: #08080e;
$black-shadow: rgba(8, 8, 14, 0.15);
$black-on-white-shadow: rgba(8, 8, 14, 0.05);
$off-black: #151521;
$off-black-hover: #27273a;
$base-text-on-black: #939598;

$base-dark-tone: #141235;
$base-dark-tone-hover: #0c0b20;
$off-dark-tone: #3a385f;
$base-shadow: rgba(20, 18, 53, 0.3);
$base-text-on-dark-tone: #dedeeb;
$modal-color: rgba(20, 18, 53, 0.8);

$grey: #555555;
$light-grey: #707070;

$white: #ffffff;
$off-white: #e7e7e8;
$dark-off-white: #e0e0e5;
$darker-off-white: #d1d1d7;
$base-text-on-white: #5b5a73;

$off-dark-tone-darker: #0e0d21;

$scrollbar-thumb: rgba(0, 0, 0, 0.3);

$coming-soon-white: rgba(237, 237, 240, 0.6);
$coming-soon-grey: rgba(225, 225, 229, 0.7);
$coming-soon-description-text: #525072;
$stat-icon-background: rgba(41, 172, 219, 0.15);
$stat-border: #211f4b;

$blue: #29acdb;
$blue-hover: #259dc9;
$blue-shadow: rgba(41, 172, 219, 0.3);
$green: #11bd8f;
$dark-green: #0c9f78;
$light-green: #e7f8f4;
$green-hover: #0faa81;
$green-shadow: rgba(17, 189, 143, 0.3);
$purple: #6464a0;
$light-purple: #dcdcff;
$purple-hover: #5d5d92;
$purple-shadow: rgba(100, 100, 160, 0.3);
$red: #db2953;
$light-red: #f8d4dd;
$lighter-red: #fdedf1;
$red-hover: #bd1f44;
$dark-red: #a18a98;
$red-shadow: rgba(219, 41, 83, 0.3);
$orange: #f97316;
$orange-hover: #ea580c;
$orange-shadow: rgba(234, 88, 12, 0.3);
$light-blue: #d4eef8;
$yellow: #dbcf29;
$black-shadow: rgba(20, 18, 53, 0.3);
$coke-red: hsl(358, 100%, 48%);
$dreamseat-blue: #236092;
$f1-red: #e10600;
$brightspeed-orange: #f0492e;

$blue-overlay: rgba(41, 172, 219, 0.1);
$off-white-overlay: rgba(231, 231, 232, 0.15);

$discord: #5865f2;

$disabled-button: rgba(0, 0, 0, 0.07);

@import './game-colours';

$header-font-family: 'WinnerSans', Helvetica, Arial, sans-serif;
$base-font-family: 'HCo Gotham', Helvetica, Arial, sans-serif;
$base-font-size: 16;

$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;
$weight-black: 800;

$border-radius: 15px;
$border-radius-medium: 10px;
$border-radius-small: 8px;
$border-thickness: 2px;

$full-site-width: 1550px;
$slim-site-width: 1140px;

$huge-breakpoint: 1700px;
$xlarge-breakpoint: 1590px;
$mobile-nav-breakpoint: 1460px;
$large-breakpoint: 1400px;
$xmedium-breakpoint: 1300px;
$medium-breakpoint: 1050px;
$small-breakpoint: 750px;
$small-dashboard-breakpoint: 600px;
$mobile-breakpoint: 500px;
$tiny-breakpoint: 350px;

$base-grid-padding: 30px;
$base-padding: 40px;
$base-padding-half: 20px;
$mobile-base-padding: 20px;

$base-vertical-padding: 150px;
$large-vertical-padding: 100px;
$medium-vertical-padding: 80px;
$small-vertical-padding: 40px;
$mobile-vertical-padding: 40px;

$navigation-height: 90px;
$mobile-navigation-height: 70px;
