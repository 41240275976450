.profile-views-container {
    display: grid;
    padding: $base-grid-padding;
    gap: $base-grid-padding;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-height: 100%;

    .all-profile-views-card {
        @extend .dashboard-card;
        grid-column: span 2;
        max-height: 100%;
        overflow: hidden;

        .content {
            @include customScrollbarOnWhite;
            display: grid;
            max-height: 100%;
            overflow: auto;
            gap: 10px;
            position: relative;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            &.inactive {
                overflow: hidden;
                min-height: 200px;
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .all-profile-views-card {
            grid-column: span 1;

            .content {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        gap: $base-padding-half;
        max-height: none;

        .all-profile-views-card {
            .content {
                &.inactive {
                    max-height: 400px;
                }
                .unsubbed-container {
                    .cta-container {
                        .title {
                            @include font-size(24);
                            @include line-height(24);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        .all-profile-views-card {
            .content {
                .unsubbed-container {
                    .cta-container {
                        .title {
                            @include font-size(18);
                            @include line-height(18);
                        }
                        .subtitle {
                            @include font-size(12);
                            @include line-height(16);
                        }
                        .button {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
