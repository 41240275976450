.my-vods {
    display: grid;
    gap: $base-grid-padding;
    margin: $base-grid-padding;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @import "./add-vod-form-card.scss";

    .vod-display-container {
        align-self: flex-start;
        grid-column: span 2;
        display: grid;
        gap: 10px;

        @import "./vod-card.scss";

        .empty-vod-display {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius;
            background-color: $dark-off-white;
            border: $border-thickness dashed $darker-off-white;
            text-align: center;
            @include font-size(18);
            @include line-height(18);
            font-weight: $weight-black;
            color: $base-text-on-white;
            text-transform: uppercase;
            height: 65px;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .vod-display-container {
            grid-column: span 1;
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        gap: $base-padding-half;
        margin: $base-padding-half;

        .required-formatter {
            top: 10px;
        }
    }
}
