.profile-card {
    width: 100%;
    background-color: $white;
    padding: $base-grid-padding;
    border-radius: $border-radius;
    display: flex;
    @include baseShadowOnWhite;

    &.dark-theme {
        background-color: $base-dark-tone;
        color: $white;

        .title-container {
            .subtitle {
                text-transform: uppercase;
                color: $base-text-on-dark-tone;
            }
        }
    }

    &.green-theme {
        background-color: $green;
        color: $white;

        .title-container {
            .subtitle {
                text-transform: uppercase;
                color: $base-text-on-dark-tone;
            }
        }
    }

    .title-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 20px;

        &.no-pad {
            padding-right: 0;
        }

        .title {
            @include headerFont;
            @include font-size(32);
            @include line-height(32);
            margin: 0;
        }

        .subtitle {
            margin: 0;
            margin-top: 2px;
            text-transform: uppercase;
            color: $base-text-on-white;
            @include font-size(14);
            @include line-height(14);
        }
    }

    .value {
        @include font-size(42);
        @include line-height(42);
        font-weight: $weight-black;
        margin: 0;
        margin-left: auto;
        flex-shrink: 0;
        align-self: center;

        &.gpa {
            color: $green;
        }

        &.class {
            color: $purple;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        .list-item {
            width: 100%;
            text-decoration: none;
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            margin-top: 10px;

            &:first-child {
                margin-top: 15px;
            }
        }
    }

    @import "../players/profile/primary-game.scss";
    @import "../players/profile/profile-card-links.scss";
    @import "../colleges/profile/games.scss";
    @import "../colleges/profile/academic-requirements.scss";
    @import "../colleges/profile/college-media.scss";
    @import "../colleges/profile/location.scss";

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        .title-container {
            .title {
                @include font-size(24);
                @include line-height(24);
            }

            .subtitle {
                @include font-size(12);
                @include line-height(12);
            }
        }
        .value {
            @include font-size(32);
            @include line-height(32);
        }
    }
}
