.scholar-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: auto;

    .gamer-icon-container {
        display: flex;
        position: relative;
        margin: 0;
        height: auto;

        .icon {
            display: flex;
            width: 100%;
            height: 100px;

            @media screen and (max-width: $small-breakpoint) {
                height: 80px;
            }
        }

        @import '../icons/scholarship-icons.scss';
    }

    .card-description {
        @include font-size(18);
        @include line-height(24);
        margin: 0;
        margin-top: $base-padding-half;
        color: $white;
        text-transform: uppercase;
        text-align: center;
        opacity: 70%;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(20);
        }
    }

    .card-tagline {
        @include headerFont;
        @include font-size(45);
        @include line-height(45);
        margin: 0;
        text-align: center;
        color: $white;

        @media screen and (max-width: $medium-breakpoint) {
            @include font-size(35);
            @include line-height(35);
        }

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(25);
            @include line-height(25);
        }
    }
}
