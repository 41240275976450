.whats-next-card {
    @include light-card;
    padding: $base-padding-half;
    display: flex;
    flex-direction: column;

    .thumbnail {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: $border-radius;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &.join-discord {
            background-image: url("/images/whats-next/whats-next-card-discord.jpg");
        }

        &.contact-us {
            background-image: url("/images/whats-next/whats-next-card-contact.jpg");
        }

        &.membership {
            background-image: url("/images/whats-next/whats-next-card-membership.jpg");
        }

        &.find-colleges,
        &.find-players {
            background-image: url("/images/whats-next/whats-next-card-find-colleges.jpg");
        }

        .icon {
            fill: $white;
            width: 96px;
            height: 96px;
            margin: $base-padding 0;
        }
    }

    .title {
        @include font-size(18);
        @include line-height(24);
        margin: 0;
        margin-top: 15px;
        text-transform: uppercase;
        font-weight: $weight-black;
    }

    .description {
        @include font-size(14);
        @include line-height(18);
        margin: 0;
        margin-bottom: 16px;
    }

    .button {
        margin-top: auto;
        @include font-size(16);
        width: 100%;
        height: 50px;
        border-radius: 25px;
    }

    @media screen and (max-width: $small-breakpoint) {
        .title {
            @include font-size(16);
            @include line-height(20);
        }

        .description {
            @include font-size(12);
            @include line-height(16);
        }

        .button {
            @include font-size(14);
            height: 40px;
            border-radius: 20px;
        }
    }
}
