.committed-to-college-card {
    @include light-card;
    background-color: $light-green;
    border: $border-thickness solid $green;
    display: flex;
    align-items: center;
    padding: 20px;
    @include font-size(16);
    @include line-height(18);

    .icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        fill: $green;
        margin-right: 10px;
    }
}
