.events-list-cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $base-grid-padding;
    grid-auto-rows: 1fr;
    margin: $base-padding auto;
    margin-bottom: $base-vertical-padding;

    @import "./event-card.scss";

    .show-more {
        width: 100%;
        max-width: 180px;
        border-radius: 25px;
        height: 50px;
        margin: 0 auto;
        grid-column: span 4;
    }

    @media screen and (max-width: $large-breakpoint) {
        margin: $base-padding auto;
        margin-bottom: $large-vertical-padding;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        .show-more {
            grid-column: span 3;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin: $base-padding auto;
        margin-bottom: $medium-vertical-padding;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .show-more {
            grid-column: span 2;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        grid-auto-rows: unset;
        margin: $base-padding-half auto;
        gap: $base-padding-half;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .show-more {
            grid-column: span 1;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .show-more {
            height: 40px;
        }
    }
}
