.dashboard-card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $white;
    border-radius: $border-radius;

    &.dark-theme {
        background-color: $base-dark-tone;

        .title-container {
            border-color: $stat-border;
            .title {
                color: $white;
            }
        }
    }

    .title-container {
        display: flex;
        align-items: center;
        padding: 15px $base-padding-half;
        border-bottom: $border-thickness solid $off-white;
        .title {
            margin: 0;
            @include headerFont;
            @include font-size(28);
            @include line-height(28);
        }

        .button {
            margin-left: auto;
            height: 30px;
            width: 120px;
            @include font-size(14);
            @include line-height(14);
        }
    }

    .tab-container {
        display: flex;
        height: 60px;
        border-bottom: $border-thickness solid $off-white;
        position: relative;
        overflow-y: visible;
        overflow-x: visible;
        @include customScrollbarOnWhite;

        .tab-button {
            position: relative;
            flex-shrink: 0;
            padding: 10px 20px;
            height: 100%;
            top: 2px;
            display: flex;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            font-weight: $weight-bold;
            text-align: center;
            color: $base-dark-tone;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: $border-thickness solid $off-white;
            @include transition;

            .icon {
                margin-right: 10px;
            }

            &.active,
            &:hover {
                border-color: $blue;
                color: $blue;
                .icon {
                    fill: $blue;
                }
            }

            &[disabled] {
                border-color: $off-white;
                color: $off-white;
                .icon {
                    fill: $off-white;
                }
            }
        }
    }

    .content {
        display: grid;
        gap: $base-padding-half;
        padding: $base-padding-half;
    }

    @media screen and (max-width: 850px) {
        .tab-container {
            overflow-y: hidden;
            overflow-x: auto;

            &.no-overflow {
                overflow-y: visible;
                overflow-x: visible;
            }

            .tab-button {
                position: relative;
                flex-shrink: 0;
                span {
                    display: none;
                }

                .icon {
                    margin: 0;
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .title-container {
            .title {
                @include font-size(24);
                @include line-height(24);
            }
        }

        .content {
            padding: 10px;
            gap: 10px;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .title-container {
            .title {
                @include font-size(22);
                @include line-height(22);
            }

            .button {
                @include font-size(12);
                @include line-height(12);
                width: 110px;
            }
        }
    }
}
