.search-bar {
    width: 100%;
    max-width: 500px;
    position: relative;
    display: flex;

    &.dark-theme {
        .input-field {
            border-color: $off-dark-tone;
            color: $white;

            &::placeholder {
                color: $base-text-on-black;
            }
        }

        .search-button {
            .icon {
                fill: $white;
            }
        }
    }

    label {
        display: none;
    }

    .input-field {
        max-width: 100%;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        border: $border-thickness solid $off-white;
        background-color: transparent;
        padding-left: 25px;
        padding-right: 50px;
        @include font-size(14);
        outline: 0;
        font-weight: $weight-regular;
        font-family: $base-font-family;
        @include transition;

        &::placeholder {
            color: $base-text-on-white;
        }

        &:active,
        &:focus {
            border-color: $blue;
        }
    }

    .search-button {
        @include button;
        width: 50px;
        height: 50px;
        background-color: $blue;
        display: flex;
        flex-shrink: 0;
        margin-left: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        color: $white;
        @include font-size(14);

        .icon {
            width: 18px;
            height: 18px;
            fill: $white;
            @include transition;
        }

        &:hover {
            background-color: $blue-hover;
        }
    }

    .invisible-search-button {
        display: none;
    }

    @media screen and (max-width: $small-breakpoint) {
        .input-field {
            height: 40px;
            padding-left: $base-padding-half;
        }
        .search-button {
            width: 40px;
            height: 40px;
        }
    }
}
