.statistic-container {
    display: flex;
    @include dark-card;
    color: $white;
    align-items: center;
    padding: $base-padding-half $base-grid-padding;
    min-height: 80px;

    .stat-label {
        @include headerFont;
        @include font-size(28);
        @include line-height(28);
        margin: 0;
    }

    .stat-value-container {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .stat-value {
        @include font-size(28);
        @include line-height(28);
        font-weight: $weight-bold;
        margin: 0;
    }

    .stat-icon {
        background-color: $stat-icon-background;
        width: 34px;
        height: 34px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        .icon {
            width: 20px;
            height: 20px;
            fill: $blue;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        min-height: 60px;

        .stat-label {
            @include font-size(24);
            @include line-height(24);
        }

        .stat-value {
            @include font-size(24);
            @include line-height(24);
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;

        .stat-label {
            @include font-size(22);
            @include line-height(22);
            text-align: center;
        }

        .stat-value {
            @include font-size(18);
            @include line-height(18);
            text-align: center;
        }

        .stat-value-container {
            margin-left: 0;
            margin-top: 10px;
        }

        .stat-icon {
            width: 28px;
            height: 28px;
            margin-right: 10px;

            .icon {
                width: 18px;
                height: 18px;
            }
        }
    }
}
