.player-bio-container {
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    @include baseShadowOnWhite;

    @import "./player-info";
    @import "../../profiles/biography.scss";

    .info-button-container {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        .info-button {
            margin-top: 10px;
            margin-right: 10px;

            &.discord {
                cursor: default;
                &:hover {
                    border-color: $off-white;
                }
                .icon {
                    fill: $discord;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .tooltip-container {
            margin-top: 10px;
            margin-right: 10px;
            .tooltip-button {
                background-color: $blue;
                .icon {
                    fill: $white;
                    visibility: visible;
                    opacity: 1;
                }

                &.show {
                    background-color: $blue;
                }
            }

            .tooltip-modal {
                left: 0;
                right: auto;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .info-button-container {
            align-items: center;
            justify-content: center;
            gap: 10px;

            .info-button {
                width: 100%;
                max-width: 200px;
                overflow: hidden;
                margin: 0px auto;
            }

            .tooltip-container {
                margin: 0;
            }
        }
    }
}
