.event-header {
    margin-top: $navigation-height;
    position: relative;
    padding: 80px 0;
    display: flex;

    .background-wrapper {
        .background-image {
            border-radius: 0;
            img {
                border-radius: 0;
            }
        }
    }

    @import "./event-info-container.scss";

    &.registering {
        padding-bottom: 160px;

        .event-info-container {
            padding-right: 0;
        }
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        &.registering {
            padding-bottom: 160px;

            .event-info-container {
                padding-right: $base-padding;
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding-bottom: calc(80px + #{$border-radius});
        &.registering {
            padding-bottom: calc(80px + #{$border-radius});
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-top: 40px;
        padding-bottom: calc(40px + #{$border-radius});
        &.registering {
            padding-bottom: calc(40px + #{$border-radius});
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
    }
}
