.formatted-content {
    position: relative;
    z-index: 1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $weight-black;
        text-transform: uppercase;
        margin: 0;
        margin-top: 1rem;
    }
    h1 {
        @include font-size(28);
        @include line-height(28);
    }
    h2 {
        @include font-size(24);
        @include line-height(24);
    }
    h3 {
        @include font-size(18);
        @include line-height(18);
    }
    h4 {
        @include font-size(16);
        @include line-height(16);
    }
    h5 {
        @include font-size(14);
        @include line-height(14);
    }
    h6 {
        @include font-size(12);
        @include line-height(12);
    }

    a {
        text-decoration: none;
        color: $blue;
        @include transition;

        &:hover {
            color: $blue-hover;
            text-decoration: underline;
        }
    }

    b,
    strong {
        margin-top: 1rem;
        font-weight: $weight-black;
    }

    p {
        @include font-size(14);
        @include line-height(18);
        margin-bottom: 0;
        margin-top: 1rem;
    }

    ul {
        margin-top: 1rem;
    }
    li {
        @include font-size(15);
        @include line-height(22);
        margin-top: 1rem;
    }

    *:first-child {
        margin-top: 0;
    }

    *:last-child {
        margin-bottom: 0;
    }

    @media screen and (max-width: $medium-breakpoint) {
        h1 {
            @include font-size(24);
            @include line-height(24);
        }
        h2 {
            @include font-size(22);
            @include line-height(22);
        }
        h3 {
            @include font-size(18);
            @include line-height(18);
        }
        h4 {
            @include font-size(16);
            @include line-height(16);
        }
        h5 {
            @include font-size(14);
            @include line-height(14);
        }
        h6 {
            @include font-size(12);
            @include line-height(12);
        }
        p {
            @include font-size(14);
            @include line-height(18);
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        h1 {
            @include font-size(22);
            @include line-height(22);
            margin-bottom: 1.25rem;
        }
        h2 {
            @include font-size(20);
            @include line-height(20);
            margin-bottom: 1.25rem;
        }
        h3 {
            @include font-size(16);
            @include line-height(16);
            margin-bottom: 1.25rem;
        }
        h4 {
            @include font-size(14);
            @include line-height(14);
            margin-bottom: 1.25rem;
        }
        h5 {
            @include font-size(14);
            @include line-height(14);
            margin-bottom: 1.25rem;
        }
        h6 {
            @include font-size(14);
            @include line-height(14);
            margin-bottom: 1.25rem;
        }
        p {
            @include font-size(14);
            @include line-height(18);
            margin-bottom: 1.25rem;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        h1 {
            @include font-size(20);
            @include line-height(20);
            margin-bottom: 1rem;
        }
        h2 {
            @include font-size(18);
            @include line-height(18);
            margin-bottom: 1rem;
        }
        h3 {
            @include font-size(14);
            @include line-height(14);
            margin-bottom: 1rem;
        }
        h4,
        h5,
        h6,
        p {
            margin-bottom: 1rem;
        }
    }
}
