.clearfix {
    &::after {
        content: '';
        clear: both;
        display: table;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
}

* {
    font-variant-ligatures: none;
}

html {
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

body {
    margin: 0;
    color: $base-dark-tone;
    background-color: $off-white;
    font-family: $base-font-family;
    font-weight: $weight-regular;
}

:root {
    font-size: $base-font-size + px;
    @import './toastify.scss';
}

.container {
    @include container;

    &.slim {
        max-width: $slim-site-width;
    }
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before,
.spinner:after {
    position: absolute;
    content: '';
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.background-wrapper {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    .background-image {
        height: 100%;
        position: relative;
        border-radius: $border-radius;
        background-color: $base-dark-tone;
        img {
            border-radius: $border-radius;
        }
    }
}

.error-container {
    margin-top: $navigation-height;
    @include defaultContainerPadding;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .brand {
        fill: $base-dark-tone;
        width: 150px;
        height: 150px;
    }

    .error-title {
        margin: 0;
        margin-top: $base-padding-half;
        text-align: center;
        @include headerFont;
        @include font-size(72);
    }

    @media screen and (max-width: $small-breakpoint) {
        .brand {
            width: 100px;
            height: 100px;
        }
        .error-title {
            @include font-size(32);
            @include line-height(32);
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
    }
}

.generic-link {
    text-decoration: none;
    color: $blue;

    &:hover {
        text-decoration: underline;
    }
}

.rotating {
    @include rotate;
}

.react-loading-skeleton {
    --base-color: rgba(0, 0, 0, 0.15) !important;
    @include pulseAnimation;

    &::after {
        display: none !important;
    }
}

.full-page-skeleton {
    padding: $base-grid-padding;
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: $border-radius;
    .react-loading-skeleton {
        border-radius: $border-radius;
    }
}

@import './generic-page.scss';
