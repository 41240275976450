.step-card {
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    border: $border-thickness solid $off-white;
    display: flex;

    &.green-card {
        background-color: $light-green;
        border-color: $green;
    }

    .step-number-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: $base-grid-padding;

        .step-label {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            text-transform: uppercase;
            font-weight: $weight-black;
        }
        .step-number {
            @include headerFont;
            @include font-size(110);
            @include line-height(110);
            margin: 0;
            text-transform: uppercase;
            font-weight: $weight-black;
        }
    }

    .step-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .sign-up-label {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            text-transform: uppercase;
            font-weight: $weight-black;
            text-align: center;
        }

        .button {
            margin: 0 auto;
            margin-top: $base-padding-half;
        }

        .step-two-points {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            padding-left: 17px;

            li {
                @include font-size(18);
                @include line-height(36);
                color: $base-text-on-white;
            }
        }

        .get-recruited {
            display: flex;
            color: $green;
            margin: 0 auto;
            @include font-size(42);
            @include line-height(42);
            text-transform: uppercase;
            font-weight: $weight-black;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;

        .step-number-container {
            margin-right: $base-padding-half;
            .step-label {
                @include font-size(14);
                @include line-height(14);
            }
            .step-number {
                @include font-size(72);
                @include line-height(72);
            }
        }

        .step-content {
            .sign-up-label {
                @include font-size(16);
                @include line-height(16);
            }

            .button {
                height: 50px;
                width: 200px;
                margin-top: 10px;
            }

            .step-two-points {
                li {
                    @include font-size(16);
                    @include line-height(32);
                    color: $base-text-on-white;
                }
            }

            .get-recruited {
                @include font-size(32);
                @include line-height(32);
            }
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        .step-number-container {
            .step-number {
                @include font-size(60);
                @include line-height(60);
            }
        }

        .step-content {
            .sign-up-label {
                @include font-size(14);
                @include line-height(14);
            }

            .button {
                height: 40px;
                width: 100%;
                max-width: 180px;
                @include font-size(14);
            }

            .step-two-points {
                li {
                    @include font-size(14);
                    @include line-height(20);
                    color: $base-text-on-white;
                    margin-top: 5px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            .get-recruited {
                @include font-size(24);
                @include line-height(24);
            }
        }
    }
    @media screen and (max-width: $tiny-breakpoint) {
        .step-content {
            .sign-up-label {
                @include font-size(12);
                @include line-height(12);
            }

            .button {
                height: 40px;
                width: 100%;
                max-width: 180px;
                @include font-size(14);
            }

            .step-two-points {
                li {
                    @include font-size(12);
                    @include line-height(18);
                    color: $base-text-on-white;
                }
            }

            .get-recruited {
                @include font-size(20);
                @include line-height(20);
            }
        }
    }
}
