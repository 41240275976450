.invite-colleagues {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $base-padding-half;

    @import "./invite-colleague-form.scss";
    @import "./colleague-list.scss";

    @media screen and (max-width: $xmedium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
