&.primary-game-card {
    .title-container {
        position: relative;
        padding: 0;

        .title,
        .subtitle {
            padding-right: 60px;
        }

        .primary-game-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            margin-left: auto;
            margin-top: auto;

            .icon {
                width: 24px;
                height: 24px;
            }
        }

        .info-button {
            margin-top: $base-padding-half;
            height: 40px;
            border-radius: 20px;
        }

        .game-info {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .primary-game-info-container {
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                .info-label {
                    @include font-size(14);
                    @include line-height(14);
                    margin: 0;
                    text-transform: uppercase;
                }

                .info-value {
                    @include font-size(18);
                    @include line-height(18);
                    margin: 0;
                    text-transform: uppercase;
                    font-weight: $weight-black;
                    color: $blue;
                }
            }

            @media screen and (max-width: $mobile-breakpoint) {
                margin-top: 10px;
                flex-direction: column;

                .primary-game-info-container {
                    margin-top: 10px;
                    .info-label {
                        @include font-size(12);
                        @include line-height(12);
                        margin: 0;
                        text-transform: uppercase;
                    }

                    .info-value {
                        @include font-size(16);
                        @include line-height(16);
                        margin: 0;
                        text-transform: uppercase;
                        font-weight: $weight-black;
                        color: $blue;
                    }
                }
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            .info-button {
                height: 30px;
                border-radius: $border-radius;
            }

            .primary-game-icon {
                width: 40px;
                height: 40px;

                .icon {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
