.article-container {
    max-width: 1000px;
    margin: $base-vertical-padding auto;
    margin-top: calc(#{$base-padding} + #{$navigation-height});

    .article-image-wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 50%;
        margin-bottom: 2rem;
        .article-image {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            flex-shrink: 0;
            background-color: $white;

            img {
                border-radius: $border-radius;
            }
        }
    }

    .article-title {
        @include font-size(42);
        @include line-height(42);
        text-transform: uppercase;
        font-weight: $weight-black;
        margin: 0;
        margin-bottom: 1rem;
    }

    .article-date {
        @include font-size(18);
        @include line-height(18);
        margin-top: 0;
        margin-bottom: 2rem;
        font-weight: $weight-bold;
    }

    @import "./article-content.scss";

    @media screen and (max-width: 1080px) {
        padding: 0 $base-padding;
    }

    @media screen and (max-width: $large-breakpoint) {
        margin-bottom: $large-vertical-padding;
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin-bottom: $medium-vertical-padding;
        .article-title {
            @include font-size(36);
            @include line-height(36);
        }
        .article-date {
            @include font-size(16);
            @include line-height(16);
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: 0 $base-padding-half;
        margin-bottom: $small-vertical-padding;
        .article-title {
            @include font-size(28);
            @include line-height(28);
        }
        .article-date {
            @include font-size(14);
            @include line-height(14);
            margin-bottom: 1.25rem;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: calc(#{$base-padding} + #{$mobile-navigation-height});
        .article-title {
            @include font-size(24);
            @include line-height(24);
            margin-bottom: 0.5rem;
        }
        .article-date {
            @include font-size(12);
            @include line-height(12);
            margin-bottom: 1rem;
        }
    }
}
