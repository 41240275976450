.banner {
    background-color: $light-blue;
    border: $border-thickness solid $blue;
    padding: $base-grid-padding;
    border-radius: $border-radius;
    margin-bottom: $base-grid-padding;
    display: flex;
    align-items: center;

    .description-container {
        display: flex;
        flex-direction: column;
        max-width: 1000px;

        &.has-cta {
            padding-right: 100px;
        }

        .title {
            @include headerFont;
            @include font-size(32);
            @include line-height(32);
            margin: 0;
        }

        .subtitle {
            @include font-size(14);
            @include line-height(20);
            color: $base-text-on-white;
            margin: 0;
            margin-top: 10px;

            .link {
                color: $blue;
                font-weight: $weight-medium;
            }
        }
    }

    .button {
        flex-shrink: 0;
        width: 240px;
        height: 50px;
        margin-left: auto;
    }
}
