.player-preview-card-skeleton {
    border-radius: $border-radius;
    height: 186px;
}

.player-preview-card {
    display: flex;
    flex-direction: column;

    @import "./card-top-container.scss";
    @import "./card-bottom-container.scss";
}
