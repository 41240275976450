.player-info-container {
    display: flex;

    .image-wrapper {
        @include image-wrapper(60px, 60px, 100%);
        flex-shrink: 0;
    }

    .player-info {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-left: 10px;

        .player-name {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
        }

        .team-label {
            @include font-size(12);
            @include line-height(12);
            margin: 0;
            color: $base-text-on-white;
            margin-top: 5px;
        }
        .team-name {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            font-weight: $weight-bold;
        }
    }
}
