.activations-recruiting {
    @include defaultContainerPadding;
    padding-top: 0;

    .container {
        .title {
            @include headerFont;
            @include font-size(60);
            @include line-height(60);
            margin: 0 auto;
            text-align: center;
        }

        .descrip {
            @include font-size(18);
            @include line-height(24);
            margin: 0 auto;
            margin-top: $base-padding-half;
            text-align: center;
            width: 85%;
        }

        .button-row {
            margin-top: $base-padding;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .items-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: $base-grid-padding;
            margin-top: $base-padding;

            .item {
                display: flex;
                flex-direction: column;
                border-radius: $border-radius;
                overflow: hidden;
                height: 100%;

                .top {
                    display: flex;
                    padding: $base-padding-half;
                    background-color: $base-dark-tone;

                    .card-title {
                        @include headerFont;
                        @include font-size(30);
                        color: $white;
                        margin: 0;
                    }
                }

                .bottom {
                    padding: $base-padding-half;
                    background-color: $white;
                    height: 100%;
                    margin-bottom: auto;

                    .list-item {
                        margin-top: 10px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $medium-breakpoint) {
            .title {
                @include font-size(50);
                @include line-height(50);
            }

            .descrip {
                @include font-size(16);
                @include line-height(22);
                width: 90%;
            }

            .items-grid {
                grid-template-columns: repeat(2, minmax(0, 1fr));

                .item {
                    .top {
                        .card-title {
                            @include headerFont;
                            @include font-size(25);
                        }
                    }

                    .bottom {
                        .list-item {
                            @include font-size(14);
                            @include line-height(20);
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            .title {
                @include font-size(40);
                @include line-height(40);
            }

            .descrip {
                width: 95%;
            }

            .items-grid {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }
}
