.calendar-section {
    @include dark-card;
    padding-top: 0;
    margin: $base-vertical-padding 0;

    .header-row {
        display: flex;
        flex-direction: row;
        padding: $base-padding-half 0;

        .calendar-title {
            @include headerFont;
            @include font-size(40);
            @include line-height(40);
            margin: 0;
            padding: $base-padding-half 0;
            color: $white;
        }

        .select-field-container-partners {
            margin-left: auto;
            max-width: 400px;
            width: 100%;
            align-self: center;

            .select__control {
                border-color: $off-white;

                &.select__control--is-focused,
                &:active,
                &:focus {
                    border-color: $blue;
                }
            }

            .select__placeholder {
                @include font-size(14);
                font-weight: $weight-regular;
            }
            .select__single-value,
            .select__input-container {
                @include font-size(14);
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            flex-direction: column;

            .calendar-title {
                padding: 0;
                padding-top: $base-padding-half;
                margin-bottom: 10px;
            }

            .select-field-container-partners {
                margin-left: 0px;
                align-self: flex-start;
                max-width: none;
            }
        }
    }

    .calendar-container {
        overflow-x: auto;
        border-radius: $border-radius;
        @include customScrollbarOnWhite;

        .calendar {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            min-width: 1100px;

            .days-header {
                display: grid;
                grid-template-columns: repeat(7, minmax(0, 1fr));

                .header-day {
                    @include font-size(20);
                    @include line-height(24);
                    margin: 0;
                    margin-bottom: 5px;
                    color: $white;
                    text-transform: uppercase;
                    font-weight: $weight-bold;
                    text-align: end;
                    padding-right: 7px;
                }
            }

            .days-grid {
                display: grid;
                grid-template-columns: repeat(7, minmax(0, 1fr));
                border-radius: $border-radius;
                overflow: hidden;

                .calendar-day {
                    display: flex;
                    flex-direction: column;
                    background-color: $white;
                    color: $base-dark-tone;
                    border-right: $border-thickness solid $off-white;
                    border-bottom: $border-thickness solid $off-white;
                    align-items: center;
                    padding: $base-padding-half 0;
                    min-height: 165px;

                    &:nth-child(-n + 7) {
                        border-top: $border-thickness solid $off-white;
                    }

                    &:nth-child(7n + 1) {
                        border-left: $border-thickness solid $off-white;
                    }

                    &:last-child {
                        border-bottom-right-radius: $border-radius;
                    }

                    &.empty {
                        background-color: $darker-off-white;
                        color: $grey;
                    }

                    .date {
                        @include font-size(18);
                        @include line-height(18);
                        font-weight: $weight-medium;
                        margin: 0;
                        margin-bottom: 10px;
                        align-self: flex-end;
                        margin-right: 10px;
                    }

                    .name {
                        @include headerFont;
                        @include font-size(30);
                        @include line-height(30);
                        margin: 0;
                        margin-bottom: 10px;
                    }

                    .time {
                        @include font-size(18);
                        @include line-height(18);
                        margin: 0;
                        margin-bottom: 10px;
                    }

                    .game-twitch-row {
                        display: flex;
                        flex-direction: row;

                        .icon {
                            fill: $base-dark-tone;

                            &:first-child {
                                margin-right: 10px;
                            }
                        }

                        .twitch {
                            @include transition;
                            &:hover {
                                fill: $blue;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            .calendar {
                min-width: 800px;

                .days-header {
                    .header-day {
                        @include font-size(16);
                        @include line-height(20);
                    }
                }

                .days-grid {
                    .calendar-day {
                        min-height: 135px;
                        padding: 10px 0;

                        .date {
                            @include font-size(14);
                            @include line-height(14);
                        }

                        .name {
                            @include font-size(20);
                            @include line-height(20);
                        }

                        .time {
                            @include font-size(14);
                            @include line-height(14);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        margin: $base-padding 0;

        .header-row {
            .calendar-title {
                @include font-size(30);
                @include line-height(30);
            }
        }
    }
}
