.article-card {
    display: flex;
    padding-top: 56.25%;
    border-radius: $border-radius;
    position: relative;
    overflow: hidden;
    @include baseShadowOnBlack;

    .overlay {
        @include colourToTransparentGradient($black);
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
    }

    .background-wrapper {
        .background-image {
            @include transition;
        }
    }

    &:hover {
        .background-wrapper {
            .background-image {
                transform: scale(105%);
            }
        }
    }

    &.on-home {
        .article-content {
            .article-title {
                @include font-size(24);
                @include line-height(24);
            }
        }
    }

    .article-content {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;

        .article-title {
            margin: 0;
            text-transform: uppercase;
            font-weight: $weight-black;
            color: $white;

            @include font-size(20);
            @include line-height(20);
            @include clampLines(2);
        }

        .article-date {
            color: $base-text-on-black;
            line-height: 1rem;
            margin: 0;
            margin-top: 5px;
            @include clampLines(1);
        }
    }

    @import "./article-card-media-queries.scss";
}
