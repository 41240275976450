.my-events {
    padding: $base-grid-padding;
    display: flex;
    height: 100%;

    @import "./my-events-card.scss";
    @import "./my-upcoming-events.scss";

    @import "./my-events-skeletons.scss";

    @media screen and (max-width: $xmedium-breakpoint) {
    }

    @media screen and (max-width: $medium-breakpoint) {
        flex-direction: column;
        height: auto;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
    }
}

.my-events-high-school {
    .content {
        display: flex;
        flex-direction: column;
        padding: $base-grid-padding;
        gap: $base-grid-padding;
        width: 100%;
        
        @import "./explore-events-card.scss";

        .my-events-card {
            display: flex;
            flex-direction: column;
            gap: $base-padding-half;

            .team-name {
                @include font-size(24);
                @include line-height(28);
                font-family: $header-font-family;
                margin: 0;
            }

            .events-list {
                display: grid;
                @include gridColumns(3);
                gap: $base-padding-half;
                @include customScrollbarOnWhite;
                @import "../../events/event-card.scss";
                @import "../dashboard-event-cards.scss";

                @media screen and (max-width: $medium-breakpoint) { 
                    @include gridColumns(2);
                }
                @media screen and (max-width: $small-breakpoint) { 
                    @include gridColumns(1);
                }
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            padding: $base-padding-half;
            gap: $base-padding-half;
        }
    }
}

