@keyframes fade {
    0% {
        opacity: 0;
    }
}

@keyframes fadeAndMove {
    0% {
        transform: translateY(0.4em);
        opacity: 0;
    }
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes floatInverse {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@mixin hover($duration) {
    -webkit-animation: $duration float ease-in-out infinite;
    animation: float $duration ease-in-out infinite;
}

@mixin hoverInverse($duration) {
    -webkit-animation: $duration floatInverse ease-in-out infinite;
    animation: floatInverse $duration ease-in-out infinite;
}

@mixin fade-in {
    -webkit-animation: 0.25s 0.25s fade backwards ease-out;
    animation: 0.25s 0.25s fade backwards ease-out;
}

@mixin fade-in-and-move($speed) {
    -webkit-animation: $speed $speed fadeAndMove backwards ease-out;
    animation: $speed $speed fadeAndMove backwards ease-out;
}

@mixin fade-in-and-move-fast {
    -webkit-animation: 0.15s 0.15s fadeAndMove backwards ease-out;
    animation: 0.15s 0.15s fadeAndMove backwards ease-out;
}

@mixin fade-in-slow {
    -webkit-animation: 0.5s 0.5s fade backwards ease-out;
    animation: 0.5s 0.5s fade backwards ease-out;
}

@mixin fade-in-modal {
    -webkit-animation: 0.125s 0.125s fade backwards ease-out;
    animation: 0.125s 0.125s fade backwards ease-out;
}

@mixin fast-transition {
    transition: all ease 0.15s !important;
}

@mixin transition {
    transition: all ease 0.3s !important;
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@mixin rotate {
    -webkit-animation: rotating 1.5s linear infinite;
    -moz-animation: rotating 1.5s linear infinite;
    -ms-animation: rotating 1.5s linear infinite;
    -o-animation: rotating 1.5s linear infinite;
    animation: rotating 1.5s linear infinite;
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

@mixin pulseAnimation {
    -webkit-animation: 1.5s ease-in-out 0.5s infinite normal none running flickerAnimation;
    -moz-animation: 1.5s ease-in-out 0.5s infinite normal none running flickerAnimation;
    -o-animation: 1.5s ease-in-out 0.5s infinite normal none running flickerAnimation;
    animation: 1.5s ease-in-out 0.5s infinite normal none running flickerAnimation;
}
