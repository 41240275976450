.tag {
    display: flex;
    align-items: center;
    min-height: 24px;
    background-color: $light-blue;
    border-radius: $border-radius-medium;

    .label {
        color: $base-dark-tone;
        @include font-size(14);
        padding: 4px 10px;
        font-weight: $weight-medium;
    }

    .close-button {
        flex-shrink: 0;
        width: 24px;
        height: 100%;
        border-radius: $border-radius-medium;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        cursor: pointer;
        background-color: transparent;
 
        svg {
            width: 16px;
            height: 16px;
        }

        &:hover {
            background-color: $blue;
            color: $base-dark-tone;
        }
    }
}
