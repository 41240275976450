.event-page-container {
    display: flex;
    @import "./event-navigation.scss";
    @import "./event-page-card.scss";
    @import "./event-registration-card.scss";
    @import "./event-sponsors.scss";

    .left-panel {
        width: 100%;
    }

    @media screen and (max-width: $medium-breakpoint) {
        display: grid;
        grid-auto-rows: 1fr;
        grid-template:
            "registration"
            "info"
            "sponsors";
        position: relative;
        top: -$border-radius;
        padding: 0;
        gap: $base-grid-padding;
        margin-bottom: calc(#{$medium-vertical-padding} - #{$border-radius});

        .event-registration-card {
            grid-area: registration;
            padding-bottom: 0;
        }

        .left-panel {
            grid-area: info;
            width: 100%;
            padding: 0 $base-grid-padding;
        }

        .mobile-sponsors-card {
            grid-area: sponsors;
            margin: auto $base-grid-padding;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        margin-bottom: calc(#{$small-vertical-padding} - #{$border-radius});
        gap: $base-padding-half;
        .left-panel {
            padding: 0 $base-padding-half;
        }
        .mobile-sponsors-card {
            grid-area: sponsors;
            margin: auto $base-padding-half;
        }
    }
}
