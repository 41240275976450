.drag-n-drop-field-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }

    .drag-n-drop-container {
        width: 100%;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: $base-padding-half;
        border-radius: $border-radius;
        border: $border-thickness solid transparent;
        @include transition;
        cursor: pointer;

        &.active,
        &:hover {
            border-color: $blue;
        }

        .icon {
            fill: $off-white;
        }

        .loading-icon {
            width: 70px;
            height: 70px;
            padding: 20px;
            fill: $base-dark-tone;
        }

        .description {
            margin: 0;
            margin-top: 10px;
            color: $base-text-on-white;
            text-align: center;
        }
    }

    &.error {
        .drag-n-drop-container {
            border-color: $red;
            background-color: $light-red;

            .icon {
                fill: $dark-red;
            }
        }
    }
}

@import "./profile-picture.scss";
@import "./gallery-image.scss";
