.journey-college-row {
    display: flex;
    flex-direction: row;
    gap: $base-grid-padding;
    align-items: center;
    justify-content: center;
    padding: $large-vertical-padding 0;

    .journey-college-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 200px;

        .college-icon {
            @include image-wrapper(130px, 130px, 100%);
        }

        .name {
            @include headerFont;
            @include font-size(30);
            @include line-height(30);
            color: $white;
            margin: 0;
            text-align: center;
        }
    }
}
