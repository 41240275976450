.button {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    height: 50px;
    border-radius: 25px;
    @include font-size(16);

    @media screen and (max-width: $small-breakpoint) {
        max-width: 200px;
        height: 40px;
        border-radius: 20px;
        @include font-size(14);
    }
}

.cta-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    @import "../cta-card.scss";
}
