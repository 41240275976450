.sub-card-grid {
    margin: 0 auto;
    display: flex;
    gap: $base-padding;
    margin-top: $base-padding;
    max-width: 1130px;
    width: 100%;

    &.college {
        max-width: 1520px;

        @media screen and (max-width: $large-breakpoint) {
            flex-direction: column;
            
            .pricing-card {
                margin: 0 auto;
                max-width: 500px;
        
                &.margin-top {
                    margin-top: 0;
                }
        
                .button-container {
                    .button {
                        max-width: 300px;
                    }
                }
            }
        }
    }

    @import "./pricing-card/pricing-card.scss";

    @media screen and (max-width: $medium-breakpoint) {
        flex-direction: column;
    }
    @media screen and (max-width: $small-breakpoint) {
        gap: $base-padding-half;
        margin-top: $base-padding-half;
    }
}
