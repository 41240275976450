.info-panel {
    margin-left: auto;
    width: 110px;
    padding: 20px;
    background-color: $base-dark-tone;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .info-icon {
        display: flex;
        width: 70px;
        height: 100%;
        padding: 10px 0;

        .icon {
            margin: 0 auto;
            width: 70px;
            height: 70px;
            fill: $white;
            // align-self: center;

            &.wreath {
                .cls-1,
                .cls-4 {
                    fill: #141235;
                }

                .cls-2 {
                    fill: #fff;
                }

                .cls-3 {
                    clip-path: url(#clip-path);
                }

                .cls-4 {
                    font-size: 32px;
                    font-family: WinnerSans-CompExtraBold, Winner Sans;
                    font-weight: 800;
                }
            }
        }

        .def-rank {
            display: flex;
            background-color: $white;
            border-radius: 100%;
            width: 70px;
            height: 70px;

            .rank-num {
                @include headerFont;
                @include font-size(40);
                @include line-height(40);
                color: $base-dark-tone;
                align-self: center;
                margin: 0 auto;
            }
        }
    }

    .info-display {
        display: flex;
        flex-direction: column;
        width: 70px;
    }

    .display-label {
        text-align: center;
        margin: 0;
        color: $base-text-on-dark-tone;
        text-transform: uppercase;
        @include font-size(11);
        @include line-height(11);
        margin-bottom: 3px;
    }

    .display {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white;
        font-weight: $weight-black;
        @include font-size(18);
        @include line-height(18);
        border-radius: $border-radius-small;
    }

    .gpa {
        background-color: $green;
        margin-bottom: 20px;
    }
    .year {
        background-color: $purple;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px;
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
        flex-direction: row;

        .info-display {
            margin: 0 7.5px;
        }

        .display {
            @include font-size(16);
            @include line-height(16);
        }

        .gpa {
            margin-bottom: 0;
        }
    }
}
