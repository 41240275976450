.card-content {
    width: 100%;
    padding: 15px;
    background-color: $white;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    .profile-image {
        @include image-wrapper(90px, 90px, 100%);
        flex-shrink: 0;
    }

    .feat-description {
        margin: $base-padding-half 0;

        .description {
            @include font-size(14);
            @include line-height(18);
            color: $base-dark-tone;
        }
    }

    @import './card-bio.scss';
    @import './card-rank.scss';
    @import './card-games.scss';

    @media screen and (max-width: $mobile-breakpoint) {
        border-bottom-left-radius: 0;
        border-top-right-radius: $border-radius;
        .profile-image {
            width: 70px;
            height: 70px;
        }
    }
}
