.player-game-info-container {
    display: flex;
    flex-direction: column;
    margin-top: $base-padding-half;

    .game-container {
        display: flex;
        align-items: center;

        .icon-container {
            width: 50px;
            height: 50px;

            .icon {
                width: 26px;
                height: 26px;
            }
        }

        .game-info {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            .info-container {
                &:first-child {
                    margin-bottom: 10px;
                }

                .info-label {
                    @include font-size(12);
                    @include line-height(12);
                    margin: 0;
                    color: $base-text-on-white;
                }

                .info-value {
                    @include font-size(14);
                    @include line-height(14);
                    margin: 0;
                    font-weight: $weight-bold;
                    color: $blue;
                    text-transform: uppercase;
                    margin-top: 3px;
                }
            }
        }
    }

    .tracker-button {
        width: 100%;
        margin-top: 10px;
        &.disabled {
            background-color:rgba(0, 0, 0, 0.07);
            box-shadow: none;
            cursor: not-allowed;
        }
    }
}
