.conversation-controls {
    display: flex;
    min-height: 60px;
    flex-shrink: 0;
    align-items: center;
    border-top: $border-thickness solid $off-white;
    padding: 10px;

    .max-messages {
        @include font-size(14);
    }

    @import "./conversation-navigation.scss";

    .selected-message {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 30px;
        padding: 7px 50px;
        padding-left: 15px;
        background-color: $blue;
        color: $white;
        border-radius: $border-radius;
        margin-right: 10px;
        @include font-size(14);
        @include line-height(16);

        .close-button {
            position: absolute;
            top: 0;
            right: 0;
            flex-shrink: 0;
            background-color: transparent;
            width: 40px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: 100%;

            &:hover {
                background-color: $blue-hover;
            }
            .icon {
                fill: $white;
                width: 18px;
                height: 18px;
            }
        }
    }

    .send-button {
        flex-shrink: 0;
        margin-left: auto;
        background-color: $blue;
        border-color: $blue;

        .icon {
            fill: $white;
        }

        &:hover {
            background-color: $blue-hover;
            border-color: $blue-hover;
        }

        &:disabled {
            &:hover {
                border-color: $off-white;
                .icon {
                    fill: $base-text-on-black;
                }
            }
        }
    }
}
