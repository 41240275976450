.conversation-navigation {
    display: flex;
    position: relative;
    width: 100%;

    .message-button {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        text-transform: none;
        font-weight: $weight-regular;
        padding: 6px 10px;
        border-radius: 10px;
        margin-right: $base-padding-half;
        @include font-size(14);

        .icon {
            width: 20px;
            height: 20px;
            fill: $base-dark-tone;
        }

        &:hover,
        &.active {
            background-color: $light-blue;
        }
    }

    @import "./response-selector.scss";

    @media screen and (max-width: $small-breakpoint) {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        &.has-compliments {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        .message-button {
            margin-right: 0;
            @include font-size(12);
        }
    }

    @media screen and (max-width: $small-dashboard-breakpoint) {
        gap: 5px;
        .message-button {
            @include font-size(10);
        }
    }

    @media screen and (max-width: 470px) {
        gap: 5px;
        .message-button {
            span {
                display: none;
            }
        }
    }
}
