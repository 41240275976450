.home-journey-new {
    background-color: $white;
    @include defaultContainerPadding;

    .dashboard-features {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 0;
        gap: $base-grid-padding;

        .home-feature-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            background-color: $base-dark-tone;
            border-radius: $border-radius;
            padding: $base-grid-padding;
            overflow: hidden;
            @include baseShadowOnWhite;

            .background-icon {
                width: 500px;
                height: 500px;
                position: absolute;
                top: calc(50% - 250px);
                left: calc(50% - 250px);
                transform: rotate(15deg);
                fill: $purple;
                opacity: 0.2;
            }

            .feature-icon {
                position: relative;
                z-index: 2;
                margin-bottom: 10px;

                .icon {
                    width: 110px;
                    height: 65px;

                    &.green {
                        fill: $green;
                    }

                    &.purple {
                        fill: $purple;
                    }

                    &.blue {
                        fill: $blue;
                    }

                    &.plat-message {
                        .cls-1,
                        .cls-2,
                        .cls-3 {
                            fill: none;
                        }

                        .cls-2 {
                            stroke: #59ba92;
                        }

                        .cls-2,
                        .cls-3 {
                            stroke-linecap: round;
                            stroke-width: 4px;
                        }

                        .cls-3 {
                            stroke: #fff;
                        }

                        .cls-4,
                        .cls-5 {
                            stroke: none;
                        }

                        .cls-5 {
                            fill: #fff;
                        }
                    }

                    &.profile-view {
                        .cls-1 {
                            fill: #fff;
                        }

                        .cls-2 {
                            clip-path: url(#clip-path);
                        }

                        .cls-3,
                        .cls-4 {
                            fill: none;
                        }

                        .cls-4 {
                            stroke: #59ba92;
                            stroke-linecap: round;
                            stroke-width: 4px;
                        }

                        .cls-5,
                        .cls-6 {
                            stroke: none;
                        }

                        .cls-6 {
                            fill: #fff;
                        }
                    }

                    &.scouting-report {
                        .cls-1 {
                            fill: #11bd8f;
                        }

                        .cls-2 {
                            fill: none;
                        }

                        .cls-3 {
                            fill: #fff;
                        }

                        .cls-4 {
                            clip-path: url(#clip-path);
                        }

                        .cls-5,
                        .cls-6 {
                            stroke: none;
                        }

                        .cls-6 {
                            fill: #fff;
                        }
                    }
                }
            }

            .feature-content {
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 2;

                .feature-label {
                    @include headerFont;
                    @include font-size(30);
                    @include line-height(30);
                    margin: 0;
                    margin-bottom: 5px;
                    text-align: center;
                    color: $white;
                }
                .feature-description {
                    @include font-size(14);
                    @include line-height(16);
                    margin: 0;
                    text-align: center;
                    color: $base-text-on-dark-tone;
                }
            }
            @media screen and (max-width: $xmedium-breakpoint) {
                .feature-icon {
                    width: 100px;
                    height: 100px;
                    .icon {
                        width: 100px;
                        height: 100px;
                    }
                }
                .feature-label {
                    @include font-size(24);
                    @include line-height(24);
                    margin: 0;
                    margin-bottom: 5px;
                    color: $white;
                }
            }
            @media screen and (max-width: $medium-breakpoint) {
                .feature-icon {
                    width: 100px;
                    height: 100px;
                    .icon {
                        width: 100px;
                        height: 100px;
                    }
                }
                .feature-label {
                    @include font-size(32);
                    @include line-height(32);
                }
                .background-icon {
                    width: 1000px;
                    height: 1000px;
                    top: calc(50% - 500px);
                    left: calc(50% - 500px);
                }
            }
            @media screen and (max-width: $small-breakpoint) {
                padding: $base-padding-half;
                .feature-icon {
                    width: 80px;
                    height: 80px;
                    .icon {
                        width: 80px;
                        height: 80px;
                    }
                }
                .feature-content {
                    padding-left: $base-padding-half;
                }
                .feature-label {
                    @include font-size(24);
                    @include line-height(24);
                }
                .background-icon {
                    width: 600px;
                    height: 600px;
                    top: calc(50% - 300px);
                    left: calc(50% - 300px);
                }
            }
            @media screen and (max-width: $mobile-breakpoint) {
                .feature-icon {
                    width: 60px;
                    height: 60px;
                    .icon {
                        width: 60px;
                        height: 60px;
                    }
                }
                .feature-label {
                    @include font-size(22);
                    @include line-height(22);
                }
                .feature-description {
                    @include font-size(12);
                    @include line-height(14);
                }
                .background-icon {
                    width: 500px;
                    height: 500px;
                    top: calc(50% - 250px);
                    left: calc(50% - 250px);
                }
            }
            @media screen and (max-width: $tiny-breakpoint) {
                .feature-icon {
                    width: 60px;
                    height: 60px;
                    .icon {
                        width: 60px;
                        height: 60px;
                    }
                }
                .feature-label {
                    @include font-size(20);
                    @include line-height(20);
                }
                .background-icon {
                    width: 350px;
                    height: 350px;
                    top: calc(50% - 175px);
                    left: calc(50% - 175px);
                }
            }
        }
    }

    .journey-slogan {
        padding: 0;
        margin-bottom: 30px;
        .title {
            @include font-size(60);
            @include line-height(60);
            color: $blue;
        }
    }

    .more-info {
        margin: 0 auto;
        margin-top: 60px;
        width: 360px;
        height: 80px;
        border-radius: 40px;
        @include font-size(28);
    }

    @media screen and (max-width: $medium-breakpoint) {
        .journey-slogan {
            .title {
                @include font-size(50);
                @include line-height(50);
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .dashboard-features {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .journey-steps-card {
            margin-top: $base-padding-half;
        }

        .journey-slogan {
            .title {
                @include font-size(40);
                @include line-height(40);
            }
            margin-bottom: $base-padding;
        }
        .more-info {
            margin-top: $base-padding;
            width: 280px;
            height: 60px;
            border-radius: 40px;
            @include font-size(24);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .journey-slogan {
            .title {
                @include font-size(30);
                @include line-height(30);
            }
            margin-bottom: $base-padding-half;
        }
        .more-info {
            margin-top: $base-padding-half;
            width: 240px;
            height: 50px;
            border-radius: 40px;
            @include font-size(18);
        }
    }
}
