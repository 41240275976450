.scouting-report-player-cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $base-grid-padding;
    padding: $base-grid-padding;

    @import "./scouting-report-player-card.scss";

    .no-players-container {
        grid-column: span 4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: $base-vertical-padding 0;

        .icon {
            fill: $base-dark-tone;
            width: 100px;
            height: 100px;
        }

        .no-players-label {
            @include font-size(28);
            @include line-height(28);
            text-transform: uppercase;
            margin: 0;
            margin-top: $base-padding-half;
            font-weight: $weight-black;
            text-align: center;
        }

        .no-players-message {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            margin-top: 5px;
            text-align: center;
            color: $base-text-on-white;
        }
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        .no-players-container {
            grid-column: span 3;
        }
    }

    @media screen and (max-width: $large-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .no-players-container {
            grid-column: span 2;
            margin: $large-vertical-padding 0;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .no-players-container {
            margin: $medium-vertical-padding 0;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        gap: $base-padding-half;

        .no-players-container {
            margin: $small-vertical-padding 0;
            .icon {
                width: 80px;
                height: 80px;
            }

            .no-players-label {
                @include font-size(24);
                @include line-height(24);
            }
        }
    }

    @media screen and (max-width: $small-dashboard-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        .no-players-container {
            grid-column: span 1;
            margin: $mobile-vertical-padding 0;

            .icon {
                width: 60px;
                height: 60px;
            }

            .no-players-label {
                @include font-size(18);
                @include line-height(18);
            }

            .no-players-message {
                @include font-size(12);
                @include line-height(12);
            }
        }
    }
}
