.tag-select-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    &.has-required {
        .tag-select-label {
            left: 40px;
        }
    }

    .tag-select-label {
        position: absolute;
        top: $base-padding-half;
        left: $base-padding-half;
        margin: 0;
        @include font-size(14);
        @include line-height(14);
        z-index: 1;

        span {
            margin-left: 15px;
            @include font-size(12);
            color: $base-text-on-white;
        }
    }

    .required {
        z-index: 1;
        color: $blue;
        @include font-size(24);
        font-weight: $weight-bold;
        position: absolute;
        left: 20px;
        top: 17px;
    }

    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }

    .tag-select__control {
        padding: 16px;
        padding-top: 40px;
        min-height: 95px;
        border-radius: $border-radius;
        border: $border-thickness solid transparent;
        box-shadow: none;

        &:hover {
            border-color: transparent;
        }

        &.tag-select__control--is-focused {
            border-color: $blue;
        }

        .tag-select__placeholder {
            @include font-size(14);
        }

        .tag-select__input-container {
            @include font-size(14);
            &:hover {
                cursor: text;
            }
        }

        .tag-select__value-container {
            padding: 0;
        }

        .tag-select__multi-value {
            display: flex;
            align-items: center;
            height: 24px;
            background-color: $light-blue;
            border-radius: $border-radius-medium;

            .tag-select__multi-value__label {
                color: $base-dark-tone;
                @include font-size(14);
                padding: 0 10px;
                font-weight: $weight-medium;
            }

            .tag-select__multi-value__remove {
                height: 100%;
                border-radius: $border-radius-medium;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                cursor: pointer;

                svg {
                    width: 16px;
                    height: 16px;
                }

                &:hover {
                    background-color: $blue;
                    color: $base-dark-tone;
                }
            }
        }
    }

    &.error {
        .tag-select__control {
            border-color: $red;
            background-color: $light-red;
        }
    }

    @media screen and (max-width: $small-dashboard-breakpoint) {
        .tag-select-label {
            display: flex;
            flex-direction: column;
            padding-right: $base-padding-half;

            span {
                margin-left: 0;
                @include font-size(12);
                color: $base-text-on-white;
                margin-top: 3px;
            }
        }

        .tag-select__control {
            padding-top: 60px;
        }
    }
    @media screen and (max-width: 450px) {
        .tag-select__control {
            padding-top: 70px;
        }
    }
}
