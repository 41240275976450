.rankings-card {
    @include light-card;
    padding-top: 0;
    margin: $base-padding 0;

    .rankings-title {
        @include headerFont;
        @include font-size(40);
        @include line-height(40);
        margin: 0;
        padding: $base-padding-half 0;
    }

    .rankings-container {
        overflow-x: auto;
        border-radius: $border-radius;
        @include customScrollbarOnWhite;

        .rankings {
            width: 100%;
            text-align: center;
            border-collapse: separate;
            border-spacing: 0;
            min-width: 800px;

            .no-rankings {
                padding: $base-padding-half 0px;
                border: $border-thickness solid $off-white;
                background-color: $off-white;
                @include font-size(20);
                @include line-height(20);
                text-transform: uppercase;
                font-weight: $weight-bold;
            }

            .rankings-head {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                background-color: $base-dark-tone;

                &.school {
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                }

                .rankings-heading {
                    padding: 18.5px 5px;
                    border-bottom: $border-thickness solid $base-dark-tone;
                    @include font-size(18);
                    text-transform: uppercase;
                    font-weight: $weight-black;
                    color: $white;

                    &:first-child {
                        border-left: $border-thickness solid $base-dark-tone;
                        border-top-left-radius: $border-radius;
                    }
                    &:last-child {
                        border-right: $border-thickness solid $base-dark-tone;
                        border-top-right-radius: $border-radius;
                    }
                }
            }

            .rankings-row {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

                &.school {
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                }

                &:nth-child(odd) {
                    background: $off-white;
                }

                .rankings-data {
                    padding: 14.5px 5px;
                    border-bottom: $border-thickness solid $off-white;
                    @include font-size(16);

                    &:first-child {
                        border-left: $border-thickness solid $off-white;
                    }

                    &:last-child {
                        border-right: $border-thickness solid $off-white;
                    }
                }

                &:last-child {
                    .rankings-data {
                        &:first-child {
                            border-bottom-left-radius: $border-radius;
                        }
                    }
                }

                &:last-child {
                    .rankings-data {
                        &:last-child {
                            border-bottom-right-radius: $border-radius;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .rankings-title {
            @include font-size(30);
            @include line-height(30);
        }

        .rankings-container {
            overflow-x: auto;
            border-radius: $border-radius;
            @include customScrollbarOnWhite;

            .rankings {
                width: 100%;
                text-align: center;
                border-collapse: separate;
                border-spacing: 0;
                min-width: 800px;

                .no-rankings {
                    padding: $base-padding-half 0px;
                    border: $border-thickness solid $off-white;
                    border-bottom-right-radius: $border-radius;
                    border-bottom-left-radius: $border-radius;
                    background-color: $off-white;
                    @include font-size(16);
                    @include line-height(16);
                    text-transform: uppercase;
                    font-weight: $weight-bold;
                }

                .rankings-head {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    background-color: $base-dark-tone;

                    &.school {
                        grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.5fr;
                    }

                    .rankings-heading {
                        padding: 18.5px 5px;
                        border-bottom: $border-thickness solid $base-dark-tone;
                        @include font-size(14);
                        text-transform: uppercase;
                        font-weight: $weight-black;
                        color: $white;

                        &:first-child {
                            border-left: $border-thickness solid $base-dark-tone;
                            border-top-left-radius: $border-radius;
                        }
                        &:last-child {
                            border-right: $border-thickness solid $base-dark-tone;
                            border-top-right-radius: $border-radius;
                        }
                    }
                }

                .rankings-row {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

                    &.school {
                        grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.5fr;
                    }

                    &:nth-child(odd) {
                        background: $off-white;
                    }

                    .rankings-data {
                        padding: 14.5px 5px;
                        border-bottom: $border-thickness solid $off-white;
                        @include font-size(14);

                        &:first-child {
                            border-left: $border-thickness solid $off-white;
                        }

                        &:last-child {
                            border-right: $border-thickness solid $off-white;
                        }
                    }

                    &:last-child {
                        .rankings-data {
                            &:first-child {
                                border-bottom-left-radius: $border-radius;
                            }
                        }
                    }

                    &:last-child {
                        .rankings-data {
                            &:last-child {
                                border-bottom-right-radius: $border-radius;
                            }
                        }
                    }
                }
            }
        }
    }
}
