.pricing-feature-comparison {
    background-color: $white;
    border-radius: $border-radius;
    max-width: 1110px;
    margin: 0 auto;
    margin-top: $base-grid-padding;
    width: 100%;
    position: relative;
    z-index: 2;
    @include baseShadowOnWhite;

    @import '../../journey/feature-header.scss';
    @import '../../journey/feature-group.scss';
    @import '../../journey/mobile-journey/feature-list-mobile.scss';

    .feature-header {
        top: 0;
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin-top: $base-padding-half;
        .feature-header {
            margin: 0;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            .filler {
                display: none;
            }
        }
    }
}
