.nav-dropdown {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border: none;
    padding: 10px $base-padding-half;
    align-items: center;
    @include transition;

    .nav-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .nav-dropdown-link {
            text-transform: uppercase;
            text-decoration: none;
            font-weight: $weight-bold;
            padding-right: 20px;
            @include font-size(16);
            text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
            @include transition;
            color: $base-dark-tone;

            &:hover,
            &.active {
                color: $blue;
            }
        }

        .arrow {
            @include transition;
            width: 12px;
            height: 12px;
            flex-shrink: 0;
            fill: $base-dark-tone;
            transform: rotate(90deg);

            &:hover,
            &.active {
                fill: $blue;
            }
        }
    }

    .links {
        z-index: 4;
        position: absolute;
        width: calc(100% + 4px);
        // align-items: center;
        display: flex;
        left: 0px;
        top: 38px;
        flex-direction: column;
        background-color: $white;
        border: $border-thickness solid $blue;
        border-radius: $border-radius;
        padding: 6px $base-padding-half;
        @include fade-in-and-move-fast;
        @include baseShadowOnWhite;

        .link {
            text-transform: uppercase;
            text-decoration: none;
            color: $base-dark-tone;
            @include font-size(14);
            @include line-height(24);
            @include transition;
            font-weight: 500;

            &:hover {
                color: $blue;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        width: 60px;
        padding: 0;
        justify-content: center;
        position: absolute;
        right: $base-padding-half;

        .links {
            border-top-left-radius: $border-radius;
        }

        .username {
            &.college-name {
                display: none !important;
            }
            display: none;
        }
        .arrow {
            display: none;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        padding: 0;
        justify-content: center;
        position: absolute;
        right: $base-padding-half;
        border: 0;

        &.active {
            .profile-picture {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                img {
                    border-color: $blue !important;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .default-picture {
                    border-color: $blue;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        .links {
            top: 38px;
            right: 0px;
        }
    }
}
