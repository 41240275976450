& {
    @media screen and (max-width: $medium-breakpoint) {
        .article-content {
            padding: 20px;
            .article-title {
                @include font-size(20);
                @include line-height(20);
            }
        }
        &.on-home {
            .article-content {
                .article-title {
                    @include font-size(20);
                    @include line-height(20);
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .article-content {
            .article-title {
                @include font-size(24);
                @include line-height(24);
            }
            .article-date {
                margin-top: 3px;
                @include font-size(12);
                @include line-height(12);
            }
        }
        &.on-home {
            .article-content {
                .article-title {
                    @include font-size(24);
                    @include line-height(24);
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .article-content {
            .article-title {
                @include font-size(20);
                @include line-height(20);
            }
        }
        &.on-home {
            .article-content {
                .article-title {
                    @include font-size(20);
                    @include line-height(20);
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .article-content {
            padding: 15px;
            .article-title {
                @include font-size(18);
                @include line-height(18);
            }
        }
        &.on-home {
            .article-content {
                .article-title {
                    @include font-size(18);
                    @include line-height(18);
                }
            }
        }
    }

    @media screen and (max-width: 400px) {
        .article-content {
            .article-title {
                @include font-size(14);
                @include line-height(14);
            }
        }
        &.on-home {
            .article-content {
                .article-title {
                    @include font-size(14);
                    @include line-height(14);
                }
            }
        }
    }
}
