.notifications-card {
    @extend .dashboard-card;
    margin-top: $base-grid-padding;
    max-height: 520px;
    height: 100%;
    overflow: hidden;

    .title-container {
        .circle-button {
            width: 34px;
            height: 34px;

            .icon {
                width: 16px;
                height: 16px;
            }
        }
    }

    .content {
        overflow: auto;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 10px;
        @include customScrollbarOnWhite;

        @import "../notification-card.scss";

        &.center-content {
            display: flex;
            gap: unset;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .empty-notifications {
            margin: $base-padding-half 0;
            .notification-title {
                @include font-size(18);
                @include line-height(18);
                margin: 0;
                text-align: center;
                text-transform: uppercase;
            }
            .notification-subtitle {
                @include font-size(14);
                @include line-height(16);
                margin: 0;
                text-align: center;
            }
        }
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        max-height: none;
        .content {
            .notification-card:nth-child(1n) {
                display: none;
            }

            .notification-card {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    display: flex !important;
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        margin-top: $base-padding-half;
    }

    @media screen and (max-width: $mobile-breakpoint) {
    }
}
