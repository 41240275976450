.event-landing-body-wide-2 {
    margin-bottom: $base-padding;

    .event-landing-bottom-header {
        @include font-size(40);
        @include line-height(40);
        @include headerFont;
        text-align: center;
        text-transform: uppercase;
        font-weight: $weight-black;
        margin: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(30);
            @include line-height(30);
        }
    }

    .event-landing-bottom-description {
        @include font-size(18);
        font-family: $base-font-family;
        text-align: center;
        font-weight: $weight-bold;
        margin-top: 20px;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }
}
