.inbox-container {
    display: flex;
    flex-direction: column;
    flex: 500;

    .header-container {
        display: flex;
        height: 60px;
        align-items: center;
        border-bottom: $border-thickness solid $off-white;
        padding: 10px $base-padding-half;

        .title {
            @include headerFont;
            @include font-size(24);
            @include line-height(24);
            margin: 0;
        }
        .icon {
            fill: $base-dark-tone;
        }
    }

    .content {
        min-height: calc(100% - 60px);
        max-height: calc(100% - 60px);
        overflow: auto;
        @include customScrollbarOnWhite;

        .admin-conversation-container {
            padding: 10px;
            border-bottom: $border-thickness solid $off-white;
            @import "./conversation-card.scss";
        }

        .conversation-grid {
            padding: 10px;
            position: relative;
            display: grid;
            gap: 10px;
            @import "./conversation-card.scss";
        }

        .no-conversation-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 0;

            .title {
                @include font-size(22);
                @include line-height(22);
                margin: 0;
                text-transform: uppercase;
                text-align: center;
            }
            .subtitle {
                @include font-size(14);
                @include line-height(14);
                margin: 0;
                margin-top: 5px;
                text-align: center;
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        .header-container {
            padding: 10px 15px;
        }
    }
}
