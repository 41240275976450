.product-faq {
    display: flex;
    flex-direction: column;
    padding: $base-vertical-padding 0;
    margin: 0;

    @import './accordion.scss';

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding 0;
    }

    .title {
        @include headerFont;
        @include font-size(60);
        @include line-height(60);
        margin: 0;
        color: $base-dark-tone;
        position: relative;
        text-align: center;

        @media screen and (max-width: $medium-breakpoint) {
            @include font-size(40);
            @include line-height(40);
        }

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(30);
            @include line-height(30);
        }
    }
}
