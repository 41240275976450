@use "sass:math";

@mixin button {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 0;
    outline: 0;
    text-align: center;
    font-family: $base-font-family;
    font-weight: $weight-bold;
    text-transform: uppercase;
    border-radius: $border-radius;
    padding: 0;
    cursor: pointer;

    @include transition;

    &:disabled,
    &[disabled] {
        background-color: $disabled-button;
        box-shadow: none;
        cursor: not-allowed;

        .icon {
            fill: $base-text-on-black;
        }

        &:hover {
            background-color: $disabled-button;
        }
    }
}

@mixin box-shadow($colour, $blur, $shiftDown, $shiftRight, $spread) {
    -webkit-box-shadow: $shiftRight $shiftDown $blur $spread $colour;
    box-shadow: $shiftRight $shiftDown $blur $spread $colour;
}

@mixin colored-button($backgroundColor, $color, $hoverBackgroundColor, $hoverColor, $shadowColour) {
    @include button;
    background-color: $backgroundColor;
    color: $color;

    @include box-shadow($shadowColour, 8px, 2px, 0px, 0px);

    &:hover,
    &:focus {
        background-color: $hoverBackgroundColor;
        color: $hoverColor;
    }
}

@mixin outline-button() {
    @include button;
    background-color: $off-white;
    color: $base-dark-tone;
    border: $border-thickness solid $base-dark-tone;

    @include box-shadow($black-shadow, 8px, 2px, 0px, 0px);

    &:hover,
    &:focus {
        background-color: $base-dark-tone;
        color: $white;
    }
}

@mixin blue-button {
    @include colored-button($blue, $white, $blue-hover, $white, $blue-shadow);
}

@mixin light-blue-button {
    @include colored-button($light-blue, $base-dark-tone, $light-blue, $base-dark-tone, $white);
}

@mixin coke-red-button {
    @include colored-button($coke-red, $white, $red-hover, $white, $red-shadow);
}

@mixin dreamseat-blue-button {
    @include colored-button($dreamseat-blue, $white, $blue-hover, $white, $blue-shadow);
}

@mixin f1-red-button {
    @include colored-button($f1-red, $white, $red-hover, $white, $red-shadow);
}

@mixin brightspeed-orange-button {
    @include colored-button($brightspeed-orange, $white, $orange-hover, $white, $orange-shadow);
}

@mixin red-button {
    @include colored-button($red, $white, $red-hover, $white, $red-shadow);
}

@mixin green-button {
    @include colored-button($green, $white, $green-hover, $white, $green-shadow);
}

@mixin grey-button {
    @include colored-button($darker-off-white, $black, $off-black-hover, $white, $black-shadow);
}

@mixin purple-button {
    @include colored-button($purple, $white, $purple-hover, $white, $purple-shadow);
}

@mixin black-button {
    @include colored-button($base-dark-tone, $white, $base-dark-tone-hover, $white, $black-shadow);
}

@mixin current-button {
    background-color: transparent;
    color: $blue;
    border: 2px solid $blue;
}

@mixin clampLine() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin clampLines($numberOfLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $numberOfLines;
    line-clamp: $numberOfLines;
    display: -webkit-box;
}

@mixin container {
    max-width: $full-site-width;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: $xlarge-breakpoint) {
        padding-left: $base-padding;
        padding-right: $base-padding;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-left: $base-padding-half;
        padding-right: $base-padding-half;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        padding-left: $mobile-base-padding;
        padding-right: $mobile-base-padding;
    }
}

@mixin linearGradient($firstColour, $firstPercent, $secondColour, $secondPercent, $angle) {
    background: linear-gradient($angle, $firstColour $firstPercent, $secondColour $secondPercent);
}

@mixin colourToTransparentGradient($background-color) {
    @include linearGradient($background-color, 10%, transparent, 100%, 0deg);
}

@mixin blackTransparentGradient() {
    @include linearGradient(black, 0%, transparent, 100%, 0deg);
}

@mixin noHighlight {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

@mixin headerFont {
    font-family: $header-font-family;
    letter-spacing: 1px;
}

@mixin defaultContainerPadding {
    padding-top: $base-vertical-padding;
    padding-bottom: $base-vertical-padding;
    @media screen and (max-width: $large-breakpoint) {
        padding-top: $large-vertical-padding;
        padding-bottom: $large-vertical-padding;
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding-top: $medium-vertical-padding;
        padding-bottom: $medium-vertical-padding;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-top: $small-vertical-padding;
        padding-bottom: $small-vertical-padding;
    }
}

@mixin font-size($size) {
    font-size: math.div($size, $base-font-size) + rem;
}

@mixin line-height($size) {
    line-height: math.div($size, $base-font-size) + rem;
}

@mixin customScrollbarOnWhite {
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: $white;
    }

    ::-webkit-scrollbar-corner {
        background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
        width: 8px;
        background: $scrollbar-thumb;
        border: $border-thickness solid $white;
        border-radius: $border-radius;
    }
}

@mixin customScrollbarOnGray {
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: $off-white;
    }

    ::-webkit-scrollbar-corner {
        background-color: $off-white;
    }

    &::-webkit-scrollbar-thumb {
        width: 8px;
        background: $scrollbar-thumb;
        border: $border-thickness solid $off-white;
        border-radius: $border-radius;
    }
}

@mixin light-card {
    border-radius: $border-radius;
    background-color: $white;
    padding: $base-grid-padding;
    @include baseShadowOnWhite;
}

@mixin dark-card {
    border-radius: $border-radius;
    padding: $base-grid-padding;
    background-color: $base-dark-tone;
    @include baseShadowOnBlack;
}

@mixin baseShadowOnWhiteReversed {
    @include box-shadow($black-on-white-shadow, 10px, -5px, 0px, 0px);
}

@mixin baseShadowOnWhite {
    @include box-shadow($black-on-white-shadow, 10px, 5px, 0px, 0px);
}

@mixin baseShadowOnBlack {
    @include box-shadow($black-on-white-shadow, 10px, 5px, 0px, 0px);
}

@mixin image-wrapper($width, $height, $border-radius, $shadow-blur: 8px, $shadow-distance: 2px) {
    width: $width;
    height: $height;
    border-radius: $border-radius;
    z-index: 1;

    @include box-shadow($base-shadow, $shadow-blur, $shadow-distance, 0px, 0px);

    img {
        border: $border-thickness solid $off-white !important;
        border-radius: $border-radius;
    }

    .default-picture {
        border-radius: $border-radius;
        border-color: $off-white;
        padding: 18%;

        .spin-logo {
            width: 100%;
            height: 100%;
        }
    }
}

@mixin table {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 800px;

    .left-aligned {
        text-align: left;
    }

    .center-aligned {
        text-align: center;
    }

    .right-aligned {
        text-align: right;
    }

    .bolded {
        font-weight: $weight-bold;
    }

    .table-head {
        background-color: $base-dark-tone;
        .table-heading {
            padding: 18.5px 5px;
            border-bottom: $border-thickness solid $base-dark-tone;
            @include font-size(18);
            text-transform: uppercase;
            font-weight: $weight-black;
            color: $white;

            &:first-child {
                border-left: $border-thickness solid $base-dark-tone;
                border-top-left-radius: $border-radius;
            }
            &:last-child {
                border-right: $border-thickness solid $base-dark-tone;
                border-top-right-radius: $border-radius;
            }
        }
    }

    .table-row {
        &:nth-child(odd) {
            background: $off-white;
        }

        .table-data {
            padding: 14.5px 5px;
            border-bottom: $border-thickness solid $off-white;
            @include font-size(16);

            &:first-child {
                border-left: $border-thickness solid $off-white;
            }

            &:last-child {
                border-right: $border-thickness solid $off-white;
            }
        }

        &:last-child {
            .table-data {
                &:first-child {
                    border-bottom-left-radius: $border-radius;
                }
            }
        }

        &:last-child {
            .table-data {
                &:last-child {
                    border-bottom-right-radius: $border-radius;
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .table-head {
            .table-heading {
                padding: 14.5px 5px;
                @include font-size(16);
            }
        }

        .table-row {
            .table-data {
                padding: 11px 5px;
                border-bottom: $border-thickness solid $off-white;
                @include font-size(14);
            }
        }
    }
}

@mixin gridColumns($columns) {
    grid-template-columns: repeat($columns, minmax(0, 1fr));
}

