.scouting-report-card {
    @include light-card;
    display: flex;
    flex-direction: column;

    .report-info-container {
        display: flex;

        .image-wrapper {
            @include image-wrapper(80px, 80px, $border-radius);
            flex-shrink: 0;
        }

        .report-info {
            display: flex;
            flex-direction: column;
            align-self: center;
            margin-left: 10px;

            .report-name {
                @include font-size(20);
                @include line-height(20);
                margin: 0;
                text-transform: uppercase;
            }

            .report-date {
                @include font-size(14);
                @include line-height(14);
                margin: 0;
                color: $base-text-on-white;
                margin-top: 3px;
            }

            .report-stream-link {
                @include font-size(14);
                @include line-height(14);
                margin: 0;
                margin-top: 3px;
                color: $blue;
                text-decoration: none;

                &:hover {
                    color: $blue-hover;
                    text-decoration: underline;
                }
            }
        }
    }

    .bottom-container {
        margin-top: 10px;
        display: flex;

        .game-info-container {
            display: flex;
            flex-wrap: wrap;
            padding-right: $base-padding-half;

            .icon-container {
                margin-right: 10px;
                margin-top: 10px;
            }
        }

        .button {
            margin-top: 10px;
            width: 180px;
            margin-left: auto;
            align-self: flex-end;
            flex-shrink: 0;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .report-info-container {
            flex-direction: column;
            align-items: center;

            .image-wrapper {
                margin: 0 auto;
            }

            .report-info {
                margin: 0;
                margin-top: 10px;
                align-items: center;
                .report-date,
                .report-stream-link {
                    @include font-size(12);
                    @include line-height(12);
                    text-align: center;
                }

                .report-name {
                    @include font-size(18);
                    @include line-height(18);
                    text-align: center;
                }
            }
        }

        .bottom-container {
            flex-direction: column;
            align-items: center;

            .game-info-container {
                padding: 0;
                .icon-container {
                    margin: 5px;
                }
            }

            .button {
                margin: 0 auto;
                margin-top: 10px;
            }
        }
    }
}
