.icon-container {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: $base-dark-tone;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        width: 18px;
        height: 18px;
        fill: $white;
    }

    &.college {
        background-color: $white;

        .icon {
            fill: $base-dark-tone;
        }
    }

    &.rocket_league {
        // background-color: $rocket-league-colour;

        .icon {
            width: 20px;
            height: 20px;
        }
    }
    // &.rainbow_six_siege {
    //     // background-color: $rainbow-six-siege-colour;
    // }
    // &.valorant {
    //     // background-color: $valorant-colour;
    // }
    // &.league_of_legends {
    //     // background-color: $league-of-legends-colour;
    // }
    // &.nba_2k22 {
    //     // background-color: $nba-2k22-colour;
    // }
    // &.apex_legends {
    //     // background-color: $apex-legends-colour;
    // }
    // &.brawlhalla {
    //     // background-color: $brawlhalla-colour;
    // }
    // &.chess {
    //     // background-color: $chess-colour;
    // }
    // &.call_of_duty {
    //     // background-color: $call-of-duty-colour;
    // }
    // &.cod_warzone {
    //     // background-color: $cod-warzone-colour;
    // }
    // &.clash_royale {
    //     // background-color: $clash-royale-colour;
    // }
    // &.csgo {
    //     // background-color: $csgo-colour;
    // }
    // &.dota_2 {
    //     // background-color: $dota-2-colour;
    // }
    // &.fifa_22 {
    //     // background-color: $fifa-22-colour;
    // }
    // &.fortnite {
    //     // background-color: $fortnite-colour;
    // }
    // &.forza {
    //     // background-color: $forza-colour;
    // }
    // &.halo_infinite {
    //     // background-color: $halo-infinite-colour;
    // }
    // &.hearthstone {
    //     // background-color: $hearthstone-colour;
    // }
    // &.iracing {
    //     // background-color: $iracing-colour;
    // }
    // &.legends_of_runeterra {
    //     // background-color: $legends-of-runeterra-colour;
    // }
    // &.madden_22 {
    //     // background-color: $madden-22-colour;
    // }
    // &.magic_the_gathering {
    //     // background-color: $magic-the-gathering-colour;
    // }
    // &.nhl_22 {
    //     // background-color: $nhl-22-colour;
    // }
    // &.overwatch {
    //     // background-color: $overwatch-colour;
    // }
    // &.side_swipe {
    //     // background-color: $side-swipe-colour;
    // }
    // &.super_smash_bros_melee {
    //     // background-color: $super-smash-bros-melee-colour;
    // }
    // &.super_smash_bros_ultimate {
    //     // background-color: $super-smash-bros-ultimate-colour;
    // }
    // &.smite {
    //     // background-color: $smite-colour;
    // }
    // &.street_fighter {
    //     // background-color: $street-fighter-colour;
    // }
    // &.tekken {
    //     // background-color: $tekken-colour;
    // }
    // &.teamfight_tactics {
    //     // background-color: $teamfight-tactics-colour;
    // }
    // &.league_of_legends_wild_rift {
    //     // background-color: $wild-rift-colour;
    // }
    // &.world_of_warcraft {
    //     // background-color: $world-of-warcraft-colour;
    // }
    // &.rogue_company {
    //     // background-color: $rogue-company-colour;
    // }
    // &.pokemon_unite {
    //     // background-color: $pokemon-unite-colour;
    // }
}
