.parent-explore-dashboard-card {
    display: flex;
    flex-direction: column;
    background-color: $black;
    border-radius: $border-radius;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $base-padding;
    background-image: url("/images/whats-next/parent-explore-dashboard-card.jpg");

    .title {
        color: $white;
        @include font-size(24);
        @include line-height(28);
        margin: 0;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: $weight-black;
    }

    .description {
        max-width: 620px;
        text-align: center;
        color: $base-text-on-dark-tone;
        @include font-size(14);
        @include line-height(18);
        margin: 0 auto;
        margin-bottom: 16px;
    }

    .button {
        margin-top: auto;
        @include font-size(16);
        width: 250px;
        height: 50px;
        border-radius: 25px;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        .title {
            @include font-size(18);
            @include line-height(24);
        }

        .description {
            @include font-size(12);
            @include line-height(16);
        }

        .button {
            @include font-size(14);
            width: 200px;
            height: 40px;
            border-radius: 20px;
        }
    }
}
