.event-card-header {
    width: 100%;
    height: 170px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    padding-top: 30px;
    justify-content: center;

    .overlay {
        @include colourToTransparentGradient($white);
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
    }

    .event-icon {
        @include image-wrapper(120px, 120px, $border-radius);
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-top: 20px;
        height: 140px;
    }
}
