.section-header {
    display: flex;
    align-items: center;
    margin-bottom: $base-padding;

    .section-title {
        @include font-size(42);
        @include line-height(42);
        margin: 0;
        margin-right: $base-padding;
        @include headerFont;
    }

    .section-cta {
        display: flex;
        align-items: center;
        @include font-size(16);
        @include line-height(16);
        font-weight: $weight-black;
        text-transform: uppercase;
        text-decoration: none;
        color: $blue;
        @include transition;

        svg {
            margin-left: 10px;
            @include transition;
        }

        &:hover {
            color: $blue-hover;

            svg {
                transform: translateX(5px);
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        flex-direction: column;
        .section-title {
            width: 100%;
            margin-right: 0;
            @include font-size(32);
            @include line-height(32);
        }
        .section-cta {
            width: 100%;
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        margin-bottom: $base-padding-half;
        .section-title {
            @include font-size(28);
            @include line-height(28);
        }
    }
}
