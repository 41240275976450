.academic-interests {
    @include light-card;
    display: flex;
    flex-direction: column;
    padding: $base-padding-half;
    box-shadow: none;
    gap: 10px;

    &.disabled {
        opacity: 0.4;
    }

    &.outlined {
        border: $border-thickness solid $off-white;
    }

    .top-container {
        display: flex;
        position: relative;
        margin-bottom: 10px;

        .title-container {
            display: flex;
            align-items: center;
            position: relative;
            flex-shrink: 0;
            .tag-select-label {
                margin: 0;
                margin-left: 20px;
                @include font-size(14);
                @include line-height(14);
                z-index: 1;
                flex-shrink: 0;
            }
            .required {
                z-index: 1;
                color: $blue;
                @include font-size(24);
                font-weight: $weight-bold;
                position: absolute;
                left: 0px;
                top: -3px;
            }
        }

        .warning-label {
            @include font-size(12);
            color: $base-text-on-white;
            margin: 0;
            margin-left: 15px;
        }

        @media screen and (max-width: $small-breakpoint) {
            flex-direction: column;
            .warning-label {
                margin: 0;
                margin-top: 10px;
            }
        }
    }

    .field-subject-selection-container {
        width: 100%;
        gap: 10px;
        display: flex;

        .select-field-container {
            width: 100%;
            .select-container {
                width: 100%;
                .select__control {
                    border: $border-thickness solid $off-white;

                    &.select__control--is-focused,
                    &:active,
                    &:focus {
                        border-color: $blue;
                    }
                }

                &.error {
                    .select__control {
                        border-color: $red;
                        background-color: $light-red;
                    }

                    .select__menu {
                        border-color: $red;
                        background-color: $light-red;
                    }
                }
            }
        }

        .checkmark-button {
            @include blue-button;
            margin: 2px 0;
            width: 46px;
            height: 46px;
            flex-shrink: 0;
            border-radius: 100%;
            box-shadow: none;

            .icon {
                fill: $white;
            }

            @media screen and (max-width: $small-breakpoint) {
                width: 36px;
                height: 36px;
                .icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            flex-direction: column;
            .checkmark-button {
                width: 80px;
                margin: 0 auto;
                border-radius: 18px;
            }
        }
    }

    .interests {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}
