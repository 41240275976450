.partner-card {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(1, 1fr);
    border: $border-thickness solid $off-white;
    border-radius: $border-radius;
    overflow: hidden;

    .background-wrapper {
        display: flex;
        position: relative;
        background-color: $base-dark-tone;
        border-right: 1px solid $grey;
        padding: 10px;

        &.needs-padding {
            padding: 10px;
            border-radius: $border-radius;
        }

        .background-image {
            @include transition;
            display: flex;
            width: 100%;
            justify-self: center;
            align-self: center;

            // padding: 10px;
            // position: relative;
            // width: 100%;
            // height: 100%;
        }
    }

    .partner-logo-icon {
        display: flex;
        background-color: $white;
        justify-content: center;
        align-items: center;
        border-right: $border-thickness solid $off-white;
        padding: $base-padding;

        &.dark {
            background-color: $base-dark-tone;
            border-right: none;
        }

        .coke-icon {
            fill: #f40009;
            height: auto;
            width: 100%;
        }

        .icon {
            width: 100%;
            height: auto;
        }

        @import '../icons/partner-icons.scss';
    }

    .partner-image {
        display: flex;
        padding: 20px;
        background-color: $base-dark-tone;

        .background-wrapper {
            position: relative;
            border: none;

            .background-image {
                @include transition;
                background-color: transparent;

                img {
                    border-radius: 0px;
                }
            }
        }
    }

    .info-box {
        display: flex;
        flex-direction: column;
        padding: $base-padding;
        align-self: center;
        background-color: $white;
        color: $base-dark-tone;

        .title {
            @include headerFont;
            @include font-size(40);
            @include line-height(40);
            margin: 0;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(35);
                @include line-height(35);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(30);
                @include line-height(30);
            }
        }

        .description {
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            margin-top: 10px;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(14);
                @include line-height(20);
            }
        }

        .view-page {
            @include transition;
            color: $blue;
            text-decoration: none;
            margin-top: $base-padding-half;

            &:hover {
                color: $base-dark-tone;
            }
        }

        .button {
            margin-top: $base-padding-half;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        grid-template-columns: none;
        .partner-logo-icon {
            padding: $base-padding-half $base-padding-half;
            border-bottom: $border-thickness solid $off-white;
            border-right: none;

            &.dark {
                border-bottom: none;
            }

            .icon {
                width: 75%;
                height: auto;
                max-height: 100px;
            }

            .coke-icon {
                fill: #f40009;
                height: auto;
                width: 50%;
            }
        }

        .background-wrapper {
            display: flex;
            .background-image {
                display: block;
                margin: 0 auto;
                width: auto;
            }
        }
    }
}
