.player-my-teams-container {
    display: flex;
    flex-direction: column;
    gap: $base-grid-padding;
    padding: $base-grid-padding;

    .team-invite-list {
        display: flex;
        flex-direction: column;
        gap: $base-grid-padding;

        .team-invites-container {
            display: grid;
            @include gridColumns(2);
            gap: $base-grid-padding;

            .team-invite-card {
                display: flex;
                gap: $base-padding-half;
                align-items: center;
                background-color: $white;
                border-radius: $border-radius;
                padding: $base-padding-half;

                .team-info {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    flex-shrink: 0;

                    .team-name {
                        font-family: $header-font-family;
                        @include font-size(24);
                        @include line-height(28);
                        margin: 0;
                    }
    
                    .game {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                    }
                }

                .medium-button {
                    margin-left: auto;
                    max-width: 240px;
                }

                @media screen and (max-width: $small-breakpoint) {
                    align-items: flex-start;
                    flex-direction: column;

                    .medium-button {
                        margin-left: unset;
                        width: 100%;
                        max-width: unset;
                    }
                }
            }

            @media screen and (max-width: $xmedium-breakpoint) {
                @include gridColumns(1);
            }
        }
    }



    .my-teams-list {
        display: flex;
        flex-direction: column;
        gap: $base-grid-padding;

        .my-teams-container {
            display: flex;
            flex-direction: column;
            gap: $base-grid-padding;

            .player-team-card {
                display: flex;
                flex-direction: column;
                gap: $base-padding-half;

                @import "../../high-schools/profile/high-school-our-team-card.scss";

                .team-controls-container {
                    width: 100%;
                    display: flex;
                    gap: $base-padding-half;

                    .medium-button {
                        max-width: 240px;
                        width: 100%;
                    }

                    @media screen and (max-width: $small-breakpoint) {
                        flex-direction: column;
                        
                        .medium-button {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        gap: $base-padding-half;

        .my-teams-container {
            gap: $base-padding-half;
        }
    }
}
