.journey-dashboard-card {
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    flex: 1000;
    @include baseShadowOnWhite;

    .title {
        @include headerFont;
        @include font-size(52);
        @include line-height(52);
        margin: 0;
    }

    .video {
        margin-top: $base-padding-half;
        position: relative;
        padding-bottom: 56.25%;
        .video-player {
            border-radius: $border-radius;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        align-self: flex-start;
    }

    @media screen and (max-width: $medium-breakpoint) {
        .title {
            @include font-size(48);
            @include line-height(48);
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        .title {
            @include font-size(32);
            @include line-height(32);
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        .title {
            @include font-size(28);
            @include line-height(28);
        }
    }
}
