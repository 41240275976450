.game-options {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .button {
        margin-right: 10px;
        @include transition;

        .icon-container {
            flex-shrink: 0;
            background-color: $base-dark-tone;
            @include transition;

            .icon {
                fill: $white;
            }

            &.selected {
                background-color: $purple;
            }
        }

        &:hover {
            .icon-container {
                background-color: $purple;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .icon-container {
            width: 30px;
            height: 30px;

            .icon {
                width: 16px;
                height: 16px;
            }
        }
        .rank {
            @include font-size(12);
            @include line-height(12);
        }
    }
}
