.profile-incomplete-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    background-color: $lighter-red;
    border: $border-thickness solid $red;
    padding: $base-padding-half;
    @include baseShadowOnWhite;

    .title-container {
        display: flex;
        flex-direction: column;
    }

    .title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
    }

    .subtitle {
        @include font-size(14);
        @include line-height(14);
        margin: 0;
        margin-top: 4px;
    }

    .button {
        width: 100%;
        max-width: 180px;
        margin-top: $base-padding-half;
    }

    .close-button {
        @include button;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-left: auto;
        background-color: transparent;

        .icon {
            fill: $base-dark-tone;
        }

        &:hover {
            background-color: $light-red;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: 15px;

        .title {
            @include font-size(24);
            @include line-height(24);
        }

        .subtitle {
            @include font-size(12);
            @include line-height(12);
        }
    }
}

.fixed-profile-incomplete {
    @extend .profile-incomplete-container;
    z-index: 100000;
    position: fixed;
    bottom: 0;
    border-bottom: 0;
    width: 100%;
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    justify-content: center;
    flex-direction: column;
    .title {
        text-align: center;
        padding: 0 40px;
    }

    .subtitle {
        text-align: center;
        padding: 0 40px;
    }
    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
