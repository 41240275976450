.account-dropdown {
    display: flex;
    width: 250px;
    background-color: $white;
    border-radius: $border-radius;
    border: $border-thickness solid $off-white;
    height: 60px;
    margin-left: auto;
    align-items: center;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
    @include transition;

    &:hover {
        border-color: $blue;
    }

    &.active {
        border-color: $blue;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .arrow {
            transform: rotate(270deg);
        }
    }

    .loading-icon {
        margin: 0 auto;
    }

    .profile-picture {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        z-index: 1;
        position: relative;
        flex-shrink: 0;

        img {
            border: $border-thickness solid $white !important;
            border-radius: 100%;
        }
    }

    .username {
        margin: 0;
        @include font-size(14);
        @include line-height(14);
        font-weight: $weight-black;
        margin: 0 10px;
        text-transform: uppercase;
        max-width: calc(250px - 40px - 20px - 12px - 20px);
        @include clampLine;

        &.college-name {
            @include clampLines(2);
            white-space: normal;
        }
    }

    .arrow {
        @include transition;
        width: 12px;
        height: 12px;
        margin-left: auto;
        flex-shrink: 0;
        margin-right: 10px;
        transform: rotate(90deg);
    }

    .links {
        z-index: 4;
        position: absolute;
        width: calc(100% + 4px);
        min-width: 200px;
        right: -2px;
        top: 56px;
        display: flex;
        flex-direction: column;
        background-color: $white;
        border: $border-thickness solid $blue;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        padding: 6px $base-padding-half;
        @include fade-in-and-move-fast;
        @include baseShadowOnWhite;

        .link {
            text-transform: uppercase;
            text-decoration: none;
            color: $base-dark-tone;
            @include font-size(14);
            @include line-height(32);
            @include transition;
            font-weight: $weight-black;

            &:hover {
                color: $blue;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        width: 60px;
        padding: 0;
        justify-content: center;
        position: absolute;
        right: $base-padding-half;

        .links {
            border-top-left-radius: $border-radius;
        }

        .username {
            &.college-name {
                display: none !important;
            }
            display: none;
        }
        .arrow {
            display: none;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        padding: 0;
        justify-content: center;
        position: absolute;
        right: $base-padding-half;
        border: 0;

        &.active {
            .profile-picture {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                img {
                    border-color: $blue !important;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .default-picture {
                    border-color: $blue;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        .links {
            top: 38px;
            right: 0px;
        }
    }
}
