.product-testimonials {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $base-grid-padding;
    margin-top: $base-padding;

    .testimonial-card {
        position: relative;
        background-color: $white;
        border-radius: $border-radius;
        padding: $base-grid-padding;
        @include baseShadowOnWhite;

        .open-quote {
            width: 40px;
            height: 40px;
            background-color: $green;
            border-radius: $border-radius;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 20px;
                height: 20px;
                fill: $white;
            }
        }

        .closed-quote {
            width: 100px;
            height: 100px;
            position: absolute;
            right: $base-padding-half;
            bottom: 20px;

            .icon {
                width: 80%;
                height: 80%;
                fill: $off-white;
            }
        }

        .quote {
            position: relative;
            color: $base-text-on-white;
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            margin-top: $base-padding-half;
            z-index: 2;
        }

        .name {
            position: relative;
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            margin-top: $base-padding-half;
            font-weight: $weight-black;
            text-transform: uppercase;
            z-index: 2;
        }

        .twitter {
            position: relative;
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            margin-top: 3px;
            font-weight: $weight-bold;
            text-transform: uppercase;
            z-index: 2;
        }

        .subtitle {
            position: relative;
            color: $base-text-on-white;
            @include font-size(14);
            @include line-height(18);
            margin: 0;
            margin-top: 5px;
            z-index: 2;
        }

        @media screen and (max-width: $medium-breakpoint) {
            width: 90%;
        }

        @media screen and (max-width: $small-breakpoint) {
            .closed-quote {
                top: auto;
                bottom: $base-grid-padding;
            }
            .quote {
                @include font-size(14);
                @include line-height(18);
                margin-top: 15px;
            }

            .name {
                @include font-size(16);
                @include line-height(16);
                margin-top: 15px;
            }

            .twitter {
                @include font-size(14);
                @include line-height(14);
            }

            .subtitle {
                @include font-size(12);
                @include line-height(14);
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            padding: $base-padding-half;
            // min-width: none;
            // max-width: 100%;
            width: 100%;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        justify-items: center;
    }
}
