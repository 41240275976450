.editable-game {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    position: relative;

    .header-container {
        display: flex;
        padding: 15px $base-padding-half;
        border-bottom: $border-thickness solid $off-white;
        align-items: center;

        .icon-container {
            width: 30px;
            height: 30px;
            margin-right: 5px;
            flex-shrink: 0;

            .icon {
                width: 14px;
                height: 14px;
            }
        }

        .game-name {
            @include headerFont;
            @include font-size(24);
            @include line-height(24);
            margin: 0;
            margin-right: 10px;
            @include clampLine;
        }

        .controls {
            display: flex;
            margin-left: auto;

            .primary-game {
                align-items: center;
                justify-content: center;
                display: flex;
                height: 30px;
                border-radius: 15px;
                border: $border-thickness solid $blue;
                background-color: $light-blue;
                @include font-size(12);
                @include line-height(10);
                text-align: center;
                text-transform: uppercase;
                font-weight: $weight-bold;
                padding: 0 15px;
            }

            .circle-button {
                width: 30px;
                height: 30px;
                margin-left: 10px;
                flex-shrink: 0;

                .icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .content {
        padding: $base-padding-half;
        display: flex;

        .rank-role-container {
            display: grid;
            gap: $base-padding-half;
            .rank-role-value {
                .title {
                    @include font-size(14);
                    @include line-height(14);
                    margin: 0;
                    text-transform: uppercase;
                    color: $base-text-on-white;
                }
                .value {
                    @include font-size(18);
                    @include line-height(18);
                    margin: 0;
                    margin-top: 3px;
                    text-transform: uppercase;
                    color: $blue;
                    font-weight: $weight-black;
                }
            }
        }

        .tracker {
            width: auto;
            margin-left: auto;
            align-self: flex-end;
            padding: 0 20px;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .header-container {
            flex-wrap: wrap;

            .game-name {
                width: calc(100% - 35px);
                @include font-size(22);
                @include line-height(22);
                padding-right: 40px;
                margin: 0;
            }

            .controls {
                width: 100%;
                margin: 0;
                .edit {
                    margin-left: 0;
                    position: absolute;
                    right: $base-padding-half;
                    top: 15px;
                }
                .delete {
                    margin-left: 0;
                    position: absolute;
                    right: $base-padding-half;
                    top: 55px;
                }

                .primary-game {
                    margin-top: 10px;
                }
            }
        }

        .content {
            flex-direction: column;

            .tracker {
                align-self: flex-start;
                margin: 0;
                margin-top: $base-padding-half;
            }
        }
    }
}
