.my-games {
    margin: $base-grid-padding;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $base-grid-padding;
    @import "./editable-game.scss";
    @import "./add-game-card.scss";

    @media screen and (max-width: $xmedium-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media screen and (max-width: $small-breakpoint) {
        gap: $base-padding-half;
        margin: $base-padding-half;
    }

    &.colleges {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
