.toggle-button {
    @include button;
    height: 34px;
    border-radius: 17px;
    border: $border-thickness solid $off-white;
    background-color: transparent;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 15px;
    font-weight: $weight-regular;
    text-transform: none;
    @include font-size(14);

    &.active {
        border-color: $base-dark-tone;
        background-color: $base-dark-tone;
        color: $white;
    }

    @media screen and (max-width: $small-breakpoint) {
        height: 30px;
        margin-right: 5px;
        margin-bottom: 5px;
        @include font-size(12);
        padding: 0 10px;
    }
}
