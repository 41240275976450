& {
    @media screen and (max-width: $small-breakpoint) {
        padding: 0 $base-padding-half;
        .brand {
            margin-left: auto;
            margin-right: auto;
        }

        .mobile-nav-button {
            margin-right: 0px;
            position: absolute;
            left: 20px;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        background-color: $white;
        @include baseShadowOnWhite;
        .brand {
            .spin-logo {
                display: none;
                &.mobile {
                    display: flex;
                    height: 60px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .brand {
            .spin-logo {
                &.mobile {
                    height: 50px;
                }
            }
        }
    }
}
