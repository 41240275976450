.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    @import "./college-verification-container.scss";

    .dashboard-container {
        &.has-verification-message {
            height: calc(100vh - 40px);
        }
        display: flex;
        width: 100%;
        height: 100vh;
        @import "../navigation/dashboard-navigation.scss";
        @import "../navigation/mobile-dashboard-navigation.scss";

        .mobile-dashboard-header {
            flex-shrink: 0;
            display: none;
            position: fixed;
            top: 0;
            width: 100%;
            height: $mobile-navigation-height;
            background-color: $base-dark-tone;
            align-items: center;
            padding: 0 $base-padding-half;
            z-index: 4;

            .brand {
                margin: 0 auto;
                .spin-logo {
                    fill: $white;
                    width: 50px;
                    height: 50px;
                }
            }

            .mobile-navigation-button {
                @include button;
                background-color: transparent;
                width: 40px;
                height: 40px;
                position: absolute;
                left: $base-padding-half;

                .icon {
                    fill: $white;
                }
            }

            .account-dropdown {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                padding: 0;
                justify-content: center;
                position: absolute;
                right: $base-padding-half;
                border: 0;
                background-color: transparent;

                .profile-picture {
                    img {
                        border: 0 !important;
                        border: $border-thickness solid transparent !important;
                        @include transition;
                    }
                }

                &.active {
                    background-color: $blue;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    .profile-picture {
                        .default-picture {
                            border-color: $blue;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                }

                .links {
                    border-top-left-radius: $border-radius;
                    top: 38px;
                    right: 0px;
                }
            }
        }

        .dashboard-content-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .dashboard-content-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: $white;
                padding: 0 $base-grid-padding;
                flex-shrink: 0;
                z-index: 3;
                height: $navigation-height;
                @include baseShadowOnWhite;

                .title-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title {
                        @include headerFont;
                        @include font-size(42);
                        @include line-height(42);
                        margin: 0;
                    }

                    .subtitle {
                        @include font-size(14);
                        @include line-height(14);
                        margin: 0;
                        margin-top: 5px;
                    }
                }

                @import "./messages/compose-group-message.scss";
            }

            .dashboard-content {
                height: 100%;
                width: 100%;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                @include customScrollbarOnGray;

                @import "./dashboard-card.scss";
                @import "./statistic-container.scss";
                @import "./unsubbed-container.scss";
                @import "./social-user-card.scss";
                @import "./player-information-form.scss";
                @import "./parent-information-form.scss";
                @import "./college-information-form.scss";
                @import "./my-events/my-events.scss";
                @import "./my-games/my-games.scss";
                @import "./my-vods/my-vods.scss";
                @import "./hub/hub.scss";
                @import "./my-profile/player-my-profile.scss";
                @import "./my-profile/college-my-profile.scss";
                @import "./my-profile/high-school-my-profile.scss";
                // @import './my-security/my-security.scss';
                @import "./parents/my-players-games.scss";
                @import "./parents/my-players-profiles.scss";
                @import "./parents/my-players-events.scss";
                @import "./parents/parent-subscriptions.scss";
                @import "./subscriptions/subscriptions.scss";
                @import "./scouting-reports/scouting-reports.scss";
                @import "./scouting-reports/scouting-report-search-card.scss";
                @import "./scouting-reports/scouting-report-player-cards.scss";
                @import "./following/following.scss";
                @import "./profile-views/profile-views.scss";
                @import "./account-settings/account-settings-card.scss";
                @import "./messages/messages-card.scss";
                @import "./subscriptions/college-subscribed-whats-next.scss";
                @import "./my-teams/high-school-my-teams.scss";
                @import "./my-teams/player-my-teams.scss";

                .parent-dropdown-container {
                    margin: $base-grid-padding;
                    margin-bottom: 0;

                    @media screen and (max-width: $small-breakpoint) {
                        margin: $base-padding-half;
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media screen and (max-width: $xmedium-breakpoint) {
            .dashboard-content-container {
                .dashboard-content-header {
                    .title-container {
                        .title {
                            @include headerFont;
                            @include font-size(36);
                            @include line-height(36);
                            margin: 0;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: $medium-breakpoint) {
            &.has-verification-message {
                height: calc(100vh - 50px);
            }

            .dashboard-content-container {
                .dashboard-content-header {
                    .title-container {
                        .title {
                            @include headerFont;
                            @include font-size(32);
                            @include line-height(32);
                            margin: 0;
                        }

                        .subtitle {
                            @include font-size(12);
                            @include line-height(16);
                            margin: 0;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            flex-direction: column;

            .mobile-dashboard-header {
                display: flex;
            }

            &.has-verification-message {
                .dashboard-content-container {
                    margin-top: 0;
                }
            }

            .dashboard-content-container {
                margin-top: $mobile-navigation-height;
                height: calc(100% - #{$mobile-navigation-height});
                .dashboard-content-header {
                    padding: 0 $base-padding-half;
                    .title-container {
                        .title {
                            @include headerFont;
                            @include font-size(28);
                            @include line-height(28);
                            margin: 0;
                        }

                        .subtitle {
                            @include font-size(12);
                            @include line-height(16);
                            margin: 0;
                            margin-top: 5px;
                        }
                    }
                }
                .account-dropdown {
                    display: none;
                }
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            &.has-verification-message {
                height: calc(100vh - 60px);
            }
            .dashboard-content-container {
                .dashboard-content-header {
                    padding: $base-padding-half;
                    min-height: $mobile-navigation-height;
                    height: auto;

                    .title-container {
                        .title {
                            @include headerFont;
                            @include font-size(24);
                            @include line-height(24);
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
