.journey-steps-card {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $base-padding-half;
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    margin-top: $base-grid-padding;
    width: 100%;
    @include baseShadowOnWhite;

    @import "./journey-step-card.scss";

    @media screen and (max-width: $large-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .step-card {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;

            &:nth-child(3) {
                grid-column: span 2;
            }
        }
    }
    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .step-card {
            width: 100%;
            max-width: none;
            margin: 0 auto;

            &:nth-child(3) {
                grid-column: span 1;
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        gap: $base-padding-half;
        padding: $base-padding-half;

        .step-card {
            width: 100%;
            max-width: none;
            margin: 0 auto;

            &:nth-child(3) {
                grid-column: span 1;
            }
        }
    }
}
