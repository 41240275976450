.tickets {
    display: grid;
    gap: $base-padding-half;
    margin-top: 20px;

    @import "./event-ticket.scss";

    .button {
        width: 100%;
    }

    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .button {
            grid-column: span 2;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .button {
            grid-column: span 1;
        }
    }
}
