.games {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .game-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $base-text-on-dark-tone;

        .icon-container {
            width: 64px;
            height: 64px;
            margin-bottom: 10px;

            .icon {
                width: 34px;
                height: 34px;
            }
        }

        @include font-size(14);
        @include line-height(14);
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    @media screen and (max-width: $small-breakpoint) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        .game-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .icon-container {
                width: 50px;
                height: 50px;
                margin-bottom: 10px;

                .icon {
                    width: 26px;
                    height: 26px;
                }
            }

            @include font-size(14);
            @include line-height(14);
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (max-width: 400px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
