.add-vod-form-card {
    @extend .dashboard-card;

    .required-formatter {
        top: 15px;
    }

    .input-field-container {
        .input-field {
            border-color: $off-white;
            @include font-size(14);

            &:focus {
                border-color: $blue;
            }
        }
        .required {
            @extend .required-formatter;
        }
    }
    .select-container {
        .select__control {
            border-color: $off-white;

            &.select__control--is-focused {
                border-color: $blue;
            }
        }
        .select__placeholder {
            @include font-size(14);
            font-weight: $weight-regular;
        }
        .select__single-value,
        .select__input-container {
            @include font-size(14);
        }

        .select__menu {
            z-index: 2;
        }
    }

    .button {
        margin: 0 auto;
        width: 100%;
        max-width: 240px;
        height: 50px;
        border-radius: 25px;
        @include font-size(16);
    }

    @media screen and (max-width: $small-breakpoint) {
        .button {
            height: 40px;
            @include font-size(14);
            max-width: 180px;
        }
    }
}
