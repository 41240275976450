.player-rank-display {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .player-rank {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .rank-label {
            margin: 0;
            text-transform: uppercase;
            @include font-size(12);
            @include line-height(12);
            color: $base-text-on-white;
        }
        .rank {
            margin: 0;
            text-transform: uppercase;
            color: $blue;
            font-weight: $weight-bold;
        }
    }

    .icon-container {
        flex-shrink: 0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .icon-container {
            width: 30px;
            height: 30px;

            .icon {
                width: 16px;
                height: 16px;
            }
        }
        .rank {
            @include font-size(12);
            @include line-height(12);
        }
    }
}
