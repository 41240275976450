.event-navigation {
    margin: 20px 0;
    display: flex;

    .button {
        background-color: $white;
        margin-right: 10px;
        width: 150px;

        &:hover,
        &.active {
            background-color: $base-dark-tone;
            color: $white;
        }
    }
    @media screen and (max-width: $medium-breakpoint) {
        margin-top: 0;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .button {
            width: 100%;
            margin: 0;
        }
    }
}
