.scouting-report-player-card {
    @include light-card;
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;

    .player-report-card-content {
        padding: $base-padding-half;
        @import "./player-report-player-info-container.scss";
        @import "./player-report-game-info-container.scss";
        @import "./scouting-report-player-banner.scss";

        .info-button-container {
            margin-top: $base-padding-half;
            .info-button {
                margin-top: 10px;
                overflow: hidden;
                &.discord {
                    .icon {
                        fill: $discord;
                    }
                }
            }
        }
    }

    @import "./player-report-bottom-container.scss";
}
