.notification-preferences {
    .email-notifications-form {
        margin-top: $base-padding-half;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $base-padding-half;
        .notification-preference-container {
            display: grid;
            gap: $base-padding-half;
            align-self: flex-start;

            .preference-title {
                @include font-size(14);
                @include line-height(14);
                margin: 0;
                text-transform: uppercase;
                font-weight: $weight-bold;
            }

            .choice-container {
                display: grid;
                gap: 10px;
            }
        }

        .button {
            grid-column: span 3;
            width: 100%;
            max-width: 240px;
            height: 50px;
            border-radius: 25px;
        }

        @media screen and (max-width: $xmedium-breakpoint) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            .button {
                grid-column: span 2;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));

            .notification-preference-container {
                display: grid;
                gap: 10px;

                .preference-title {
                    @include font-size(12);
                    @include line-height(12);
                }
            }
            .button {
                grid-column: span 1;
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            .button {
                margin: 0 auto;
            }
        }
    }
}
