.left-side-profile {
    align-self: flex-start;
    width: 100%;
    display: grid;
    gap: $base-grid-padding;

    grid-template:
        "bio"
        "media";

    .player-bio-container {
        grid-area: bio;
    }

    .media {
        grid-area: media;
    }
}
