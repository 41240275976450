.college-with-main {
    background-color: $white;

    .content {
        display: flex;
        flex-direction: column;
        padding: $base-vertical-padding 0;
        align-items: center;

        .scholar-tagline {
            @include headerFont;
            @include font-size(50);
            @include line-height(50);
            text-align: center;
            margin: 0 auto;
            color: $base-dark-tone;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(40);
                @include line-height(40);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(30);
                @include line-height(30);
            }
        }

        .scholar-description {
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            margin-top: $base-padding;
            color: $base-dark-tone;
            text-align: center;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(14);
                @include line-height(20);
            }
        }
    }

    .button {
        margin-top: $base-padding;

        @media screen and (max-width: $small-breakpoint) {
            margin-top: $base-padding-half;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .content {
            padding: $small-vertical-padding 0;
        }
    }
}
