.partner-header {
    margin-top: $navigation-height;
    width: 100%;
    background-color: $black;
    background-repeat: repeat;
    background-size: 33%;
    background-position: center;
    background-image: url('/images/pattern-spin.jpeg');

    &.coke {
        background-image: url('/images/ind-partners/coke-moisture.jpg');
    }

    .container {
        display: flex;
        flex-direction: column;

        .content-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: $large-vertical-padding 0;

            .partner-icon {
                .icon {
                    fill: $white;

                    &.spin {
                        width: 300px;
                        height: auto;
                    }

                    &.coke {
                        fill: #f40009;
                        height: 50px;
                        width: 161px;
                    }

                    &.playvs-logo {
                        width: 80%;
                    }
                }

                .ufea-logo-light {
                    height: 120px;
                    width: auto;
                }
            }

            .partner-image {
                .background-wrapper {
                    position: relative;
                    background-color: transparent;

                    &.needs-padding {
                        padding: 10px;
                    }

                    .background-image {
                        @include transition;
                        background-color: transparent;

                        img {
                            border-radius: 0px;
                        }
                    }
                }
            }

            .x-sep {
                @include headerFont;
                @include font-size(20);
                margin: 0 $base-padding;
                color: $white;
            }
        }

        @import './page-tabs.scss';
    }

    @media screen and (max-width: $medium-breakpoint) {
        .container {
            .content-container {
                .partner-icon {
                    .icon {
                        &.spin {
                            width: 200px;
                        }

                        &.coke {
                            fill: #f40009;
                            height: 50px;
                            width: 161px;
                        }
                    }

                    .ufea-logo-light {
                        height: 100px;
                        width: auto;
                    }
                }

                .partner-image {
                    .background-wrapper {
                        .background-image {
                            width: 225px;
                            margin: 0 auto;
                        }
                    }
                }

                .x-sep {
                    margin: 0 $base-padding-half;
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .container {
            .content-container {
                flex-direction: column;
                margin: 60px 0;

                .partner-icon {
                    margin-top: $base-padding-half;
                    .icon {
                        fill: $white;

                        &.spin {
                            width: 200px;
                            height: auto;
                        }

                        &.coke {
                            fill: #f40009;
                            height: 50px;
                            width: 161px;
                        }
                    }
                }

                .partner-image {
                    .background-wrapper {
                        position: relative;
                        background-color: transparent;

                        &.needs-padding {
                            padding: 10px;
                        }

                        .background-image {
                            @include transition;
                            background-color: transparent;
                            width: 200px;
                            align-self: center;
                            margin: 0 auto;

                            img {
                                border-radius: 0px;
                            }
                        }
                    }
                }

                .x-sep {
                    @include font-size(16);
                    margin: 0 $base-padding-half;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
        .container {
            .content-container {
                flex-direction: column;
                margin: $base-padding 0px;

                .partner-icon {
                    margin-top: 10px;

                    .icon {
                        fill: $white;

                        &.spin {
                            width: 175px;
                            height: auto;
                        }

                        &.coke {
                            fill: #f40009;
                            height: 50px;
                            width: 161px;
                        }
                    }

                    .ufea-logo-light {
                        height: 80px;
                        width: auto;
                    }
                }

                .partner-image {
                    .background-wrapper {
                        position: relative;
                        background-color: transparent;

                        &.needs-padding {
                            padding: 10px;
                        }

                        .background-image {
                            @include transition;
                            background-color: transparent;
                            width: 50%;
                            align-self: center;
                            margin: 0 auto;

                            img {
                                border-radius: 0px;
                            }
                        }
                    }
                }

                .x-sep {
                    @include font-size(16);
                    margin: 10px 0;
                }
            }
        }
    }
}
