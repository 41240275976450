.left-side-profile {
    align-self: flex-start;
    width: 100%;
    display: grid;
    gap: $base-grid-padding;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @import "./banner.scss";

    .banner {
        grid-column: span 2;
        margin: 0;
    }

    .committed-to-college-card {
        grid-column: span 2;
    }

    .player-bio-container {
        grid-column: span 2;
    }

    @media screen and (max-width: $small-breakpoint) {
        .committed-to-college-card {
            grid-column: span 1;
        }

        .player-bio-container {
            grid-column: span 1;
        }

        .banner {
            grid-column: span 1;
        }
    }
}
