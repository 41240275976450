.add-game-card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    position: relative;
    align-items: center;
    justify-content: center;
    background-image: url("/images/SPIN_Add_Game_Background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: $base-grid-padding;

    .card-title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        color: $white;
        text-align: center;
        margin: 0;
    }

    .card-description {
        @include font-size(14);
        @include line-height(18);
        color: $base-text-on-dark-tone;
        text-align: center;
        margin: 0;
        margin-top: 5px;
    }

    .button {
        margin: 0 auto;
        margin-top: $base-padding-half;
        width: 100%;
        max-width: 200px;
    }
}
