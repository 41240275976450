.leaderboard-card {
    @include light-card;
    padding-top: 0;
    margin: $base-padding 0;

    .leaderboard-selection-container {
        display: flex;
        height: 90px;
        align-items: center;
        .leaderboard-title {
            @include headerFont;
            @include font-size(36);
            @include line-height(36);
            margin: 0;
        }

        .select-field-container {
            margin-left: auto;
            max-width: 400px;
            width: 100%;

            .select__control {
                border-color: $off-white;
        
                &.select__control--is-focused,
                &:active,
                &:focus {
                    border-color: $blue;
                }
            }

            .select__placeholder {
                @include font-size(14);
                font-weight: $weight-regular;
            }
            .select__single-value,
            .select__input-container {
                @include font-size(14);
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .leaderboard-selection-container {
            .leaderboard-title {
                @include font-size(32);
                @include line-height(32);
            }

            .select-field-container {
                max-width: 300px;
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        margin: $base-padding-half 0;
        padding: $base-padding-half;

        .leaderboard-selection-container {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            margin-bottom: 10px;

            .leaderboard-title {
                width: 100%;
                @include font-size(28);
                @include line-height(28);
            }

            .select-field-container {
                max-width: 100%;
                margin-top: 10px;
            }
        }
    }
}