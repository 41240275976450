.find-colleges-form {
    display: flex;
    flex-direction: column;
    gap: $base-grid-padding;
    padding: $base-padding-half;
    max-height: calc(100vh - 102px - #{$base-padding} - 92px);
    overflow: auto;

    .form-heading {
        @include font-size(18);
        @include line-height(24);
        margin: 0;
        margin-bottom: 4px;
        text-transform: uppercase;
    }

    .location-container,
    .offer-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .radio-button-field-container {
            .radio-button-label {
                @include font-size(16);
                @include line-height(18);

                .radio-button {
                    width: 30px;
                    height: 30px;

                    &::before {
                        width: 18px;
                        height: 18px;
                        box-shadow: inset 18px 18px $blue;
                    }
                }
            }
        }

        .select-container {
            .select__control {
                border-color: $off-white;
            }
            .select__placeholder {
                @include font-size(14);
                font-weight: $weight-regular;
            }
            .select__single-value,
            .select__input-container {
                @include font-size(14);
            }

            .select__menu {
                z-index: 2;
            }
        }

        .number-slider-container {
            border-color: $off-white;
        }

        .checkbox-display {
            border-color: $off-white;

            &.active {
                border-color: $blue;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        gap: 10px;
        padding: 10px;

        .form-heading {
            @include font-size(16);
            @include line-height(20);
            margin-bottom: 0;
        }

        .location-container,
        .offer-container {
            .radio-button-field-container {
                .radio-button-label {
                    @include font-size(14);
                    @include line-height(16);

                    .radio-button {
                        width: 24px;
                        height: 24px;

                        &::before {
                            width: 14px;
                            height: 14px;
                            box-shadow: inset 14px 14px $blue;
                        }
                    }
                }
            }
        }
    }
}
