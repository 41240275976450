.college-following-card {
    @extend .dashboard-card;
    grid-column: span 2;
    max-height: 100%;
    overflow: hidden;

    .content {
        @include customScrollbarOnWhite;
        display: grid;
        max-height: 100%;
        overflow: auto;
        gap: 10px;
        position: relative;
        align-content: flex-start;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .no-content-container {
            padding: $base-padding;
            grid-column: span 2;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon {
                width: 50px;
                height: 50px;
                fill: $base-dark-tone;
            }
            h2 {
                @include font-size(18);
                text-transform: uppercase;
                margin: 0;
                margin-top: $base-padding-half;
                text-align: center;
            }
        }

        &.inactive {
            overflow: hidden;
            min-height: 200px;
        }
    }
}
