.event-registration-card {
    position: relative;
    width: 490px;
    background: $off-white;
    flex-shrink: 0;
    z-index: 1;
    padding: $base-grid-padding;
    top: -338px;
    border-radius: $border-radius;
    min-height: 500px;

    &.no-prize {
        top: -265px;
    }

    .title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
    }

    @import "./event-tickets.scss";

    .event-disclaimer {
        @include font-size(16);
        @include line-height(16);
        text-transform: uppercase;
        text-align: center;
        font-weight: $weight-bold;
        margin: 0;
        margin-top: 20px;
    }

    @media screen and (max-width: $medium-breakpoint) {
        width: 100%;
        top: 0;
        min-height: unset;
        &.no-prize {
            top: 0;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;

        .title {
            @include font-size(28);
            @include line-height(28);
        }
    }
}
