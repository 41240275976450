.my-events-card-skeleton {
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
    flex: 1170;
    .react-loading-skeleton {
        border-radius: $border-radius;
        height: 100%;
    }
}
