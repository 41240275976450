.my-events-card {
    @extend .dashboard-card;
    width: 100%;
    height: 100%;
    flex: 1170;

    .title-container {
        .image-wrapper {
            @include image-wrapper(40px, 40px, 100%);
            margin-right: 10px;
        }
    }

    .content {
        overflow-x: hidden;
        overflow-y: auto;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        @include customScrollbarOnWhite;
        @import "../../events/event-card.scss";
        @import "./explore-events-card.scss";

        .full {
            grid-column: span 3;
            min-height: 175px;
            height: 100%;
        }

        @import "../dashboard-event-cards.scss";
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        .content {
            .full {
                grid-column: span 1;
            }
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    @media screen and (max-width: $xmedium-breakpoint) {
        .content {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
    @media screen and (max-width: $medium-breakpoint) {
        height: auto;
        .content {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    @media screen and (max-width: $small-dashboard-breakpoint) {
        .content {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
