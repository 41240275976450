.number-slider-container {
    max-width: 100%;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    border: $border-thickness solid transparent;
    background-color: $white;
    padding: 0 25px;
    outline: 0;
    @include transition;
    display: flex;
    align-items: center;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }

    .required {
        color: $blue;
        @include font-size(24);
        font-weight: $weight-bold;
        position: relative;
        top: 4px;
        margin-right: 10px;
        flex-shrink: 0;
    }

    label {
        color: $base-dark-tone;
        font-weight: $weight-regular;
        font-family: $base-font-family;
        @include font-size(14);
        flex-shrink: 0;
        margin-right: 20px;
    }

    .slider-container {
        width: 100%;
    }

    .slider-value {
        width: 20%;
        max-width: 70px;
        padding-left: 10px;
        flex-shrink: 0;
        @include font-size(14);
        font-weight: $weight-bold;
        text-align: right;
    }

    .slider-track {
        background-color: $off-white;
        height: 8px;
        border-radius: 4px;
    }

    .slider-thumb {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: $blue;
        box-shadow: none;
        border: $border-thickness solid $white;
    }
    @media screen and (max-width: $small-breakpoint) {
        height: 40px;
        padding: 0 15px;
    }
}
