.footer {
    width: 100%;
    background-color: $black;
    color: $base-text-on-black;
    padding: $base-vertical-padding 0;
    display: flex;
    position: relative;
    overflow: hidden;

    .container {
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 1;
        flex-wrap: wrap;

        .footer-column {
            display: flex;
            flex-direction: column;

            p {
                margin: 0;
            }

            .footer-column-title {
                @include headerFont;
                @include font-size(32);
                @include line-height(32);
                color: $white;
                margin-bottom: 30px;
            }

            @import "./footer-description";
            @import "./footer-socials.scss";
            @import "./footer-newsletter.scss";
        }
    }

    .footer-background-icon {
        position: absolute;
        left: calc(50% - 560px);
        top: calc(50% - 632.5px);
        width: 1120px;
        height: 1265px;
        fill: #151521;
        opacity: 0.3;
        transform: rotate(15deg);
    }

    @media screen and (max-width: $large-breakpoint) {
        padding: $large-vertical-padding 0;
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding: $medium-vertical-padding 0;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $small-vertical-padding 0;
    }
}
