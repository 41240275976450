.dashboard-features {
    flex: 550;
    display: grid;
    gap: $base-grid-padding;
    padding-left: $base-grid-padding;

    .feature-card {
        display: flex;
        align-items: center;
        position: relative;
        background-color: $base-dark-tone;
        border-radius: $border-radius;
        padding: $base-grid-padding;
        overflow: hidden;
        @include baseShadowOnWhite;

        .background-icon {
            width: 600px;
            height: 600px;
            position: absolute;
            top: calc(50% - 300px);
            left: calc(50% - 300px);
            transform: rotate(15deg);
            fill: $purple;
            opacity: 0.1;
        }

        .feature-icon {
            width: 100px;
            height: 100px;
            position: relative;
            z-index: 2;

            .icon {
                width: 100px;
                height: 100px;
                fill: $white;

                &.green {
                    fill: $green;
                }

                &.purple {
                    fill: $purple;
                }

                &.blue {
                    fill: $blue;
                }
            }
        }

        .feature-content {
            display: flex;
            flex-direction: column;
            padding-left: $base-grid-padding;
            position: relative;
            z-index: 2;
        }

        .feature-label {
            @include headerFont;
            @include font-size(30);
            @include line-height(30);
            margin: 0;
            color: $white;
        }
        .feature-description {
            @include font-size(14);
            @include line-height(18);
            margin: 0;
            color: $base-text-on-dark-tone;
        }

        @media screen and (max-width: $xmedium-breakpoint) {
            .feature-icon {
                width: 60px;
                height: 60px;
                .icon {
                    width: 60px;
                    height: 60px;
                }
            }
            .feature-label {
                @include font-size(24);
                @include line-height(24);
                margin: 0;
                color: $white;
            }
        }
        @media screen and (max-width: $medium-breakpoint) {
            .feature-icon {
                width: 100px;
                height: 100px;
                .icon {
                    width: 100px;
                    height: 100px;
                }
            }
            .feature-label {
                @include font-size(32);
                @include line-height(32);
            }
            .background-icon {
                width: 1000px;
                height: 1000px;
                top: calc(50% - 500px);
                left: calc(50% - 500px);
            }
        }
        @media screen and (max-width: $small-breakpoint) {
            padding: $base-padding-half;
            .feature-icon {
                width: 80px;
                height: 80px;
                .icon {
                    width: 80px;
                    height: 80px;
                }
            }
            .feature-content {
                padding-left: $base-padding-half;
            }
            .feature-label {
                @include font-size(24);
                @include line-height(24);
            }
            .background-icon {
                width: 600px;
                height: 600px;
                top: calc(50% - 300px);
                left: calc(50% - 300px);
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            .feature-icon {
                width: 60px;
                height: 60px;
                .icon {
                    width: 60px;
                    height: 60px;
                }
            }
            .feature-label {
                @include font-size(22);
                @include line-height(22);
            }
            .feature-description {
                @include font-size(12);
                @include line-height(16);
            }
            .background-icon {
                width: 500px;
                height: 500px;
                top: calc(50% - 250px);
                left: calc(50% - 250px);
            }
        }
        @media screen and (max-width: $tiny-breakpoint) {
            .feature-icon {
                width: 60px;
                height: 60px;
                .icon {
                    width: 60px;
                    height: 60px;
                }
            }
            .feature-label {
                @include font-size(20);
                @include line-height(20);
            }
            .background-icon {
                width: 350px;
                height: 350px;
                top: calc(50% - 175px);
                left: calc(50% - 175px);
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        gap: $base-padding-half;
    }
}
