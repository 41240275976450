.my-upcoming-events-card {
    @extend .dashboard-card;
    width: 100%;
    flex: 360;
    margin-left: $base-grid-padding;

    .content {
        overflow-x: hidden;
        overflow-y: auto;
        @include customScrollbarOnWhite;
        @import "../../events/event-card.scss";
        @import "../dashboard-event-cards.scss";
    }

    @media screen and (max-width: $medium-breakpoint) {
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: $base-grid-padding;

        .content {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin-top: $base-padding-half;
    }

    @media screen and (max-width: $small-dashboard-breakpoint) {
        .content {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
