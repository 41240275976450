.product-quotes {
    display: flex;
    flex-direction: column;
    background-image: url('/images/SPIN_Icon_BG_Straight.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: $base-vertical-padding 0;
    margin: 0;

    &.home {
        background-color: $base-dark-tone;
    }

    .container {
        &.pricing {
            max-width: $slim-site-width;
        }

        .title {
            @include headerFont;
            @include font-size(60);
            @include line-height(60);
            margin: 0;
            padding-bottom: $base-padding-half;
            color: $white;
            position: relative;
            text-align: center;

            &.pricing {
                color: $base-dark-tone;
            }

            & span {
                color: $blue;
            }

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(40);
                @include line-height(40);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(30);
                @include line-height(30);
            }
        }

        @import './product-testimonials.scss';
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding 0;

        .container {
            .title {
                padding-bottom: 10px;
            }
        }
    }
}
