.subscription-plans {
    width: 100%;
    position: relative;
    background-color: $base-dark-tone;
    background-image: url("/images/SPIN_Icon_BG_Straight.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: $navigation-height;

    .pricing {
        @include defaultContainerPadding;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            @include headerFont;
            @include font-size(90);
            @include line-height(90);
            margin: 0;
            color: $white;
            position: relative;
            z-index: 2;
            text-align: center;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(60);
                @include line-height(60);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(48);
                @include line-height(48);
            }

            @media screen and (max-width: $mobile-breakpoint) {
                @include font-size(36);
                @include line-height(36);
            }
        }

        .description {
            @include font-size(18);
            @include line-height(28);
            max-width: 700px;
            color: $base-text-on-dark-tone;
            position: relative;
            z-index: 2;
            text-align: center;
            margin: 0;
            margin-top: $base-grid-padding;

            @media screen and (max-width: $medium-breakpoint) {
                margin-top: $base-padding-half;
            }

            @media screen and (max-width: $small-breakpoint) {
                margin-top: 10px;
                @include font-size(14);
                @include line-height(20);
                max-width: 500px;
            }
        }

        @import "./wrong-user-prompt.scss";
        @import "./sub-card-grid.scss";
        @import "./pricing-card-toggle.scss";
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
    }
}
