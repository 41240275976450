.message-preferences {
    .message-preferences-form {
        margin-top: $base-padding-half;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $base-padding-half;
        .message-preference-container {
            display: grid;
            gap: $base-padding-half;
            align-self: flex-start;

            .preference-title {
                @include font-size(14);
                @include line-height(14);
                margin: 0;
                text-transform: uppercase;
                font-weight: $weight-bold;
            }

            .choice-container {
                display: grid;
                gap: 10px;

                .default-response-radio {
                    .radio-button-label {
                        align-items: flex-start;
                    }
                }

                .textarea-container {
                    margin-left: 34px;

                    .textarea {
                        border-color: $off-white;
                        min-height: 110px;
                        @include font-size(14);

                        &:active,
                        &:focus {
                            border-color: $blue;
                        }

                        &:disabled {
                            border-color: $off-white;
                        }
                    }
                }
            }
        }

        .button {
            grid-column: span 3;
            width: 100%;
            max-width: 240px;
            height: 50px;
            border-radius: 25px;
        }

        .upgrade-container {
            .label {
                @include font-size(16);
                font-weight: $weight-bold;
            }
            .button {
                width: 200px;
                height: 40px;
            }
        }

        @media screen and (max-width: $xmedium-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            .button {
                grid-column: span 1;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            .message-preference-container {
                display: grid;
                gap: 10px;

                .preference-title {
                    @include font-size(12);
                    @include line-height(12);
                }
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            .upgrade-container {
                .label {
                    text-align: center;
                }
            }
            .button {
                margin: 0 auto;
            }
        }
    }
}
