.hero-header {
    display: flex;
    margin-top: $navigation-height;
    width: 100%;
    height: calc(100vh - $navigation-height);
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
        url('/images/home-images/player-crowd-main.jpeg');
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;
    background-size: cover;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .header-title {
            @include headerFont;
            @include font-size(100);
            @include line-height(100);
            margin: 0;
            margin-top: calc(0.3 * (100vh - $navigation-height));
            color: $white;

            span {
                color: $blue;
            }
        }

        .header-support {
            @include font-size(25);
            @include line-height(40);
            font-weight: 400;
            text-align: center;
            margin: $base-padding-half 0;
            color: $white;
        }

        .button {
            margin-top: $base-padding-half;
            z-index: 2;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .container {
            .header-title {
                @include font-size(80);
                @include line-height(80);
            }

            .header-support {
                @include font-size(24);
                @include line-height(35);
                font-weight: 400;
                text-align: center;
                margin: $base-padding-half 0;
                color: $white;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .container {
            .header-title {
                @include font-size(70);
                @include line-height(70);
                text-align: center;
                text-shadow: none;
                margin-top: calc(0.3 * (100vh - $navigation-height));
            }

            .header-support {
                @include font-size(22);
                @include line-height(30);
                font-weight: 400;
                text-align: center;
                margin: $base-padding-half 0;
                color: $white;
            }

            .button {
                margin-top: $mobile-base-padding;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
        height: calc(100vh - $mobile-navigation-height);
        background-attachment: scroll;

        .container {
            .header-title {
                @include font-size(40);
                @include line-height(40);
            }

            .header-support {
                @include font-size(18);
                @include line-height(25);
                margin: $base-padding-half 0;
            }
        }
    }
}
