.explore-events-card {
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    position: relative;
    align-items: center;
    justify-content: center;
    background-image: url("/images/SPIN_Explore_Upcoming_Events_Background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: $base-grid-padding;

    &.span-1 {
        grid-column: span 1;
    }

    .card-title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        color: $white;
        text-align: center;
        margin: 0;
    }

    .card-description {
        @include font-size(14);
        @include line-height(18);
        color: $base-text-on-dark-tone;
        text-align: center;
        margin: 0;
        margin-top: 5px;
    }

    .button {
        margin: 0 auto;
        margin-top: $base-padding-half;
        width: 100%;
        max-width: 200px;
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        grid-column: span 1;
    }
    @media screen and (max-width: $xmedium-breakpoint) {
        grid-column: span 1;
    }
    @media screen and (max-width: $medium-breakpoint) {
        grid-column: span 1;
    }
    @media screen and (max-width: $small-dashboard-breakpoint) {
        grid-column: span 1;
    }
}
