.activation-section {
    background-color: $off-white;
    color: $base-dark-tone;
    @include defaultContainerPadding;

    &.dark {
        background-color: $base-dark-tone;
        color: $white;
    }

    .container {
        display: flex;
        flex-direction: column;

        &.slim {
            max-width: $slim-site-width;
        }

        .act-header {
            @include headerFont;
            @include font-size(40);
            @include line-height(40);
            margin: 0 auto;
            margin-bottom: $base-padding;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(30);
                @include line-height(30);
            }
        }

        .act-icon {
            display: flex;
            margin-bottom: $base-padding;
            justify-content: center;
            width: 350px;
            align-self: center;

            .icon {
                width: 100%;
                height: auto;

                &.showcase-white {
                    fill: $white;
                }

                &.panel-light {
                    width: 250px;
                    fill: $white;
                }

                &.panel-dark {
                    width: 250px;
                    fill: $base-dark-tone;
                }
            }

            @media screen and (max-width: $small-breakpoint) {
                margin-bottom: $base-padding-half;

                .icon {
                    width: 70%;
                    height: auto;
                }
            }
        }

        .act-description {
            display: flex;
            flex-direction: column;
            margin: 0;
            @include font-size(18);
            @include line-height(24);
            padding-bottom: $base-padding-half;

            &:last-child {
                padding-bottom: 0;
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(14);
                @include line-height(20);
            }
        }

        .act-list-container {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: $base-grid-padding;
            margin: $base-padding-half 0;

            .col-cont {
                display: flex;
                flex-direction: column;

                .col-title {
                    margin: 0;
                    @include font-size(22);
                    @include line-height(26);
                    text-transform: uppercase;
                    font-weight: $weight-bold;
                }

                .act-bullet-list {
                    list-style-position: outside;

                    .bullet-item {
                        @include font-size(18);
                        @include line-height(24);
                        padding-bottom: 5px;

                        &:last-child {
                            padding-bottom: 0px;
                        }

                        @media screen and (max-width: $small-breakpoint) {
                            @include font-size(14);
                            @include line-height(20);
                        }
                    }
                }
            }

            @media screen and (max-width: $small-breakpoint) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: 5px;

                .act-bullet-list {
                    padding-left: 10px;
                }
            }
        }

        .cta-button {
            margin: 0 auto;
            margin-top: $base-padding;

            @media screen and (max-width: $small-breakpoint) {
                margin-top: $base-padding-half;
            }
        }
    }
}
