.card-top-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border: $border-thickness solid $off-white;
    border-bottom: 0px;
    padding: 10px;

    .player-personal-info {
        display: flex;
        align-items: center;

        .player-profile-image {
            @include image-wrapper(60px, 60px, 100%);
            flex-shrink: 0;
        }

        .player-info-container {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .player-name {
                @include font-size(16);
                @include line-height(20);
                margin: 0;
            }

            .player-location {
                @include font-size(12);
                @include line-height(16);
                margin: 0;
                color: $base-text-on-white;
                font-weight: $weight-medium;
            }
        }
    }

    .player-rank-display {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .player-rank {
            margin-left: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .rank-label {
                margin: 0;
                text-transform: uppercase;
                @include font-size(10);
                @include line-height(10);
                color: $base-text-on-white;
            }
            .rank {
                @include font-size(14);
                @include line-height(14);
                margin: 0;
                text-transform: uppercase;
                color: $blue;
                font-weight: $weight-bold;
            }
        }

        .icon-container {
            flex-shrink: 0;
            width: 30px;
            height: 30px;

            .icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}
