.dashboard-navigation {
    width: 300px;
    position: relative;
    background-color: $white;
    height: 100%;
    flex-shrink: 0;
    @include transition;
    z-index: 4;

    .brand-container {
        padding: 0 $base-padding;
        background-color: $base-dark-tone;
        height: $navigation-height;
        display: flex;
        align-items: center;

        .brand {
            .spin-logo {
                fill: $white;
            }
            .spin-icon {
                display: none;
            }
        }
    }

    .links {
        height: calc(100vh - #{$navigation-height});
        display: flex;
        flex-direction: column;
        padding: $base-padding-half;
        overflow-y: auto;
        @include baseShadowOnWhite;

        .separator {
            margin: $base-padding-half 0;
            background-color: $off-white;
            height: $border-thickness;
        }

        .link {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            @include font-size(14);
            text-decoration: none;
            font-weight: $weight-black;
            color: $base-dark-tone;
            height: 50px;
            @include transition;
            padding: $base-padding-half;
            border-radius: $border-radius;
            margin-bottom: 5px;

            .icon {
                @include transition;
                margin-right: 10px;
                fill: $base-dark-tone;
                width: 20px;
                height: 20px;
            }

            .messages {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                min-width: 35px;
                flex-shrink: 0;
                background-color: $light-blue;
                border-radius: 10px;
                margin-left: auto;
                font-weight: $weight-bold;
                text-align: center;
                @include transition;
            }

            &:hover,
            &.active {
                background-color: $light-blue;

                .messages {
                    background-color: $blue;
                    color: $white;
                }
            }
        }
    }

    .minimize-button {
        @include button;
        transform: rotate(180deg);
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        background-color: $base-dark-tone;
        position: absolute;
        right: -10px;
        @include transition;

        .icon {
            fill: $white;
            width: 10px;
            height: 10px;
        }
    }

    &.minimized {
        width: 90px;

        .minimize-button {
            transform: rotate(0deg);
        }

        .links {
            .link {
                align-items: center;
                justify-content: center;
                padding: 0;
                position: relative;
                .icon {
                    margin: 0;
                }
                span {
                    display: none;
                }
                .messages {
                    display: flex !important;
                    position: absolute;
                    top: -2px;
                    right: -6px;
                    border-radius: 10px;
                    @include font-size(11);
                    min-width: 22px;
                    height: 22px;
                    border-radius: 11px;
                    padding: 0 5px;
                }
            }
        }

        .brand-container {
            padding: 0;
            justify-content: center;
            .brand {
                .spin-logo {
                    display: none;
                    &.spin-icon {
                        display: flex;
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        width: 90px;

        .minimize-button {
            display: none;
        }

        .links {
            .link {
                align-items: center;
                justify-content: center;
                padding: 0;
                position: relative;
                .icon {
                    margin: 0;
                }
                span {
                    display: none;
                }

                .messages {
                    position: absolute;
                    top: -2px;
                    right: -6px;
                    border-radius: 10px;
                    @include font-size(11);
                    min-width: 22px;
                    height: 22px;
                    border-radius: 11px;
                    padding: 0 5px;
                }
            }
        }

        .brand-container {
            padding: 0;
            justify-content: center;
            .brand {
                .spin-logo {
                    display: none;
                    &.spin-icon {
                        display: flex;
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        display: none;
    }
}
