&.colleges-page-list-header {
    .filters {
        display: grid;
        gap: $base-grid-padding;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .game-filters {
            grid-column: span 2;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .label {
                width: 100%;
                text-transform: uppercase;
                font-weight: $weight-bold;
                margin: 0;
                margin-bottom: $base-padding-half;
            }
        }

        .filter-by-distance,
        .international-scholarship-container {
            .checkbox-container {
                margin-bottom: $base-padding-half;
            }
        }

        .checkbox-container {
            label {
                text-transform: uppercase;
                font-weight: $weight-bold;
            }
            .checkbox-display {
                border-color: $off-white;
                &.active {
                    border-color: $blue;
                }
            }
        }

        .number-slider-container {
            border-color: $off-white;
        }

        @media screen and (max-width: $medium-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            .game-filters {
                grid-column: span 1;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            gap: $base-padding-half;

            .checkbox-container {
                .checkbox-label {
                    @include font-size(14);
                }
            }
        }
    }
}
