.home-journey {
    background-color: $off-white;
    background-image: url("/images/SPIN_Icon_Tilable_Background.png");
    background-repeat: repeat;
    background-position: center;
    @include defaultContainerPadding;

    @import "../journey/journey-features.scss";
    @import "../journey/journey-steps-card.scss";

    .dashboard-features {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 0;

        .feature-card {
            flex-direction: column;

            .feature-content {
                padding: 0;
                margin-top: $base-padding-half;

                .feature-label,
                .feature-description {
                    text-align: center;
                }
            }
        }
    }

    .journey-slogan {
        padding: 0;
        margin-bottom: 60px;
    }

    .more-info {
        margin: 0 auto;
        margin-top: 60px;
        width: 360px;
        height: 80px;
        border-radius: 40px;
        @include font-size(28);
    }

    @media screen and (max-width: $medium-breakpoint) {
        .journey-slogan {
            .title {
                @include font-size(60);
                @include line-height(60);
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .dashboard-features {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .journey-steps-card {
            margin-top: $base-padding-half;
        }

        .journey-slogan {
            .title {
                @include font-size(48);
                @include line-height(48);
            }
            margin-bottom: $base-padding;
        }
        .more-info {
            margin-top: $base-padding;
            width: 280px;
            height: 60px;
            border-radius: 40px;
            @include font-size(24);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .journey-slogan {
            .title {
                @include font-size(36);
                @include line-height(36);
            }
            margin-bottom: $base-padding-half;
        }
        .more-info {
            margin-top: $base-padding-half;
            width: 240px;
            height: 50px;
            border-radius: 40px;
            @include font-size(18);
        }
    }
}
