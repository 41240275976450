.biography-container {
    .title {
        @include headerFont;
        margin: 0;
        @include font-size(32);
        @include line-height(32);
        margin-top: $base-grid-padding;
        margin-bottom: 15px;
    }

    .biography {
        margin: 0;
        color: $base-text-on-white;
        word-break: break-all;
        @include font-size(14);
        @include line-height(20);
    }

    @media screen and (max-width: $small-breakpoint) {
        .title {
            @include font-size(24);
            @include line-height(24);
        }

        .biography {
            margin: 0;
            color: $base-text-on-white;
            @include font-size(12);
            @include line-height(18);
        }
    }
}
