.ind-partners-featured-cards {
    @include defaultContainerPadding;

    &.players {
        background-image: url('/images/pattern-spin.jpeg');
        background-size: 33%;
        background-repeat: repeat;
    }

    &.schools {
        background-color: $off-white;
    }

    .container {
        .section-title {
            margin-bottom: $base-padding;
            .title {
                @include headerFont;
                @include font-size(40);
                @include line-height(40);
                margin: 0;
                color: $white;

                &.dark {
                    color: $base-dark-tone;
                }
            }
        }
        .feat-player-list {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: $base-grid-padding;
            margin: $base-padding auto;

            @import './feat-card/card.scss';

            .show-more {
                width: 100%;
                max-width: 180px;
                border-radius: 25px;
                height: 50px;
                margin: 0 auto;
                grid-column: span 3;
            }

            @media screen and (max-width: $xmedium-breakpoint) {
                margin: $base-padding auto;
                margin-bottom: $large-vertical-padding;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                .show-more {
                    grid-column: span 2;
                }
            }

            @media screen and (max-width: $medium-breakpoint) {
                margin: $base-padding-half auto;
                gap: $base-padding-half;
                grid-template-columns: repeat(1, minmax(0, 1fr));

                .show-more {
                    grid-column: span 1;
                }
            }

            @media screen and (max-width: $mobile-breakpoint) {
                .show-more {
                    height: 40px;
                }
            }
        }
    }
}
