.scholar-dollar-current {
    background-color: $off-white;

    &.dark {
        background-color: $base-dark-tone;
    }
    .content {
        display: flex;
        flex-direction: column;
        padding: $base-vertical-padding 0;

        .tagline {
            @include headerFont;
            @include font-size(50);
            @include line-height(50);
            text-align: center;
            margin: 0 auto;
            color: $base-dark-tone;

            &.white {
                color: $white;
            }

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(40);
                @include line-height(40);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(30);
                @include line-height(30);
            }
        }

        .description {
            @include font-size(18);
            @include line-height(24);
            margin: $base-padding 0;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(14);
                @include line-height(20);
            }

            &.white {
                color: $white;
            }
        }

        .dollar-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: $base-grid-padding;
            margin-top: $base-padding;

            @import './dollar-card.scss';

            @media screen and (max-width: $medium-breakpoint) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                width: 60%;
                align-self: center;
            }

            @media screen and (max-width: $small-breakpoint) {
                width: 75%;
                align-self: center;
            }

            @media screen and (max-width: $mobile-breakpoint) {
                width: 100%;
                align-self: center;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            padding: $small-vertical-padding 0;
        }

        @media screen and (max-width: $mobile-breakpoint) {
            padding: $mobile-vertical-padding 0;
        }
    }
}
