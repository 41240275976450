.player-profile-info {
    display: flex;
    align-items: center;

    .player-profile-image {
        @include image-wrapper(100px, 100px, 100%);
        flex-shrink: 0;
    }

    .bio {
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        .player-gamertag {
            word-break: break-all;
            @include headerFont;
            margin: 0;
            @include font-size(32);
            @include line-height(32);
        }

        .player-name {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            margin-top: 3px;
            text-transform: uppercase;
            font-weight: $weight-bold;
        }

        .player-location {
            margin: 0;
            margin-top: 2px;
            color: $base-text-on-white;
            @include font-size(14);
            @include line-height(14);
            font-weight: $weight-medium;
        }
    }

    .user-controls {
        display: flex;
        align-self: flex-start;
        margin-left: auto;

        .button {
            margin-left: 10px;
        }
    }

    .socials-container {
        display: flex;
        margin-top: 10px;
        align-items: center;

        .icon {
            @include transition;
            fill: $base-dark-tone;
            margin-right: 10px;
            width: 20px;
            height: 20px;
            &:hover {
                opacity: 0.6;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        flex-wrap: wrap;
        .user-controls {
            margin-top: 20px;
            width: 100%;

            .button {
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;

        .bio {
            width: 100%;
            align-items: center;
            justify-content: center;
            padding-left: 0;
            margin-top: 10px;

            .player-gamertag,
            .player-location,
            .player-name {
                text-align: center;
            }
        }

        .user-controls {
            margin: 0 auto;
            margin-top: 10px;
            width: auto;

            .button {
                margin-left: 0;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
