.pricing-option-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 220px;
    flex: 1;

    &.margin-top {
        margin-top: 36px;
    }

    .card-highlight {
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        background-color: $white;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        height: 36px;

        @include headerFont;
        @include font-size(22);
        @include line-height(36);
    }

    .card-highlight + .pricing-option {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .pricing-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: $base-padding-half;
        border-radius: $border-radius;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: $white;
        color: $base-dark-tone;

        &.blue-background {
            color: $white;
            background-color: $blue;

            .pricing-background-icon {
                fill: $blue-hover;
            }

            .button {
                border: $border-thickness solid $white;
                color: $white;

                &:hover {
                    background-color: $white;
                    color: $blue;
                }
            }
        }

        &.green-background {
            color: $white;
            background-color: $green;

            .pricing-background-icon {
                fill: $green-hover;
            }

            .button {
                border: $border-thickness solid $white;
                color: $white;

                &:hover {
                    background-color: $white;
                    color: $green;
                }
            }
        }

        &.purple-background {
            color: $white;
            background-color: $purple;

            .pricing-background-icon {
                fill: $purple-hover;
            }

            .button {
                border: $border-thickness solid $white;
                color: $white;

                &:hover {
                    background-color: $white;
                    color: $purple;
                }
            }
        }

        .pricing-background-icon {
            width: 380px;
            height: 380px;
            position: absolute;
            top: calc(50% - 190px);
            left: calc(50% - 190px);
            transform: rotate(15deg);
            fill: $off-white;
        }

        &.college {
            padding: 15px;

            .pricing-value {
                @include font-size(62);
                @include line-height(62);
            }
        }

        &.custom {
            .pricing-value {
                @include font-size(48);
                @include line-height(48);
                margin-top: 35px;
            }

            .button {
                margin-top: 11px;
            }
        }
        
        .pricing-label {
            @include headerFont;
            @include font-size(28);
            @include line-height(28);
            margin: 0;
            margin-top: $base-padding-half;
            position: relative;
            z-index: 2;
        }

        .pricing-value {
            @include headerFont;
            @include font-size(72);
            @include line-height(72);
            text-align: center;
            margin: 0;
            margin-top: 40px;
            position: relative;
            z-index: 2;

            .value-cents {
                @include headerFont;
                @include font-size(24);
                @include line-height(24);
                color: $white;
                opacity: 0.9;
            }
        }

        .pricing-interval {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            font-weight: $weight-medium;
            margin-top: 10px;
            position: relative;
            z-index: 2;
            min-height: 20px;
        }

        .journey-pricing-option-form {
            width: 100%;
        }

        .free-trial-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 21px;

            .free-trial-descriptor {
                @include font-size(12);
                @include line-height(12);
                margin: 0;
                color: $white;
                font-weight: $weight-bold;
                position: relative;
                text-transform: uppercase;
                z-index: 2;
                margin-bottom: 5px;
                text-align: center;
                opacity: 0.9;
            }

            .button {
                margin-top: 0;
                @include font-size(14);
                @include line-height(14);
            }
        }

        .button {
            margin-top: 40px;
            height: 40px;
            width: 100%;
            border-radius: 35px;
            @include font-size(14);
            @include line-height(14);
            font-weight: $weight-black;
            background-color: transparent;
            border: $border-thickness solid $base-dark-tone;
            color: $base-dark-tone;
            position: relative;
            z-index: 2;

            &:hover {
                background-color: $base-dark-tone;
                color: $white;
            }
        }

        @media screen and (max-width: $medium-breakpoint) {
            .pricing-label {
                @include font-size(24);
                @include line-height(24);
            }

            .pricing-value {
                @include font-size(60);
                @include line-height(60);
                .value-cents {
                    @include font-size(28);
                    @include line-height(28);
                }
            }

            .pricing-interval {
                @include font-size(12);
                @include line-height(12);
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            padding: 15px;

            .pricing-label {
                margin-top: 10px;
            }

            .pricing-value {
                @include font-size(52);
                @include line-height(52);
                margin-top: 30px;
                .value-cents {
                    @include font-size(24);
                    @include line-height(24);
                }
            }


            &.custom {
                .pricing-value {
                    @include font-size(40);
                    @include line-height(40);
                    margin-top: 32px;
                }
    
                .button {
                    margin-top: 0px;
                }
            }

            .pricing-interval {
                margin-bottom: 10px;
            }

            .button {
                margin-top: $base-padding-half;
                @include font-size(12);
                @include line-height(12);
            }

            .free-trial-container {
                margin-top: 1px;

                .free-trial-descriptor {
                    @include font-size(10);
                    @include line-height(10);
                }

                .button {
                    @include font-size(12);
                    @include line-height(12);
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin: 0 auto;
        max-width: 360px;

        &.margin-top {
            margin: 0 auto;
        }
    }
}
