.bottom-container {
    display: flex;
    background-color: $base-dark-tone;
    padding: $base-padding-half;
    align-items: center;
    margin-top: auto;

    .button {
        width: 100%;
        max-width: 180px;
    }

    .school-info-container {
        display: flex;
        .info-display {
            display: flex;
            flex-direction: column;
            width: 70px;
            flex-shrink: 0;
            margin-right: $base-padding-half;
        }

        .display-label {
            text-align: center;
            margin: 0;
            color: $base-text-on-dark-tone;
            text-transform: uppercase;
            @include font-size(11);
            @include line-height(11);
            margin-bottom: 3px;
        }

        .display {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $white;
            font-weight: $weight-black;
            @include font-size(18);
            @include line-height(18);
            border-radius: $border-radius-small;
        }

        .gpa {
            background-color: $green;
        }
        .year {
            background-color: $purple;
        }
    }

    @media screen and (max-width: 1700px) {
        flex-direction: column;

        .school-info-container {
            .info-display {
                margin: 0 10px;
            }
        }

        .button {
            margin-top: $base-padding-half;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        flex-direction: column;

        .school-info-container {
            .info-display {
                margin: 0 10px;
            }
        }

        .button {
            margin-top: $base-padding-half;
        }
    }
}
