.event-landing-card {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    text-decoration: none;
    background-color: $white;
    color: $black;
    padding: 20px 20px;
    @include baseShadowOnWhite;

    .event-landing-card-header {
        @include font-size(24);
        @include line-height(24);
        font-family: $base-font-family;
        text-align: left;
        text-transform: uppercase;
        font-weight: $weight-black;
        margin: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(18);
            @include line-height(18);
        }
    }

    .event-landing-card-description {
        @include font-size(18);
        font-family: $base-font-family;
        text-align: left;
        font-weight: $weight-regular;
        margin-top: 20px;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    .event-landing-card-description-2 {
        @include font-size(20);
        @include line-height(25);
        font-family: $base-font-family;
        text-align: left;
        font-weight: $weight-regular;
        font-style: italic;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    .event-landing-card-link {
        @include font-size(18);
        font-family: $base-font-family;
        text-align: left;
        font-weight: $weight-regular;
        margin-top: 15px;
        color: white;
        margin-block-end: 0;

        &:hover {
            color: $blue;
        }

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: 20px;
    }
    
}
