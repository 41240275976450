.compose-group-message-container {
    display: flex;
    margin-left: $base-padding-half;
    align-items: center;

    .compose-group-message {
        background-color: $white;
        border: $border-thickness solid $off-white;
        padding: 10px;
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: $border-radius;

        &.disabled {
            opacity: 0.4;
        }

        .compose-info-container {
            display: flex;
            flex-direction: column;

            .title {
                @include font-size(14);
                @include line-height(14);
                font-weight: $weight-black;
                text-transform: uppercase;
                margin: 0;
            }

            .subtitle {
                @include font-size(12);
                @include line-height(14);
                margin-top: 2px;
                margin: 0;

                &.red {
                    color: $red;
                }
                &.blue {
                    color: $blue;
                }
            }
        }

        .button {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-left: $base-padding-half;

            .icon {
                fill: $white;
            }
        }

        .unsubbed-button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: $blue;
            margin-left: $base-padding-half;

            .icon {
                fill: $white;
            }
        }

        @media screen and (max-width: 840px) {
            .compose-info-container {
                .title {
                    @include font-size(12);
                    @include line-height(12);
                }

                .subtitle {
                    @include font-size(10);
                    @include line-height(12);
                }
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            height: 50px;

            .button,
            .unsubbed-button {
                flex-shrink: 0;
                width: 30px;
                height: 30px;

                .icon {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        @media screen and (max-width: 400px) {
            align-self: flex-end;
            max-width: 145px;

            .compose-info-container {
                .title {
                    display: none;
                }
            }

            .button,
            .unsubbed-button {
                margin-left: 10px;
            }
        }
    }

    .tooltip-container {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media screen and (max-width: $small-breakpoint) {
        margin-left: auto;

        .tooltip-container {
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
