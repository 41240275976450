.college-profile-container {
    margin-top: $navigation-height;
    padding-top: $base-padding;
    padding-bottom: $base-vertical-padding;
    display: flex;

    @import "./college-left-side-profile.scss";
    @import "./college-right-side-profile.scss";

    @import "./college-bio.scss";
    @import "../../profiles/profile-card.scss";

    @media screen and (max-width: $large-breakpoint) {
        padding-bottom: $large-vertical-padding;
        padding-top: $base-padding;
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        .right-side-profile {
            width: 360px;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding-bottom: $medium-vertical-padding;
        padding-top: $base-padding;
        flex-wrap: wrap;
        .right-side-profile {
            margin-top: $base-grid-padding;
            padding-left: 0;
            width: 100%;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-bottom: $small-vertical-padding;
        padding-top: $base-padding;
        flex-wrap: wrap;
        .left-side-profile {
            gap: $base-padding-half;
        }
        .right-side-profile {
            margin-top: $base-padding-half;
            gap: $base-padding-half;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
    }
}
