.player-journey-card {
    background-color: white;
    color: $base-dark-tone;
    border-radius: $border-radius;
    padding: $base-padding;

    .player-journey-card-content {
        display: flex;
        flex-direction: row;
        width: 100%;

        .player-journey-card-profile-container {
            display: flex;
            flex-direction: row;
            gap: $base-padding-half;
            align-items: center;

            .player-profile-image {
                @include image-wrapper(90px, 90px, 100%);
                flex-shrink: 0;
            }

            .player-journey-bio-content {
                display: flex;
                flex-direction: column;

                .display-name {
                    @include headerFont;
                    @include font-size(40);
                    @include line-height(40);
                    margin: 0 0 10px 0;
                }

                .location {
                    @include font-size(16);
                    @include line-height(16);
                    margin: 0;
                }
            }
        }
    }

    .journey-description {
        display: flex;
        margin-top: $base-padding-half;

        .description {
            @include font-size(18);
            @include line-height(24);
            margin: 0;
        }
    }
}
