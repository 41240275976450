.event-landing-body-wide {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: $base-grid-padding;
    grid-auto-rows: min-content;
    margin: $base-padding auto;

    @import "./event-landing-card.scss";
    @import "./event-landing-card-white.scss";

    @media screen and (max-width: $large-breakpoint) {
        margin: $base-padding auto;
        // margin-bottom: $large-vertical-padding;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .show-more {
            grid-column: span 2;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin: $base-padding auto;
        // margin-bottom: $medium-vertical-padding;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .show-more {
            grid-column: span 2;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        grid-auto-rows: unset;
        margin: $base-padding-half auto;
        gap: $base-padding-half;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
