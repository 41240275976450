.links {
    display: flex;
    flex-direction: column;
    .link {
        width: 100%;
        color: $blue;
        text-decoration: none;
        @include font-size(14);
        @include line-height(14);
        margin-top: 10px;

        &:first-child {
            margin-top: 15px;
        }

        &:hover {
            color: $blue-hover;
            text-decoration: underline;
        }
    }
}
