.player-journey-wrapper {
    display: flex;
    margin-top: $navigation-height;
    height: calc(100vh - $navigation-height);
    background-image: url('/images/player-journey/player-journey-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .player-journey-arrow {
            &.left {
                transform: rotate(180deg);
            }
        }

        .player-journey-content {
            display: flex;
            flex-direction: column;
            width: 70%;
            max-height: 100%;
            @include transition;

            &.hidden {
                display: none;
            }

            &.video {
                width: 60%;
            }

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: $white;
                margin-bottom: $base-padding-half;

                .title {
                    @include headerFont;
                    @include font-size(40);
                    margin: 0;
                }

                .content {
                    @include font-size(24);
                    @include line-height(30);
                    align-self: flex-end;
                    margin: 0;
                    font-weight: $weight-medium;
                    text-transform: uppercase;
                }
            }

            @import './card/player-journey-display.scss';
            @import './card/player-journey-video-card.scss';
            @import './card/college/player-journey-college.scss';
        }
    }

    @media screen and (max-width: $large-breakpoint) {
        height: auto;
        padding: $base-padding 0;
    }
}
