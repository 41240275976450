h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $weight-black;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 2rem;
}
h1 {
    @include font-size(42);
    @include line-height(42);
}
h2 {
    @include font-size(32);
    @include line-height(32);
}
h3 {
    @include font-size(24);
    @include line-height(24);
}
h4 {
    @include font-size(18);
    @include line-height(18);
}
h5 {
    @include font-size(16);
    @include line-height(16);
}
h6 {
    @include font-size(14);
    @include line-height(14);
}

a {
    text-decoration: none;
    color: $blue;
    @include transition;

    &:hover {
        color: $blue-hover;
        text-decoration: underline;
    }
}

b,
strong {
    font-weight: $weight-bold;
}

p {
    @include font-size(18);
    @include line-height(24);
    margin-top: 0;
    margin-bottom: 2rem;
}

li {
    @include font-size(18);
    @include line-height(24);
    margin-bottom: 1rem;
}

*:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: $medium-breakpoint) {
    h1 {
        @include font-size(36);
        @include line-height(36);
    }
    h2 {
        @include font-size(28);
        @include line-height(28);
    }
    h3 {
        @include font-size(20);
        @include line-height(20);
    }
    h4 {
        @include font-size(16);
        @include line-height(16);
    }
    h5 {
        @include font-size(14);
        @include line-height(14);
    }
    h6 {
        @include font-size(12);
        @include line-height(12);
    }

    p,
    li {
        @include font-size(16);
        @include line-height(22);
    }
}

@media screen and (max-width: $small-breakpoint) {
    h1 {
        @include font-size(28);
        @include line-height(28);
        margin-bottom: 1.25rem;
    }
    h2 {
        @include font-size(24);
        @include line-height(24);
        margin-bottom: 1.25rem;
    }
    h3 {
        @include font-size(18);
        @include line-height(18);
        margin-bottom: 1.25rem;
    }
    h4 {
        @include font-size(14);
        @include line-height(14);
        margin-bottom: 1.25rem;
    }
    h5 {
        @include font-size(13);
        @include line-height(13);
        margin-bottom: 1.25rem;
    }
    h6 {
        @include font-size(12);
        @include line-height(12);
        margin-bottom: 1.25rem;
    }
    li {
        @include font-size(14);
        @include line-height(20);
    }
    p {
        @include font-size(14);
        @include line-height(20);
        margin-bottom: 1.25rem;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    h1 {
        @include font-size(24);
        @include line-height(24);
        margin-bottom: 1rem;
    }
    h2 {
        @include font-size(20);
        @include line-height(20);
        margin-bottom: 1rem;
    }
    h3 {
        @include font-size(16);
        @include line-height(16);
        margin-bottom: 1rem;
    }
    h4 {
        @include font-size(12);
        @include line-height(12);
        margin-bottom: 1rem;
    }
    h5 {
        @include font-size(12);
        @include line-height(12);
        margin-bottom: 1rem;
    }
    h6 {
        @include font-size(11);
        @include line-height(1);
        margin-bottom: 1rem;
    }
    p {
        @include font-size(14);
        @include line-height(20);
        margin-bottom: 1rem;
    }
    li {
        @include font-size(14);
        @include line-height(20);
        margin-bottom: 0.5rem;
    }
}
