.event-landing-description {
    color: white;

    .dates {
        @include font-size(16);
        @include line-height(20);
        margin-bottom: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(12);
            @include line-height(10);
        }
    }

    .game {
        @include font-size(16);
        @include line-height(20);
        margin-bottom: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(12);
            @include line-height(12);
        }
    }

    .prize-pool {
        @include font-size(34);
        @include line-height(20);
        @include headerFont;
        margin-bottom: 0;
        color: $green;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(24);
            @include line-height(24);
        }
    }

    .location-event {
        @include font-size(16);
        @include line-height(21);
        text-transform: uppercase;
        margin-bottom: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(12);
            @include line-height(12);
        }
    }
}