.college-my-profile-container {
    padding: $base-grid-padding;
    display: flex;
    flex-direction: column;

    .profile-container {
        display: flex;

        @import "../../colleges/profile/college-left-side-profile.scss";
        @import "../../colleges/profile/college-right-side-profile.scss";
        @import "../../colleges/profile/college-bio.scss";
        @import "../../profiles/profile-card.scss";

        @media screen and (max-width: $large-breakpoint) {
            .right-side-profile {
                width: 360px;
            }
        }

        @media screen and (max-width: $xmedium-breakpoint) {
            flex-wrap: wrap;
            .right-side-profile {
                margin-top: $base-grid-padding;
                padding-left: 0;
                width: 100%;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            .left-side-profile {
                gap: $base-padding-half;
            }
            .right-side-profile {
                margin-top: $base-padding-half;
                gap: $base-padding-half;
            }
        }
    }

    .large-button {
        margin-top: $base-grid-padding;
        margin-left: auto;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        .large-button {
            margin-right: auto;
            @include font-size(14px);
            @include line-height(14px);
            height: 50px;
            width: 200px;
        }
    }
}
