.order-subtotal {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-padding-half;

    .order-title {
        @include font-size(18);
        @include line-height(18);
        margin: 0;
        text-transform: uppercase;
        font-weight: $weight-black;
        margin-bottom: 10px;
    }

    .separator {
        height: 1px;
        width: 100%;
        background-color: $off-white;
        margin: 10px 0;
    }

    .order-item {
        display: flex;
        .item-description {
            margin: 0;
            @include font-size(15);
            @include line-height(24);
            padding-right: 20px;
        }
        .item-price {
            flex-shrink: 0;
            margin: 0;
            margin-left: auto;
            @include font-size(15);
            @include line-height(24);
        }
    }

    .order-total {
        display: flex;
        margin-top: 10px;

        .title {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            text-transform: uppercase;
            font-weight: $weight-black;
        }

        .price {
            margin: 0;
            margin-left: auto;
            @include font-size(18);
            @include line-height(18);
            font-weight: $weight-black;
        }
    }
}
