.summer-dark {
    .cls-1 {
        fill: none;
    }

    .cls-2 {
        fill: #121212;
    }

    .cls-4 {
        fill: #f40009;
    }
}

.summer-light {
    .cls-1 {
        fill: none;
    }

    .cls-2 {
        fill: #fff;
    }

    .cls-4 {
        fill: #f40009;
    }
}
