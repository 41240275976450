.referred-container {
    display: flex;
    flex-direction: column;

    .title {
        @include font-size(18);
        @include line-height(18);
        font-weight: $weight-medium;
        margin: 0;
    }

    .select-container {
        margin-top: 10px;
    }
}
