.event-sponsors-card {
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    margin-top: 20px;

    &.mobile-sponsors-card {
        display: none;
    }

    .title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
    }

    .sponsors-container {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $base-padding-half;

        .sponsor {
            position: relative;
            height: 40px;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        display: none;

        .sponsors-container {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &.mobile-sponsors-card {
            display: block;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        .sponsors-container {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .title {
            @include font-size(28);
            @include line-height(28);
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        .sponsors-container {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
