.events-description-cards-container {
    margin-top: $base-padding;
    display: grid;
    gap: $base-grid-padding;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .events-description-card {
        border-radius: $border-radius;
        background-color: $white;
        @include baseShadowOnBlack;
        padding: 30px;

        .title {
            @include headerFont;
            @include font-size(32);
            @include line-height(32);
            margin: 0;
        }

        .description {
            @include font-size(15);
            @include line-height(20);
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        grid-auto-columns: minmax(0, 1fr);
        grid-template:
            "a b"
            "c c";

        .events-description-card {
            .title {
                @include font-size(28);
                @include line-height(28);
            }

            .description {
                @include font-size(14);
                @include line-height(18);
                margin-bottom: 0;
            }

            &:first-child {
                grid-area: a;
            }
            &:nth-child(2) {
                grid-area: b;
            }
            &:last-child {
                grid-area: c;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        gap: $base-padding-half;
        grid-template: none;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin-top: $base-padding-half;

        .events-description-card {
            padding: $base-padding-half;
            .title {
                @include font-size(24);
                @include line-height(24);
            }

            &:first-child {
                grid-area: unset;
            }
            &:nth-child(2) {
                grid-area: unset;
            }
            &:last-child {
                grid-area: unset;
            }
        }
    }
}
