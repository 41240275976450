& {
    @media screen and (max-width: $small-breakpoint) {
        .event-card-header {
            height: 100px;
            padding-top: 10px;
            .event-icon {
                width: 80px;
                height: 80px;

                .default-picture {
                    .spin-logo {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
        .event-card-content {
            padding-top: 0;
            padding-bottom: 10px;

            .games-container {
                margin-bottom: 10px;

                .icon-container {
                    width: 28px;
                    height: 28px;

                    .icon {
                        width: 14px;
                        height: 14px;
                    }
                }
            }

            .bottom-container {
                .prize-pool {
                    @include font-size(12);
                }
                .prize-value {
                    @include font-size(16);
                    margin-bottom: 5px;
                }

                .button {
                    height: 30px;
                    width: 130px;
                    @include font-size(12);
                }
            }
        }
    }
}
