.select-field-container {
    max-width: 100%;
    position: relative;

    .required {
        color: $blue;
        @include font-size(24);
        font-weight: $weight-bold;
        position: absolute;
        left: 20px;
        top: 15px;
    }

    &.error {
        .required {
            color: $red;
        }
    }

    .description-label {
        @include font-size(14);
        margin: 0;
        margin-top: 5px;
    }

    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }

    .warning-label {
        @include font-size(14);
        color: $base-text-on-white;
        margin: 0;
        margin-top: 10px;
    }

    &.parent-child-select {
        max-width: 300px;
        z-index: 2;
        .image-wrapper {
            @include image-wrapper(40px, 40px, 100%, 5px, 1px);
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }
    &.parent-child-select {
        max-width: 300px;
        z-index: 2;
        .image-wrapper {
            @include image-wrapper(40px, 40px, 100%, 5px, 1px);
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    @media screen and (max-width: $small-breakpoint) {
        .required {
            left: 15px;
            top: 10px;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        &.parent-child-select {
            max-width: 100%;
        }
    }
}

.select-container {
    .select__control {
        max-width: 100%;
        width: 100%;
        min-height: 50px;
        border-radius: 25px;
        border: $border-thickness solid transparent;
        background-color: $white;
        padding: 0;
        padding-left: 20px;
        padding-right: 5px;
        color: $base-dark-tone;
        outline: 0;
        font-weight: $weight-medium;
        font-family: $base-font-family;
        @include transition;
        @include font-size(18);
        box-shadow: none;

        &:hover {
            border-color: transparent;
        }

        &.select__control--is-focused,
        &:active,
        &:focus {
            border-color: $blue;
        }

        &.select__control--menu-is-open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            .select__indicator {
                transform: rotate(180deg);
            }
        }

        .select__indicator-separator {
            display: none;
        }

        .select__input-container,
        .select__placeholder {
            color: $base-dark-tone;
        }

        .select__indicator {
            width: 46px;
            height: 46px;
            @include transition;
            align-items: center;
            justify-content: center;
            svg {
                fill: $base-dark-tone;
            }
        }

        .select__multi-value {
            display: flex;
            align-items: center;
            min-height: 24px;
            background-color: $light-blue;
            border-radius: $border-radius-medium;

            .select__multi-value__label {
                color: $base-dark-tone;
                @include font-size(14);
                padding: 4px 10px;
                white-space: pre-wrap;
                overflow: visible;
                text-overflow: unset;
            }

            .select__multi-value__remove {
                height: 100%;
                border-radius: $border-radius-medium;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                cursor: pointer;

                svg {
                    width: 16px;
                    height: 16px;
                }

                &:hover {
                    background-color: $blue;
                    color: $base-dark-tone;
                }
            }
        }
    }

    &.parent-child-select {
        .select__control {
            min-height: 60px;
            border-radius: $border-radius;
        }
    }

    .select__value-container {
        padding: 9px 0;

        .select__single-value {
            font-weight: $weight-medium;
            grid-area: unset;
            position: absolute;
        }
        .select__placeholder {
            grid-area: unset;
            position: absolute;
        }
        .select__input-container {
            grid-area: unset;
        }
    }

    .select__menu {
        margin: 0;
        border-radius: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border: $border-thickness solid $blue;
        border-top: 0;
        box-shadow: none;

        .select__option {
            background-color: transparent;
            cursor: pointer;

            &.select__option--is-selected {
                font-weight: $weight-medium;
                color: $blue;
            }

            &:hover {
                color: $blue;
            }
        }
    }

    &.error {
        .select__control {
            border-color: $red;
            background-color: $light-red;
        }

        .select__menu {
            border-color: $red;
            background-color: $light-red;
        }
    }

    &.has-required {
        .select__control {
            padding-left: 40px;
        }
    }

    &.parent-child-select {
        .select__control {
            padding-left: 56px;

            .select__value-container {
                padding: 9px 0;

                .select__single-value {
                    font-family: $header-font-family;
                    @include font-size(24);
                }
            }

            &.select__control--menu-is-open {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .select__placeholder {
            @include font-size(14);
            font-weight: $weight-regular;
        }
        .select__single-value,
        .select__input-container {
            @include font-size(14);
        }

        .select__control {
            border-radius: 20px;
            min-height: 40px;
            padding-left: 15px;

            .select__indicator {
                width: 36px;
                height: 36px;
                padding: 0;
            }
        }

        .select__value-container {
            padding: 6px 0;
        }

        &.has-required {
            .select__control {
                padding-left: 30px;
            }
        }
    }
}
