.form-container {
    display: flex;
    flex-direction: column;
    grid-gap: $base-padding-half;
    gap: $base-padding-half;
    margin-top: $base-padding-half;
    max-width: 980px;
    height: 100%;
    align-items: flex-start;
    min-height: calc(100vh - #{$navigation-height} - #{$base-padding-half});

    .progress-container {
        margin: 0 $base-grid-padding;
        margin-top: 10px;
        background-color: $white;
        height: 4px;
        border-radius: 2px;
        width: calc(100% - #{$base-grid-padding * 2});

        .progress {
            border-radius: 2px;
            background-color: $blue;
            height: 4px;
        }
    }

    .parent-information-form {
        margin-top: 0;
        .form-header {
            .form-heading {
                margin-top: 0px;
            }
        }
    }

    .buttons-container {
        width: calc(100% - #{$base-grid-padding * 2});
        display: flex;
        margin: 0 $base-grid-padding;
        margin-bottom: $base-padding;
        margin-top: auto;
        align-items: center;

        .steps {
            @include font-size(18);
            @include line-height(18);
            font-weight: $weight-black;
            text-transform: uppercase;
            margin: 0;
            color: $base-text-on-black;
            flex-shrink: 0;
            margin-right: 10px;
        }

        .button {
            width: 220px;
            height: 50px;
            @include font-size(16);

            &:first-of-type {
                margin-left: auto;
            }

            &:not(:first-of-type) {
                margin-left: $base-padding-half;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        height: auto;
        gap: 10px;

        .progress-container {
            width: calc(100% - #{$base-padding-half * 2});
            margin: 0 $base-padding-half;
        }

        .buttons-container {
            width: calc(100% - #{$base-padding-half * 2});
            margin: 0 $base-padding-half;
            margin-bottom: $base-padding-half;

            .steps {
                @include font-size(14);
                @include line-height(14);
            }

            .button {
                height: 40px;
                width: 180px;
                @include font-size(14);

                &:not(:first-of-type) {
                    margin-left: 10px;
                }
            }
        }
    }
}
