.high-school-profile-container {
    margin-top: $navigation-height;
    padding-top: $base-padding;
    padding-bottom: $base-vertical-padding;
    display: flex;

    @import './high-school-left-side-profile.scss';
    @import './high-school-right-side-profile.scss';

    @import './high-school-bio.scss';
    @import '../../profiles/profile-card.scss';
    @import './high-school-our-teams-card.scss';

    @media screen and (max-width: $large-breakpoint) {
        padding-bottom: $large-vertical-padding;
        padding-top: $base-padding;
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        .right-side-profile {
            width: 360px;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding-bottom: $medium-vertical-padding;
        padding-top: $base-padding;
        flex-wrap: wrap;
        .right-side-profile {
            margin-top: $base-grid-padding;
            padding-left: 0;
            width: 100%;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-bottom: $small-vertical-padding;
        padding-top: $base-padding;
        flex-wrap: wrap;
        .left-side-profile {
            gap: $base-padding-half;
        }
        .right-side-profile {
            margin-top: $base-padding-half;
            gap: $base-padding-half;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
    }
}

.example-high-school-profile-container {
    margin-top: $navigation-height;
    padding-top: $base-padding;
    padding-bottom: $base-vertical-padding;
    display: flex;
    flex-direction: column;

    @import './banner.scss';

    .high-school-profile-container {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    @media screen and (max-width: $large-breakpoint) {
        padding-bottom: $large-vertical-padding;
        padding-top: $base-padding;
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding-bottom: $medium-vertical-padding;
        padding-top: $base-padding;

        .example-banner {
            flex-direction: column;
            align-items: flex-start;

            .example-description-container {
                max-width: 100%;
                padding-right: 0px;

                .example-title {
                    @include font-size(28);
                    @include line-height(28);
                }
            }

            .button {
                margin-top: $base-padding-half;
                margin-left: 0;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding-bottom: $small-vertical-padding;
        padding-top: $base-padding;

        .example-banner {
            flex-direction: column;
            padding: $base-padding-half;
            margin-bottom: $base-padding-half;

            .example-description-container {
                .example-title {
                    @include font-size(24);
                    @include line-height(24);
                }

                .example-subtitle {
                    @include font-size(12);
                    @include line-height(18);
                }
            }

            .button {
                height: 40px;
                width: 180px;
                @include font-size(14);
                margin-top: $base-padding-half;
                margin-left: 0;
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;

        .example-banner {
            .button {
                width: 100%;
            }
        }
    }
}
