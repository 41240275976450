.compose-group-message-content {
    display: grid;
    gap: 10px;
    padding: $base-padding-half;
    max-height: calc(100vh - 72px - #{$base-padding} - 92px);
    overflow: auto;

    .description {
        @include font-size(14);
        @include line-height(18);
        margin: 0;
        margin-bottom: 10px;
    }

    .select-container {
        // z-index: 1;
        .select__placeholder {
            @include font-size(14);
            font-weight: $weight-regular;
        }
        .select__single-value,
        .select__input-container {
            @include font-size(14);
        }

        .select__menu {
            z-index: 2;

            .select__menu-list {
                max-height: 140px;
            }
        }

        .select__control {
            border-color: $off-white;

            &.select__control--is-focused,
            &:active,
            &:focus {
                border-color: $blue;
            }
        }
    }

    .checkbox-container {
        .checkbox-display {
            border-color: $off-white;

            &.active {
                border-color: $blue;

                .icon {
                    opacity: 1;
                }
            }
        }

        .checkbox-label {
            @include font-size(14);
        }
    }

    .message-preview {
        margin-top: 10px;

        .label {
            @include font-size(14);
            color: $base-text-on-black;
            margin: 0;
        }

        .message {
            @include font-size(14);
            @include line-height(18);
            border-radius: $border-radius;
            padding: 15px $base-padding-half;
            border-bottom-left-radius: 0;
            background-color: $light-blue;
            color: $black;
            margin: 0;
            margin-top: 7px;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: 10px;
    }
}
