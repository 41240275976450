.whats-next-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $base-padding-half;
    width: calc(100% - (#{$base-grid-padding} * 2));
    max-width: 680px;
    margin: $base-grid-padding;

    .header {
        grid-column: span 2;
        display: flex;
        flex-direction: column;

        .heading {
            margin: 0;
            text-transform: uppercase;
            font-weight: $weight-black;
            @include font-size(18);
            @include line-height(24);
        }

        .subheading {
            margin: 0;
            @include font-size(14);
            @include line-height(18);
        }

        .list {
            margin: 0;
            margin-top: $base-padding-half;
            padding-left: 17px;
            .list-item {
                @include font-size(16);
                @include line-height(24);
                margin-bottom: 20px;
            }
        }
    }

    @import "../whats-next-card.scss";

    .whats-next-card {
        .thumbnail {
            height: 175px;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        gap: 10px;
        width: calc(100% - (#{$base-padding-half} * 2));
        margin: $base-padding-half;

        .form-header {
            .form-heading {
                @include font-size(16);
                @include line-height(20);
            }
        }

        .whats-next-card {
            .thumbnail {
                aspect-ratio: 16/9;

                .icon {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .header {
            grid-column: span 1;
        }

        .whats-next-card {
            .thumbnail {
                aspect-ratio: unset;
                height: 120px;
            }
        }
    }
}
