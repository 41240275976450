.player-information-form {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $base-padding-half;
    width: calc(100% - (#{$base-grid-padding} * 2));
    max-width: 980px;
    margin: $base-grid-padding;
    margin-top: $base-padding-half;

    @import "../player-profile-edit/academic-interests.scss";
    @import "../player-profile-edit/schools-of-interest.scss";

    &.hidden {
        display: none;
    }

    .span-three {
        grid-column: span 3;
    }

    .academic-interests {
        @extend .span-three;
    }
    .schools-of-interest {
        @extend .span-three;
    }

    .profile-incomplete-container {
        @extend .span-three;
        margin-top: 10px;
    }

    .required-formatter {
        top: 15px;
    }

    .profile-image {
        @extend .span-three;
        display: flex;
    }

    .committed-container {
        @extend .span-three;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $base-padding-half;
    }

    .checkbox-container {
        @extend .span-three;
    }

    .international-container {
        @extend .span-three;
        display: grid;
        gap: $base-padding-half;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .buttons-container {
        @extend .span-three;
        display: flex;

        .button {
            width: 220px;
            height: 50px;
            @include font-size(16);
            &:first-child {
                margin-left: auto;
                margin-right: 20px;
            }
        }
    }

    .form-header {
        @extend .span-three;
        display: flex;
        flex-direction: column;

        .form-heading {
            margin: 0;
            margin-top: 10px;
            text-transform: uppercase;
            font-weight: $weight-black;
            @include font-size(18);
            @include line-height(24);
        }

        .form-subheading {
            margin: 0;
            @include font-size(14);
            @include line-height(18);

            .link {
                text-decoration: none;
                color: $blue;
                @include transition;

                &:hover {
                    color: $blue-hover;
                    text-decoration: underline;
                }
            }
        }
    }

    .biography {
        @extend .span-three;
        .textarea {
            min-height: 90px;
            @include font-size(14);
        }
    }

    .input-field-container {
        .input-field {
            @include font-size(14);
        }
        .required {
            @extend .required-formatter;
        }
    }

    .date-picker-container {
        .date-picker {
            @include font-size(14);
        }
        .required {
            @extend .required-formatter;
        }
    }

    .select-container {
        // z-index: 1;
        .select__placeholder {
            @include font-size(14);
            font-weight: $weight-regular;
        }
        .select__single-value,
        .select__input-container {
            @include font-size(14);
        }

        .select__menu {
            z-index: 2;
        }
    }

    .tag-select-container {
        @extend .span-three;
    }

    .terms-and-service-checkoff {
        @extend .span-three;
    }

    .terms-and-service-error {
        @extend .span-three;
        @include font-size(14);
        color: $red;
        margin: 0;
    }

    @media screen and (max-width: $small-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin: $base-padding-half;
        gap: 15px;
        margin-top: 10px;
        width: calc(100% - (#{$base-padding-half} * 2));

        .span-three {
            grid-column: span 2;
        }

        .required-formatter {
            top: 10px;
        }

        .buttons-container {
            .button {
                width: calc(50% - 10px);
                height: 50px;
                @include font-size(16);
                &:first-child {
                    margin-left: auto;
                    margin-right: 20px;
                }
            }
        }

        .form-header {
            .form-heading {
                @include font-size(16);
                @include line-height(20);
            }
            .form-subheading {
                @include font-size(12);
                @include line-height(16);
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .span-three {
            grid-column: span 1;
        }

        .international-container {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .buttons-container {
            flex-direction: column;
            .button {
                width: 100%;
                height: 40px;
                @include font-size(14);
                &:first-child {
                    margin: 0;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
