.scouting-reports {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $base-grid-padding;
    padding: $base-grid-padding;

    @import "./scouting-report-card.scss";

    @media screen and (max-width: $large-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        gap: $base-padding-half;
    }
}
