&.socials {
    width: 30%;
    padding: 0 80px;

    .info {
        margin-bottom: 20px;
        @include font-size(15);
        @include line-height(15);
    }

    .socials-container {
        border-top: $border-thickness solid #35353a;
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        padding-top: 20px;

        .social-link {
            display: flex;
            align-items: center;
            @include transition;

            .icon {
                width: 24px;
                height: 24px;
                fill: $white;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }
    @media screen and (max-width: $large-breakpoint) {
        width: 35%;
    }

    @media screen and (max-width: $medium-breakpoint) {
        width: 50%;
        padding-left: 40px;
        padding-right: 0;
    }

    @media screen and (max-width: $small-breakpoint) {
        width: 100%;
        padding: 0;
        margin-top: 40px;
    }
}
