.media-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .video {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        border-radius: $border-radius;
        overflow: hidden;

        .video-player {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .gallery {
        display: grid;
        gap: $base-padding-half;
        margin-top: $base-padding-half;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        .gallery-image-wrapper {
            padding-bottom: 66.67%;
            border-radius: $border-radius;
            position: relative;
            overflow: hidden;

            .gallery-image {
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;

                img {
                    border-radius: $border-radius;
                }
            }
        }

        @media screen and (max-width: $xmedium-breakpoint) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: $medium-breakpoint) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @media screen and (max-width: $small-breakpoint) {
            gap: 10px;
            margin-top: 10px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
