.cta-card-skeleton {
    border-radius: $border-radius;
    height: 125px;
}

.cta-card {
    display: flex;
    background-color: $black;
    border-radius: $border-radius;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: $base-grid-padding;

    &.college-search-engine {
        background-image: url("/images/whats-next/college-search-engine-card.jpg");
    }

    &.player-search-engine {
        background-image: url("/images/whats-next/player-search-engine-card.jpg");
    }

    &.membership {
        background-image: url("/images/whats-next/membership-card.jpg");
    }

    .title-container {
        display: flex;
        flex-direction: column;

        .title {
            max-width: 450px;
            color: $white;
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-weight: $weight-black;
        }

        .description {
            max-width: 450px;
            color: $base-text-on-dark-tone;
            @include font-size(14);
            @include line-height(18);
            margin: 0;
        }
    }

    .button {
        flex-shrink: 0;
        @include font-size(16);
        width: 200px;
        height: 50px;
        border-radius: 25px;
        margin-left: auto;
    }

    @media screen and (max-width: $small-breakpoint) {
        flex-direction: column;
        justify-content: center;
        padding: $base-padding-half;
        .title-container {
            .title {
                text-align: center;
                @include font-size(16);
                @include line-height(20);
            }

            .description {
                text-align: center;
                @include font-size(12);
                @include line-height(16);
            }
        }

        .button {
            @include font-size(14);
            width: 180px;
            height: 40px;
            border-radius: 20px;
            margin: 0 auto;
            margin-top: 10px;
        }
    }
}
