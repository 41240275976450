.date-picker-container {
    max-width: 100%;
    position: relative;
    label {
        display: none;
    }

    .date-picker {
        max-width: 100%;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        border: $border-thickness solid transparent;
        background-color: $white;
        padding: 0 25px;
        color: $base-dark-tone;
        outline: 0;
        font-weight: $weight-medium;
        font-family: $base-font-family;
        @include transition;
        @include font-size(18);

        &::placeholder {
            font-weight: $weight-regular;
            color: $base-text-on-white;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border-color: $blue;
            -webkit-text-fill-color: $base-dark-tone;
            -webkit-box-shadow: 0 0 0px 1000px $light-blue inset;
        }

        &:active,
        &:focus {
            border-color: $blue;
        }

        &.has-required {
            padding-left: 40px;
        }
    }
    &.error {
        .required {
            color: $red;
        }
    }

    @import './date-picker-popup.scss';

    .required {
        color: $blue;
        @include font-size(24);
        font-weight: $weight-bold;
        position: absolute;
        left: 20px;
        top: 16px;
    }
    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }
    @media screen and (max-width: $small-breakpoint) {
        .date-picker {
            height: 40px;
        }
    }
}
