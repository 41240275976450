.event-landing-card-white {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    text-decoration: none;
    background-color: $white;
    color: $black;
    padding: 30px;
    margin-top: -150px;
    @include baseShadowOnWhite;

    .event-landing-card-header {
        @include font-size(24);
        @include line-height(24);
        font-family: $base-font-family;
        text-align: center;
        text-transform: uppercase;
        margin: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(20);
            @include line-height(20);
        }
    }

    .event-landing-card-header-date {
        @include font-size(24);
        @include line-height(24);
        font-family: $base-font-family;
        text-align: center;
        text-transform: uppercase;
        font-weight: $weight-medium;
        margin: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(20);
            @include line-height(20);
        }
    }

    .event-landing-card-description {
        @include font-size(18);
        @include line-height(30);
        font-family: $base-font-family;
        text-align: left;
        font-weight: $weight-regular;
        margin-top: 20px;
        margin-block-end: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(16);
            @include line-height(18);
        }
    }

    .event-landing-card-description-address {
        @include font-size(18);
        font-family: $base-font-family;
        text-align: center;
        font-weight: $weight-regular;
        margin-top: 0px;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    .event-landing-card-description-games {
        @include font-size(18);
        font-family: $base-font-family;
        text-align: center;
        font-weight: $weight-regular;
        margin-top: 5px;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    .event-landing-card-description-2 {
        @include font-size(20);
        @include line-height(25);
        font-family: $base-font-family;
        text-align: left;
        font-weight: $weight-regular;
        font-style: italic;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    .event-landing-card-link {
        @include font-size(18);
        font-family: $base-font-family;
        text-align: left;
        font-weight: $weight-regular;
        margin-top: 15px;
        color: white;
        margin-block-end: 0;

        &:hover {
            color: $blue;
        }

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    .landing-list-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $base-grid-padding;
        grid-auto-rows: min-content;
        justify-items: start;
        margin: $base-padding 0;

        .landing-list-item-header {
            @include headerFont;
            @include font-size(30);
            margin: 0;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(25);
            }
        }

        .click-here-guide {
            color: $blue;

            &:hover {
                color: $base-dark-tone;
            }
        }

        li {
            @include font-size(18);
            margin-bottom: 10px;

            a {
                color: $base-dark-tone;

                &:hover {
                    color: $blue;
                }
            }
        }

        @media screen and (max-width: $medium-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        @media screen and (max-width: $small-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    @media screen and (max-width: $large-breakpoint) {
        margin-top: -100px;
    }

    @media screen and (max-width: $small-breakpoint) {
        margin-top: -10px;
    }

    .landing-center-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $base-padding auto;
        text-decoration: none;

        .landing-center-button-tag {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }

        .landing-bottom-description {
            @include font-size(18);
            @include line-height(20);
            font-family: $base-font-family;
            font-weight: $weight-bold;
            color: red;
            margin-block-end: 0;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(16);
                @include line-height(18);
            }
        }

        .landing-info {
            margin-bottom: 20px;
        }
    }
}
