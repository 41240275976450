.wreath {
    .cls-1,
    .cls-4 {
        fill: #141235;
    }

    .cls-2 {
        fill: #fff;
    }

    .cls-3 {
        clip-path: url(#clip-path);
    }

    .cls-4 {
        font-size: 32px;
        font-family: WinnerSans-CompExtraBold, Winner Sans;
        font-weight: 800;
    }
}

.refrag-logo {
    .cls-1 {
        fill: #dc0023;
    }
}

.augment-logo {
    .st0 {
        fill: #c80a19;
    }
}

.ufea-logo-light {
    .cls-1 {
        fill: #00e1ff;
    }
    .cls-2 {
        fill: #001f39;
    }
    .cls-3 {
        fill: #fff;
    }
    .cls-4 {
        fill: #b7b7b7;
    }
    .cls-5 {
        mix-blend-mode: multiply;
    }
    .cls-6 {
        isolation: isolate;
    }
}
