.page-list-header {
    width: 100%;
    background-color: $white;
    padding: $base-padding 0;
    @include baseShadowOnWhite;

    &.news-page-list-header {
        margin-top: $navigation-height;
        background-color: $base-dark-tone;
        color: $white;
    }

    @import "../players/players-page-list-header.scss";
    @import "../colleges/colleges-page-list-header.scss";

    .page-list-title {
        @include headerFont;
        @include font-size(42);
        line-height: 50px;
        margin: 0;
        flex-shrink: 0;
        margin-right: 20px;
    }

    .title-container {
        display: flex;
        flex-direction: row;
    }

    .search-container {
        width: 100%;
        max-width: 560px;
        margin-left: auto;
        display: flex;

        .search-bar {
            margin-left: auto;
        }

        .filter-button {
            margin-right: 10px;
            flex-shrink: 0;
        }
    }

    .outer-search-button {
        margin-top: $base-padding-half;
        width: 180px;
        height: 50px;
        border-radius: 25px;
        @include font-size(16);
    }

    @import "./filter.scss";

    @media screen and (max-width: $medium-breakpoint) {
        .title-container {
            flex-wrap: wrap;
        }

        .page-list-title {
            width: 100%;
            @include font-size(32);
            @include line-height(32);
        }

        .search-container {
            margin-top: 20px;
            max-width: 100%;

            .search-bar {
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .page-list-title {
            width: 100%;
            @include font-size(28);
            @include line-height(28);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        &.news-page-list-header {
            margin-top: $mobile-navigation-height;
        }
    }
}
