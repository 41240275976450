.event-registration-form-container {
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    position: relative;
    top: -80px;
    background-color: $off-white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    margin-bottom: calc(#{$base-vertical-padding} - 80px);

    .left-panel {
        width: calc(50% - #{$base-padding});
        display: grid;
        gap: $base-padding-half;
        margin-right: $base-padding;

        @import './event-ticket.scss';
        @import './event-referral.scss';

        .ticket {
            cursor: default;
        }
    }
    // for document sign offs usually
    .error-label {
        @include font-size(14);
        color: $red;
        margin: 0;
        margin-top: 10px;
    }
    .right-panel {
        width: calc(50% - #{$base-padding});
        margin-left: $base-padding;
        display: flex;
        flex-direction: column;

        .button {
            width: 100%;
            margin-top: $base-padding-half;
        }

        @import './event-order-subtotal.scss';

        .stripe-payment {
            margin-top: 20px;
        }
    }

    .title-container {
        display: flex;
        flex-direction: column;
        .title {
            @include headerFont;
            @include font-size(32);
            @include line-height(32);
            margin: 0;
        }

        .subtitle {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            margin-top: 5px;
        }
    }

    .content-container {
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
        display: grid;
        margin-top: $base-grid-padding;
        gap: 10px;
    }

    @media screen and (max-width: $large-breakpoint) {
        margin-bottom: calc(#{$large-vertical-padding} - 80px);
    }

    @media screen and (max-width: $medium-breakpoint) {
        max-width: 100%;
        margin-bottom: calc(#{$medium-vertical-padding} - #{$border-radius});
        top: -$border-radius;
    }
    @media screen and (max-width: $small-breakpoint) {
        margin-bottom: calc(#{$small-vertical-padding} - #{$border-radius});
        padding: $base-padding-half;

        flex-direction: column;

        .left-panel,
        .right-panel {
            width: 100%;
            margin: 0;
        }

        .right-panel {
            margin-top: 20px;
        }
    }
}
