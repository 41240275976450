.mobile-dashboard-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 300px;
    height: 100%;
    background-color: $white;
    padding-top: 0;
    z-index: 10001;
    display: none;
    flex-direction: column;
    @include transition;
    transform: translateX(-100%);

    .brand-container {
        padding: 0 $base-padding-half;
        background-color: $base-dark-tone;
        height: $navigation-height;
        display: flex;
        align-items: center;

        .brand {
            .spin-logo {
                fill: $white;
            }
            .spin-icon {
                display: none;
            }
        }
    }

    .links {
        height: calc(100vh - #{$navigation-height});
        display: flex;
        flex-direction: column;
        padding: $base-padding-half;
        overflow-y: auto;

        .separator {
            margin: $base-padding-half 0;
            background-color: $off-white;
            height: $border-thickness;
        }

        .link {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            @include font-size(14);
            text-decoration: none;
            font-weight: $weight-black;
            color: $base-dark-tone;
            height: 50px;
            @include transition;
            padding: $base-padding-half;
            border-radius: $border-radius;
            margin-bottom: 5px;

            .icon {
                @include transition;
                margin-right: 10px;
                fill: $base-dark-tone;
                width: 20px;
                height: 20px;
            }

            .messages {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                min-width: 35px;
                flex-shrink: 0;
                background-color: $light-blue;
                border-radius: 10px;
                margin-left: auto;
                font-weight: $weight-bold;
                text-align: center;
                @include transition;
            }

            &:hover,
            &.active {
                background-color: $light-blue;

                .messages {
                    background-color: $blue;
                    color: $white;
                }
            }
        }
    }

    .minimize-button {
        @include button;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-color: $base-dark-tone;
        margin-left: auto;
        .icon {
            fill: $white;
        }
    }

    &.open {
        transform: translateX(0);
    }

    @media screen and (max-width: $small-breakpoint) {
        display: flex;
    }
    @media screen and (max-width: $mobile-breakpoint) {
        max-width: 280px;
    }
}
