.player-my-profile-container {
    padding: $base-grid-padding;
    display: flex;
    flex-direction: column;

    .profile-container {
        display: flex;
        @import "../../players/profile/player-left-side.scss";
        @import "../../players/profile/player-right-side.scss";

        @import "../../players/profile/committed-to-college.scss";
        @import "../../players/profile/player-bio.scss";
        @import "../../profiles/profile-card.scss";

        @media screen and (max-width: $large-breakpoint) {
            .right-side-profile {
                width: 360px;

                .primary-game-card {
                    .right-container {
                        .primary-game-icon {
                            width: 40px;
                            height: 40px;

                            .icon {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $xmedium-breakpoint) {
            flex-wrap: wrap;
            .right-side-profile {
                margin-top: $base-grid-padding;
                padding-left: 0;
                width: 100%;

                .primary-game-card {
                    .right-container {
                        .primary-game-icon {
                            width: 66px;
                            height: 66px;

                            .icon {
                                width: 34px;
                                height: 34px;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            flex-wrap: wrap;
            .left-side-profile {
                gap: $base-padding-half;
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            .right-side-profile {
                margin-top: $base-padding-half;
                gap: $base-padding-half;
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            flex-wrap: wrap;
            .right-side-profile {
                .primary-game-card {
                    .right-container {
                        .primary-game-icon {
                            width: 40px;
                            height: 40px;

                            .icon {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .large-button {
        margin-top: $base-grid-padding;
        margin-left: auto;
    }
    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        .large-button {
            margin-right: auto;
            @include font-size(14px);
            @include line-height(14px);
            height: 50px;
            width: 200px;
        }
    }
}
