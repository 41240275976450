.college-card-content {
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .college-icon {
        @include image-wrapper(130px, 130px, 100%);
    }

    .program-name {
        @include headerFont;
        text-align: center;
        @include font-size(24);
        @include line-height(24);
        margin: 0;
        margin-top: 10px;
    }

    .program-location {
        @include font-size(14);
        @include line-height(14);
        margin: 0;
        margin-top: 10px;
        color: $base-text-on-white;
    }

    .games-played {
        margin-top: 20px;
        margin-bottom: 5px;
        text-align: center;
        text-transform: uppercase;
        @include font-size(13);
        @include line-height(13);
        color: $base-text-on-white;
    }

    .games-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .icon-container {
            margin: 5px;
        }
    }

    .button {
        width: 180px;
        margin-top: 20px;
    }

    .bottom-container {
        flex-direction: column;
        margin-top: auto;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: $small-breakpoint) {
        .college-icon {
            width: 100px;
            height: 100px;
        }
        .program-name {
            @include font-size(20);
            @include line-height(20);
        }
    }
}
