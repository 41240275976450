.recruiting-hub {
    display: flex;
    flex-direction: column;
    padding: $base-grid-padding;
    height: calc(100vh - $navigation-height);

    .hub-cta-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $base-grid-padding;
        margin-bottom: $base-grid-padding;

        @import "./dashboard-cta-card.scss";

        @media screen and (max-width: $small-breakpoint) {
            margin-bottom: $base-padding-half;
        }
    }

    .hub-content-container {
        display: flex;
        width: 100%;

        .left {
            height: 100%;
            flex-shrink: 0;
            flex: 530;
            display: flex;
            flex-direction: column;
            @import "./statistics-card.scss";
            @import "./notifications-card.scss";

            @media screen and (max-width: $xlarge-breakpoint) {
                width: 350px;
            }
        }

        .right {
            width: 100%;
            display: flex;
            flex: 1000;
            height: 100%;
            flex-direction: column;
            margin-left: $base-grid-padding;
            @import "./latest-news-card.scss";
            @import "./upcoming-events-card.scss";
        }

        @import "./hub-skeletons.scss";
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        flex-direction: column;
        .hub-cta-container {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .hub-content-container {
            flex-direction: column;

            .left {
                width: 100%;
            }
            .right {
                margin: 0;
                margin-top: $base-grid-padding;
            }
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;

        .hub-content-container {
            .right {
                margin: 0;
                margin-top: $base-padding-half;
            }
        }
    }
}
