.no-cal-card {
    width: 100%;
    padding: $base-padding;
    background-color: $base-dark-tone;
    margin: $base-vertical-padding 0;
    border-radius: $border-radius;

    p {
        color: $white;
        @include font-size(25);
        font-weight: 600;
    }

    @media screen and (max-width: $medium-breakpoint) {
        padding: $base-padding-half;
        margin: $small-vertical-padding 0;

        p {
            @include font-size(20);
        }
    }
}
