.card-bottom-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: $base-dark-tone;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    .info-display {
        display: flex;
        flex-direction: column;
        width: 60px;
        margin-right: 10px;
    }

    .display-label {
        text-align: center;
        margin: 0;
        color: $base-text-on-dark-tone;
        text-transform: uppercase;
        @include font-size(10);
        @include line-height(10);
        margin-bottom: 3px;
    }

    .display {
        width: 100%;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white;
        font-weight: $weight-black;
        @include font-size(14);
        @include line-height(14);
        border-radius: $border-radius-small;
    }

    .gpa {
        background-color: $green;
    }
    .year {
        background-color: $purple;
    }

    .button {
        margin-left: auto;
        width: 160px;
        height: 36px;
        border-radius: 18px;
        @include font-size(14);
    }
}
