.wrong-user-section {
    display: flex;
    flex-direction: column;
    background-color: $light-red;
    border: 2px solid $red;
    border-radius: $border-radius;
    margin: 0 auto;
    margin-top: $base-padding-half;
    padding: $base-padding-half;

    .line-one {
        @include headerFont;
        @include font-size(24);
        @include line-height(24);
        text-align: center;
        margin: 0;
    }

    .line-two {
        @include font-size(16);
        @include line-height(24);
        margin: 0;
        padding-top: 5px;
        text-align: center;
    }

    @media screen and (max-width: $small-breakpoint) {
        .line-one {
            @include font-size(20);
            @include line-height(20);
        }

        .line-two {
            @include font-size(14);
            @include line-height(20);
        }
    }
}
