.my-players-games {
    display: flex;
    flex-direction: column;
    padding: $base-grid-padding;

    .my-player {
        display: flex;
        flex-direction: column;
        margin-bottom: $base-grid-padding;

        &:last-child {
            margin-bottom: 0;
        }

        .player-container {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .image-wrapper {
                @include image-wrapper(40px, 40px, 100%);
            }

            .username {
                @include headerFont;
                @include font-size(28);
                @include line-height(28);
                margin: 0;
                margin-left: 10px;
            }
        }

        .my-games {
            margin: 0;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;

        .my-player {
            margin-bottom: $base-padding-half;

            .player-container {
                .image-wrapper {
                    width: 36px;
                    height: 36px;
                }

                .username {
                    @include headerFont;
                    @include font-size(24);
                    @include line-height(24);
                    margin: 0;
                    margin-left: 10px;
                }
            }
        }
    }
}
