.feature-group-mobile {
    .feature-list-item {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        background-color: transparent;
        border-bottom: $border-thickness solid $off-white;
        padding: 15px;
        align-items: center;
        margin: 0;

        &:last-child {
            border-bottom: 0;
        }

        &.header {
            background-color: $light-blue;
            border: 0;
            border-radius: $border-radius;
            margin: 0 15px;
            padding: 0 15px;
            align-self: center;
            min-height: 60px;
            display: flex;
            align-items: center;

            .item-info-container {
                padding: 0;
                .item-label {
                    font-weight: $weight-black;
                }
            }
        }

        .item-info-container {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin: 0;
            padding-bottom: 10px;

            .item-label {
                @include font-size(18);
                @include line-height(18);
                margin: 0;
                font-weight: $weight-bold;
                text-transform: uppercase;
            }
            .item-description {
                @include font-size(12);
                @include line-height(16);
                margin: 0;
                margin-top: 8px;
            }
        }

        .checkmarks {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin: 0 auto;
            align-items: center;
            width: 100%;
            gap: 5px;

            &.has-four {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            .checkmark-info-container {
                display: flex;
                align-items: center;

                .descriptor {
                    @include headerFont;
                    @include font-size(24);
                    @include line-height(30);
                    margin: 0;
                    margin-right: 10px;
                }

                .checkmark-container {
                    .icon {
                        display: block;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        border: $border-thickness solid $base-dark-tone;
                        background-color: $off-white;
                        fill: $base-dark-tone;
                        padding: 5px;
                    }

                    &.tier-one {
                        .icon {
                            background-color: $light-blue;
                        }
                    }

                    &.tier-two {
                        .icon {
                            background-color: $light-green;
                        }
                    }

                    &.tier-three {
                        .icon {
                            background-color: $light-purple;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            .checkmarks {
                grid-template-columns: repeat(1, minmax(0, 1fr)) !important;

                .checkmark-info-container {
                    display: grid;
                    gap: 10px;
                    grid-template-columns: repeat(2, minmax(0, 1fr));

                    .checkmark-container {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $small-breakpoint) {
        display: none;
    }

    &.college {
        @media screen and (min-width: $large-breakpoint) {
            display: none !important;
        }

        @media screen and (min-width: $small-breakpoint) {
            display: unset;
        }

        .feature-list-item {
            @media screen and (max-width: $small-breakpoint) {
                .checkmarks {
                    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;

                    .checkmark-info-container {
                        display: grid;
                        gap: 10px;
                        grid-template-columns: repeat(2, minmax(0, 1fr));

                        .checkmark-container {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}
