.parent-my-events {
    padding: $base-grid-padding;
    display: flex;
    height: 100%;

    @import "../my-events/my-events-card.scss";
    @import "../my-events/my-upcoming-events.scss";
    @import "../my-events/my-events-skeletons.scss";

    .my-players-events {
        width: 100%;
        height: 100%;
        flex: 1170;
        display: grid;
        gap: $base-grid-padding;
    }

    @media screen and (max-width: $xmedium-breakpoint) {
    }

    @media screen and (max-width: $medium-breakpoint) {
        flex-direction: column;
        height: auto;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
    }
}
