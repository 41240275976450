.college-profile-info {
    display: flex;
    align-items: center;

    .left-panel {
        display: flex;
        flex-direction: column;
        align-self: flex-start;

        .college-logo-info-container {
            display: flex;
            align-items: center;

            .college-profile-image {
                @include image-wrapper(100px, 100px, 100%);
                flex-shrink: 0;
            }

            .bio {
                width: calc(100% - 100px);
                display: flex;
                flex-direction: column;
                padding-left: 20px;

                .college-name {
                    word-break: break-all;
                    @include headerFont;
                    margin: 0;
                    @include font-size(32);
                    @include line-height(32);
                }

                .college-location {
                    margin: 0;
                    margin-top: 5px;
                    color: $base-text-on-white;
                    @include font-size(14);
                    @include line-height(14);
                    font-weight: $weight-medium;
                }
            }
        }
    }

    .right-panel {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        align-self: flex-start;
    }

    @import "./social-controls.scss";
    @import "./college-tuition.scss";

    .socials-container {
        display: flex;
        margin-top: 10px;
        align-items: center;

        .icon {
            @include transition;
            fill: $base-dark-tone;
            margin-right: 10px;
            &:hover {
                opacity: 0.6;
            }
        }
    }

    @media screen and (max-width: $xmedium-breakpoint) {
        flex-direction: column;
        .left-panel {
            width: 100%;
        }
        .right-panel {
            width: 100%;
            margin-left: 0;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .left-panel {
            .college-logo-info-container {
                flex-direction: column;
                .bio {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    padding-left: 0px;
                    margin-top: 10px;

                    .college-location,
                    .college-name {
                        text-align: center;
                    }
                }
            }
        }
    }
}
