.journey-dashboard {
    padding: $base-padding 0;
    .container {
        display: flex;
        flex-wrap: wrap;
        @import "./journey-dashboard-card.scss";
        @import "./journey-features.scss";
        @import "./journey-steps-card.scss";

        @media screen and (max-width: $xmedium-breakpoint) {
            flex-direction: column;

            .journey-dashboard-card {
                width: 100%;
            }

            .dashboard-features {
                padding-left: 0;
                margin-top: $base-grid-padding;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }

        @media screen and (max-width: $medium-breakpoint) {
            .dashboard-features {
                padding-left: 0;
                margin-top: $base-grid-padding;
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
        @media screen and (max-width: $small-breakpoint) {
            .dashboard-features {
                padding-left: 0;
                margin-top: $base-padding-half;
                gap: $base-padding-half;
            }
            .journey-steps-card {
                margin-top: $base-padding-half;
            }
        }
    }
}
