.popup-header {
    display: flex;
    // flex-direction: column;
    align-items: center;
    min-height: 60px;
    padding: 15px $base-padding-half;
    border-bottom: $border-thickness solid $off-white;

    .popup-title-container {
        display: flex;
        flex-direction: column;
        padding-right: 10px;

        .popup-title {
            @include headerFont;
            @include font-size(28);
            @include line-height(28);
            margin: 0;
            text-transform: uppercase;

            &.promo {
                @include font-size(40);
                @include line-height(40);

                @media screen and (max-width: $small-breakpoint) {
                    @include font-size(34);
                    @include line-height(34);
                    text-align: center;
                }

                @media screen and (max-width: $mobile-breakpoint) {
                    @include font-size(30);
                    @include line-height(30);
                }
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(24);
                @include line-height(24);
            }

            @media screen and (max-width: $mobile-breakpoint) {
                @include font-size(22);
                @include line-height(22);
            }
        }

        .popup-subtitle {
            @include font-size(14);
            @include line-height(18);
            margin: 0;
            margin-right: 10px;

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(12);
                @include line-height(16);
            }
        }
    }

    .close-button {
        @include button;
        flex-shrink: 0;
        margin-left: auto;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: transparent;
        border: $border-thickness solid $off-white;

        .icon {
            fill: $base-dark-tone;
        }

        &:hover {
            background-color: $blue;
            border-color: $blue;

            .icon {
                fill: $white;
            }
        }

        &.promo {
            @media screen and (max-width: $small-breakpoint) {
                display: none;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: 8px 10px;
    }
}
