.activations-partners {
    display: flex;
    flex-direction: column;
    margin-top: $navigation-height;
    width: 100%;
    background-color: $off-white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('/images/esports-fair-bg.jpg');
    align-items: center;
    justify-content: center;
    padding: 80px 0;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;

        .title {
            @include headerFont;
            @include font-size(50);
            @include line-height(50);
            margin: 0;
            color: $base-dark-tone;
        }

        .icons-group {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: $base-padding;

            .partner-icon {
                margin-right: $base-padding;
                padding: $base-padding-half;

                &:last-of-type {
                    margin-right: 0;
                }

                .icon {
                    width: auto;
                    height: 70px;
                }
                .spin {
                    fill: $base-dark-tone;
                    width: auto;
                    height: 70px;
                }
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .container {
            .title {
                @include font-size(40);
                @include line-height(40);
            }

            .sub-title {
                @include font-size(22);
                @include line-height(22);
            }

            .icons-group {
                .partner-icon {
                    .icon,
                    .spin {
                        width: auto;
                        height: 50px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .container {
            .title {
                @include headerFont;
                @include font-size(30);
                @include line-height(30);
            }

            .icons-group {
                margin-top: $base-padding-half;

                .partner-icon {
                    .icon,
                    .spin {
                        width: auto;
                        height: 30px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        margin-top: $mobile-navigation-height;
        padding: $mobile-vertical-padding 0;
        .container {
            .title {
                @include headerFont;
                @include font-size(40);
                @include line-height(40);
            }

            .sub-title {
                @include font-size(16);
                @include line-height(16);
            }

            .icons-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .partner-icon {
                    margin-top: $base-padding;
                    margin-right: 0;
                    padding: 0;

                    .icon,
                    .spin {
                        width: 150px;
                        height: auto;
                    }
                }
            }
        }
    }
}
