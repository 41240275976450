.scholar-main {
    @include defaultContainerPadding;

    .container {
        &.slim {
            max-width: $slim-site-width;
        }

        .scholar-description {
            display: flex;
            flex-direction: column;
            margin: 0;
            color: $base-dark-tone;

            .scholar-tagline {
                @include headerFont;
                @include font-size(50);
                @include line-height(50);
                text-align: left;
                margin: 0;
                padding-bottom: $base-padding;

                &.green {
                    color: $green;
                    padding-bottom: 0;
                }

                @media screen and (max-width: $medium-breakpoint) {
                    @include font-size(40);
                    @include line-height(40);
                }

                @media screen and (max-width: $small-breakpoint) {
                    @include font-size(30);
                    @include line-height(30);
                    margin: 0;
                }

                // @media screen and (max-width: $mobile-breakpoint) {
                //     @include font-size(20);
                //     @include line-height(40);
                // }
            }

            .scholar-description {
                @include font-size(18);
                @include line-height(24);
                margin: 0;
                margin-bottom: $base-padding;

                @media screen and (max-width: $small-breakpoint) {
                    @include font-size(14);
                    @include line-height(20);
                }
            }
        }
    }
}
