.high-school-our-teams-card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    gap: $base-padding-half;
    @include baseShadowOnWhite;
    
    .title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
    }

    .our-teams-container {
        display: flex;
        flex-direction: column;
        gap: $base-padding-half;
        
        @import "./high-school-our-team-card.scss";
    }
}