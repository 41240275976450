.feature-header {
    display: grid;
    @include gridColumns(3);
    background-color: transparent;
    min-height: 60px;
    padding: 15px 0;
    align-items: center;
    margin: 0 $base-grid-padding;
    position: sticky;
    top: $navigation-height;
    background-color: $white;
    border-bottom: $border-thickness solid $off-white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.high-school {
        @include gridColumns(2);
    }

    .header-title {
        @include headerFont;
        @include font-size(28);
        @include line-height(28);
        text-align: center;

        &.left {
            text-align: left;
            
            @media screen and (max-width: $medium-breakpoint) {
                text-align: center;
            }
        }

        &.right {
            text-align: right;

            @media screen and (max-width: 1500px) {
                
                text-align: center;
            }
        }

        &.mobile {
            @media screen and (max-width: $small-breakpoint) {
                display: none;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        display: none;
    }

    

    &.college {
        grid-template-columns: 25% repeat(4, minmax(0, 1fr));
    
    
        @media screen and (max-width: $large-breakpoint) {
            display: none;
        }
    }
}

.feature-header-mobile {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    background-color: transparent;
    min-height: 60px;
    padding: 15px 0;
    align-items: center;
    margin: 0 auto;
    background-color: $white;
    border-bottom: $border-thickness solid $off-white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .header-title {
        @include headerFont;
        @include font-size(25);
        @include line-height(25);
        text-align: center;
    }

    @media screen and (min-width: $small-breakpoint) {
        display: none;
    }
}
