&.players-page-list-header {
    .filters {
        display: grid;
        gap: $base-grid-padding;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .game-filters {
            grid-column: span 2;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .label {
                width: 100%;
                text-transform: uppercase;
                font-weight: $weight-bold;
                margin: 0;
                margin-bottom: $base-padding-half;
            }
        }

        .filter-by-games,
        .filter-by-distance,
        .filter-by-gpa,
        .filter-by-graduating-class,
        .filter-by-state-sort,
        .sort-by {
            .checkbox-container {
                margin-bottom: $base-padding-half;
            }
        }

        .filter-by-gpa-distance,
        .filter-by-follows,
        .filter-by-state-sort {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: $base-grid-padding;
        }

        .checkbox-container {
            .checkbox-label {
                text-transform: uppercase;
                font-weight: $weight-bold;
            }
            .checkbox-display {
                border-color: $off-white;
                &.active {
                    border-color: $blue;
                }
            }
        }

        .field-of-study-heading {
            display: flex;
            margin-bottom: $base-padding-half;

            .generic-toggle-switch {
                margin-left: auto;
            }
        }

        .select-field-container {
            .select__control {
                border-color: $off-white;
                .select__placeholder {
                    @include font-size(14);
                    font-weight: $weight-regular;
                }
                .select__single-value,
                .select__input-container {
                    @include font-size(14);
                }
                &.select__control--is-focused,
                &:active,
                &:focus {
                    border-color: $blue;
                }

                .select__value-container {
                    .select__multi-value {
                        .select__multi-value__remove {
                            padding: 4px;
                        }
                    }
                }
            }

            .select__menu {
                z-index: 2;
            }
        }

        .number-slider-container {
            border-color: $off-white;
        }

        @media screen and (max-width: $medium-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            .game-filters {
                grid-column: span 1;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            gap: $base-padding-half;

            .filter-by-gpa-distance,
            .filter-by-follows,
            .filter-by-state-sort {
                gap: $base-padding-half;
            }

            .filter-by-games,
            .filter-by-distance,
            .filter-by-gpa,
            .filter-by-graduating-class,
            .filter-by-state-sort,
            .sort-by {
                .checkbox-container {
                    margin-bottom: 10px;
                }
            }

            .field-of-study-heading {
                margin-bottom: 10px;
            }

            .checkbox-container {
                .checkbox-label {
                    @include font-size(14);
                }
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            .filter-by-gpa-distance,
            .filter-by-follows,
            .filter-by-state-sort {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                // gap: 10px;
            }

            .field-of-study-heading {
                flex-direction: column;

                .generic-toggle-switch {
                    align-self: flex-start;
                    margin-top: 10px;
                    margin-left: 0;
                }
            }
        }
    }
}
