.messages-card {
    margin: $base-grid-padding;
    @include light-card;
    padding: 0;
    height: calc(100% - (#{$base-grid-padding} * 2));
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;

    @import "./inbox-container.scss";
    @import "./conversation-container.scss";

    .conversation-container {
        .conversation-controls {
            .conversation-navigation {
                .response-selector {
                    .content {
                        max-height: calc(100vh - (#{$navigation-height} * 2) - 30px - 60px - 60px - 15px - 80px);
                    }
                }
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        .inbox-container {
            width: 100%;
            flex: 1;

            &.has-conversation {
                display: none;
            }
        }

        .conversation-container {
            width: 100%;
            flex: 1;
            border-left: 0;
            display: none;

            .header-container {
                .back-button {
                    display: flex;
                }
            }

            &.has-conversation {
                display: flex;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        margin: $base-padding-half;
        height: calc(100% - (#{$base-padding-half} * 2));

        .conversation-container {
            .conversation-controls {
                .conversation-navigation {
                    .response-selector {
                        .content {
                            max-height: calc(
                                100vh - (#{$mobile-navigation-height} * 2) - 30px - 60px - 60px - 15px - 80px
                            );
                        }
                    }
                }
            }
        }
    }
}
