&.description {
    width: 35%;

    p {
        @include font-size(13);
        @include line-height(20);
    }

    .spin-logo {
        width: 269px;
        height: 70px;
        fill: $white;
    }

    .footer-description {
        margin-top: 30px;
    }

    .footer-slogan {
        margin-top: 30px;
        @include headerFont;
        @include font-size(24);
        @include line-height(24);
        color: $white;

        span {
            color: $blue;
        }
    }

    .link-container {
        margin-top: 30px;
        display: flex;

        .link {
            @include font-size(13);
            @include line-height(20);
            color: $base-text-on-black;
            text-decoration: none;
            margin: 0;
            margin-right: 20px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    @media screen and (max-width: $large-breakpoint) {
        width: 30%;
    }

    @media screen and (max-width: $medium-breakpoint) {
        width: 50%;
        padding-right: 40px;
    }

    @media screen and (max-width: $small-breakpoint) {
        width: 100%;
        padding: 0;
        margin-top: 40px;

        .spin-logo {
            width: 240px;
            height: 62px;
        }

        .link-container {
            flex-wrap: wrap;
            .link {
                width: 100%;
            }
        }
    }
}
