.event-landing-card-article {
    display: block;
    border-radius: $border-radius;
    text-decoration: none;
    background-color: $base-dark-tone;
    color: $white;
    // margin-bottom: $base-padding;
    padding: 10px 0;
    @include baseShadowOnWhite;

    .event-landing-card-article-header {
        @include font-size(24);
        @include line-height(24);
        font-family: $base-font-family;
        text-align: center;
        text-transform: uppercase;
        font-weight: $weight-black;
    }

    .container-article {
        display: flex;
        justify-content: center;
    
        .article-landing {
            border-radius: $border-radius;
        }
    
    }

    .event-landing-card-article-description {
        @include font-size(18);
        font-family: $base-font-family;
        color:  $darker-off-white;
        text-align: center;
        font-style: italic;

    }

    .article-landing-container {
        max-width: 75%;
        margin: 0 auto;
    }

    @import "../news/article-card.scss";
    
}
