.event-landing-card-colleges {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    text-decoration: none;
    background-color: $white;
    color: $black;
    padding: 20px 20px;
    margin-top: 10px;
    @include baseShadowOnWhite;

    .event-landing-card-header {
        @include font-size(60);
        @include line-height(60);
        @include headerFont;
        text-align: left;
        text-transform: uppercase;
        font-weight: $weight-black;
        margin: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(40);
            @include line-height(40);
        }
    }

    .event-landing-card-description {
        @include font-size(20);
        font-family: $base-font-family;
        text-align: center;
        font-weight: $weight-bold;
        margin-top: 20px;
        margin-block-end: 0;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(25);
            @include line-height(25);
        }
    }

    .event-landing-card-description-2 {
        @include font-size(20);
        @include line-height(25);
        font-family: $base-font-family;
        text-align: left;
        font-weight: $weight-regular;
        font-style: italic;

        @media screen and (max-width: $small-breakpoint) {
            @include font-size(14);
            @include line-height(14);
        }
    }

    .landing-colleges-container {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: $base-padding-half;
        grid-auto-rows: min-content;
        justify-items: stretch;
        color: $base-dark-tone;
        margin-top: $base-padding-half;
        margin-bottom: $base-padding;

        @media screen and (max-width: $large-breakpoint) {
            margin: $base-padding auto;
            grid-template-columns: repeat(3, minmax(0, 1fr));

            .show-more {
                grid-column: span 2;
            }
        }

        @media screen and (max-width: $medium-breakpoint) {
            margin: $base-padding auto;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            .show-more {
                grid-column: span 2;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            grid-auto-rows: unset;
            margin: $base-padding-half 0;
            gap: $base-padding-half;
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        @media screen and (max-width: $mobile-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .event-landing-card-link {
            @include font-size(18);
            font-family: $base-font-family;
            text-align: left;
            font-weight: $weight-bold;
            margin-top: 15px;
            color: $black;
            text-decoration: none;
            margin-block-end: 0;
    
            &:hover {
                color: $blue;
            }
    
            @media screen and (max-width: $small-breakpoint) {
                @include font-size(18);
                @include line-height(18);
            }
        }
    }
}
