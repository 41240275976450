.card-profile-container {
    display: flex;
    align-items: center;

    .bio-container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .gamertag {
            @include headerFont;
            margin: 0;
            @include font-size(24);
            @include line-height(24);
        }

        .hs-name {
            margin: 0;
            margin-top: 3px;
            text-transform: uppercase;
            font-weight: $weight-bold;
        }

        .location {
            margin: 0;
            margin-top: 2px;
            color: $base-text-on-white;
            @include font-size(14);
            @include line-height(14);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .bio-container {
            .gamertag {
                @include font-size(20);
                @include line-height(20);
            }
        }
    }
}
