.ticket {
    color: $base-dark-tone;
    background-color: $white;
    border-radius: $border-radius;
    padding: $base-padding-half;
    cursor: pointer;
    border: $border-thickness solid $off-white;
    text-align: left;
    font-family: $base-font-family;

    &.active {
        border-color: $blue;
    }

    &.disabled {
        background-color: rgba(255, 255, 255, 0.5);
        cursor: default;
    }

    .already-registered {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        .registered {
            @include font-size(14);
            @include line-height(14);
            text-transform: uppercase;
            font-weight: $weight-bold;
            margin: 0;
            padding-right: 10px;
        }

        .registered-link {
            @include font-size(14);
            @include line-height(14);
            text-transform: uppercase;
            font-weight: $weight-bold;
            margin: 0;
            color: $blue;
            text-decoration: none;
            margin-left: auto;
            @include transition;

            &:hover {
                color: $blue-hover;
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
            flex-direction: column;
            align-items: flex-start;

            .registered-link {
                margin: 0;
            }
        }
    }

    .title-container {
        display: flex;

        .ticket-title {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            font-weight: $weight-black;
            text-transform: uppercase;
            padding-right: 20px;
        }

        .ticket-price {
            @include font-size(18);
            @include line-height(18);
            margin: 0;
            margin-left: auto;
            flex-shrink: 0;
            font-weight: $weight-black;
        }
    }
    .ticket-description {
        @include font-size(15);
        @include line-height(20);
        margin: 0;
        margin-top: 10px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .title-container {
            flex-direction: column;

            .ticket-price {
                margin-left: 0;
                margin-top: 3px;
            }
        }
    }
}
