.create-team-content {
    display: grid;
    gap: $base-padding-half;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .input-field {
        border-color: $off-white;

        &:focus {
            border-color: $blue;
        }
    }

    .select__control {
        border-color: $off-white;

        &:hover {
            border-color: $blue;
        }

        &.select__control--is-focused,
        &:active,
        &:focus {
            border-color: $blue;
        }
    }

    .select__menu {
        z-index: 2;
    }

    .button-container {
        display: grid;
        gap: $base-padding-half;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .full {
            grid-column: span 1;
        }

        .button {
            width: 100%;
            max-width: 250px;
            margin: 0 auto;
            height: 50px;
            border-radius: 25px;
            @include font-size(16);
        }

        @media screen and (max-width: $mobile-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .invite-players-input-container {
        @include light-card;
        display: flex;
        flex-direction: column;
        box-shadow: none;
        padding: 0;
        gap: 10px;
    
        .title-container {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 10px;
            .tag-select-label {
                margin: 0;
                margin-left: 20px;
                @include font-size(14);
                @include line-height(14);
                z-index: 1;
    
                span {
                    margin-left: 15px;
                    @include font-size(12);
                    color: $base-text-on-white;
                }
            }
    
            .required {
                z-index: 1;
                color: $blue;
                @include font-size(24);
                font-weight: $weight-bold;
                position: absolute;
                left: 0px;
                top: -3px;
            }
        }
    
        .tag-select-container {
            width: 100%;
            .tag-select__control {
                border: $border-thickness solid $off-white;

                &.tag-select__control--is-focused,
                &:active,
                &:focus {
                    border-color: $blue;
                }
            }

            &.error {
                .tag-select__control {
                    border-color: $red;
                    background-color: $light-red;
                }

                .tag-select__menu {
                    border-color: $red;
                    background-color: $light-red;
                }
            }
        }
    }

    .roster {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title-label {
            @include font-size(16);
            font-weight: $weight-medium;
        }

        .players-list {
            display: flex;
            flex-direction: column;
            gap: 5px;
            
            .player-card {
                background-color: $off-white;
                border-radius: $border-radius;
                display: flex;
                gap: $base-padding-half;
                padding: 10px;
                align-items: center;

                .player-info {
                    display: flex;
                    gap: 10px;
                    font-weight: $weight-medium;
                    
                    i {
                        font-weight: $weight-bold;
                    }
                }

                .controls-container {
                    margin-left: auto;

                    .control-button {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}