.upcoming-events-card {
    @extend .dashboard-card;
    width: 100%;
    flex-shrink: 0;
    margin-top: $base-grid-padding;
    margin-bottom: $base-grid-padding;

    .content {
        overflow-x: hidden;
        overflow-y: auto;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        @include customScrollbarOnWhite;
        @import "../../events/event-card.scss";
        @import "../dashboard-event-cards.scss";
    }

    @media screen and (max-width: 1850px) {
        .content {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            .event-card {
                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        margin-top: $base-padding-half;
        margin-bottom: $base-padding-half;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .content {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}
