.product-features {
    width: 100%;
    position: relative;
    background-color: $white;
    background-color: $base-dark-tone;
    @include defaultContainerPadding;
    background-image: url("/images/SPIN_Icon_BG_Straight.png");
    background-repeat: repeat;
    background-size: 100%;
    background-position: top;

    .pricing {
        @include defaultContainerPadding;
        max-width: $slim-site-width;

        .title {
            @include headerFont;
            @include font-size(50);
            @include line-height(50);
            margin: 0;
            color: $white;
            position: relative;
            z-index: 2;
            text-align: center;

            &.product {
                margin-bottom: $base-padding-half;
            }
        }

        .background-icon {
            width: 2000px;
            height: 2000px;
            position: absolute;
            top: -200px;
            left: -300px;
            transform: rotate(15deg);
            fill: $purple;
            opacity: 0.1;
        }

        @import "../journey/journey-pricing-feature-comparison.scss";
        @import "../journey/journey-questions.scss";
        @import "./pricing-card-toggle.scss";

        @media screen and (max-width: $medium-breakpoint) {
            .title {
                @include font-size(40);
                @include line-height(40);
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            .title {
                @include font-size(30);
                @include line-height(30);
            }
        }

        @media screen and (max-width: $mobile-breakpoint) {
        }
    }
}
