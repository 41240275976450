.notification-card {
    display: flex;
    border: $border-thickness solid $off-white;
    border-radius: $border-radius;
    padding: 10px;
    align-items: center;
    position: relative;

    .image-wrapper {
        @include image-wrapper(40px, 40px, 100%, 5px, 1px);
        flex-shrink: 0;
    }

    .user-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        padding-right: 10px;

        .user-label {
            @include font-size(14);
            @include line-height(14);
            text-transform: uppercase;
            margin: 0;
            font-weight: $weight-black;

            .graduating-class {
                color: $purple;
            }
        }

        .user-sublabel {
            @include font-size(12);
            @include line-height(12);
            margin: 0;
            margin-top: 3px;
            @include clampLines(1);
        }
    }

    .user-controls {
        display: flex;
        flex-shrink: 0;
        margin-left: auto;

        .circle-button {
            width: 30px;
            height: 30px;

            .icon {
                width: 14px;
                height: 14px;
            }
        }

        .small-button {
            width: 120px;
            margin-left: 10px;
        }
    }

    .notification-badge {
        border: $border-thickness solid $white;
        background-color: $red;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        position: absolute;
        top: -2px;
        right: -2px;
    }
}
