.referral-code-container {
    width: 250px;
    display: flex;
    flex-direction: row;
    margin-top: $base-padding-half;

    .referral-code-form {
        display: flex;
        flex-direction: row;

        .input-field-container {
            .input-field {
                padding: 0 $base-padding-half;
                background-color: $off-dark-tone;
                color: $white;
                @include font-size(14);
                height: 40px;

                &::placeholder {
                    color: $base-text-on-dark-tone;
                }

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    border-color: $blue;
                    -webkit-text-fill-color: $base-dark-tone;
                    -webkit-box-shadow: 0 0 0px 1000px $light-blue inset;
                }
            }

            .required {
                display: none;
            }
        }

        .referral-button {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-left: 10px;
            flex-shrink: 0;

            .icon {
                transform: rotate(180deg);
                fill: $white;
            }
        }
    }
}
