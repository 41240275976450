.toggle-switch-container {
    display: flex;
    align-items: center;
    .value {
        display: flex;
        width: 30px;
        margin-left: 10px;
        @include font-size(14);
        font-weight: $weight-bold;
        text-transform: uppercase;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked {
                & + .slider {
                    background-color: $light-blue;
                    border-color: $blue;

                    &::before {
                        background-color: $blue;
                        -webkit-transform: translateX(20px);
                        -ms-transform: translateX(20px);
                        transform: translateX(20px);
                    }
                }
            }
        }

        .slider {
            width: 100%;
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $off-white;
            border: $border-thickness solid $off-dark-tone;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: $border-radius-medium;

            &::before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                left: -2px;
                bottom: -2px;
                border-radius: 100%;
                background-color: $off-dark-tone;
                @include transition;
            }
        }
    }
}
