.full-page-coming-soon {
    margin: $base-grid-padding;
    background-color: $base-dark-tone;
    border-radius: $border-radius;
    width: calc(100% - (#{$base-grid-padding} * 2));
    height: 100%;
    display: flex;
    position: relative;

    @media screen and (max-width: $small-breakpoint) {
        margin: $base-padding-half;
        width: calc(100% - (#{$base-padding-half} * 2));
    }
}

.coming-soon-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    @include linearGradient($dark-off-white, 0%, $coming-soon-white, 100%, 190deg);
    backdrop-filter: blur(1.5px);
    border-radius: $border-radius;

    align-items: center;
    justify-content: center;

    .new-coming-soon-container {
        display: flex;
        flex-direction: column;

        .new {
            display: flex;
            align-items: center;
            align-self: flex-start;
            @include font-size(12);
            @include line-height(12);
            border-radius: 9px;
            flex-shrink: 0;
            height: 18px;
            background-color: $red;
            padding: 0 10px;
            font-weight: $weight-black;
            color: $white;
            text-transform: uppercase;
        }

        .coming-soon {
            @include font-size(28);
            @include line-height(28);
            font-weight: $weight-black;
            margin: 0;
            text-transform: uppercase;
        }
    }

    .description {
        @include font-size(14);
        @include line-height(18);
        color: $coming-soon-description-text;
        margin: 0;
        margin-top: 3px;
        text-align: center;
    }

    .button {
        margin: 0 auto;
        margin-top: $base-padding-half;
        width: 100%;
        max-width: 180px;
    }
}

.coming-soon-popup {
    max-height: 300px;
    height: 100%;
    background-color: $base-dark-tone !important;

    .close-button {
        @include button;
        position: absolute;
        z-index: 5;
        right: 15px;
        top: 15px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: $white;
        border: $border-thickness solid $white;

        .icon {
            fill: $base-dark-tone;
        }

        &:hover {
            background-color: $blue;
            border-color: $blue;

            .icon {
                fill: $white;
            }
        }
    }
}
