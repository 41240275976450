.account-content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $base-padding-half;

    .button {
        width: 100%;
        max-width: 400px;
        height: 50px;
        border-radius: 25px;
        margin-top: $base-padding-half;
    }

    @media screen and (max-width: $mobile-nav-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: $small-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        .button {
            width: 100%;
            max-width: 400px;
            height: 40px;
            @include font-size(12);
            margin-top: 10px;
        }
    }
}
