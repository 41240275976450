.social-user-card {
    display: flex;
    border: $border-thickness solid $off-white;
    border-radius: $border-radius;
    padding: 10px;
    align-items: center;
    align-self: flex-start;

    .image-wrapper {
        @include image-wrapper(40px, 40px, 100%, 5px, 1px);
        flex-shrink: 0;
    }

    .user-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .user-label {
            @include font-size(14);
            @include line-height(14);
            text-transform: uppercase;
            margin: 0;
            font-weight: $weight-black;
            color: $base-dark-tone;
            text-decoration: none;
            @include transition;

            .graduating-class {
                color: $purple;
            }

            &:hover {
                cursor: pointer;
                color: $blue;
            }
        }

        .user-sublabel-container {
            display: flex;
            align-items: center;
            margin-top: 3px;

            .icon-container {
                width: 18px;
                height: 18px;
                margin-right: 5px;

                .icon {
                    width: 10px;
                    height: 10px;
                }
            }

            .user-sublabel {
                @include font-size(12);
                @include line-height(12);
                margin: 0;
            }
        }
    }

    .user-controls {
        display: flex;
        flex-shrink: 0;
        margin-left: auto;

        .circle-button {
            width: 30px;
            height: 30px;

            .icon {
                width: 14px;
                height: 14px;
            }
        }

        .small-button {
            width: 120px;
            margin-left: 10px;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;

        .user-info {
            margin-top: 10px;
            .user-label {
                text-align: center;
            }

            .user-sublabel-container {
                justify-content: center;
            }
        }

        .user-controls {
            margin: 0 auto;
            margin-top: 10px;
        }
    }
}
