.parent-information-container {
    display: flex;
    flex-direction: column;
    background-image: url("/images/SPIN_Information_Collection_Background.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top right;    
    min-height: calc(100vh - #{$navigation-height} - #{$base-padding-half});


    @import "./parents/parent-onboarding-form.scss";
    @import "./parents/parent-information-form.scss";
    @import "./parents/parent-whats-next.scss";
}
