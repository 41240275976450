.event-card-content {
    height: 100%;
    padding: 30px 20px;
    padding-top: 0;
    background-color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    display: flex;
    flex-direction: column;

    .event-title {
        @include font-size(18);
        @include line-height(18);
        font-family: $base-font-family;
        text-align: center;
        text-transform: uppercase;
        font-weight: $weight-black;
        margin: 0;
    }

    .event-description {
        margin: 0;
        text-align: center;
        color: $base-text-on-white;
        margin-top: 5px;
    }

    .games-container {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .icon-container {
            margin: 0 5px;
        }
    }

    .prize-pool {
        text-transform: uppercase;
        margin: 0;
        text-align: center;
        @include font-size(13);
        @include line-height(13);
    }

    .prize-value {
        margin: 0;
        text-align: center;
        @include font-size(18);
        @include line-height(18);
        font-weight: $weight-black;
        color: $green;
        margin-bottom: 10px;
    }

    .button {
        margin: 0 auto;
        width: 180px;
    }

    .bottom-container {
        flex-direction: column;
        margin-top: auto;
        display: flex;
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: 20px;
    }
}
