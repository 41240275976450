.statistics-card {
    @extend .dashboard-card;

    .content {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .separator {
            grid-column: span 2;
            height: 2px;
            background-color: $stat-border;
        }

        .stat {
            display: flex;

            .stat-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                background-color: $stat-icon-background;
                width: 28px;
                height: 28px;
                border-radius: 100%;
                margin-right: 10px;

                .icon {
                    fill: $blue;
                    width: 16px;
                    height: 16px;

                    &.views,
                    &.points {
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            .stat-info {
                display: flex;
                flex-direction: column;

                .stat-value {
                    color: $white;
                    font-weight: $weight-bold;
                    @include font-size(24);
                    @include line-height(24);
                    margin: 0;
                }

                .stat-label {
                    margin: 0;
                    margin-top: 8px;
                    color: $base-text-on-dark-tone;
                    text-transform: uppercase;
                    @include font-size(12);
                    @include line-height(12);
                }
            }
        }
    }
}
