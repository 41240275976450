.ind-partners-quote {
    background-color: $black;
    @include defaultContainerPadding;

    &.coke-quote {
        background-image: url('/images/ind-partners/coke-moisture.jpg');
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
    }

    &.ihsea-quote {
        background-image: url('/images/pattern-spin.jpeg');
        background-position: center;
        background-size: 33%;
        background-repeat: repeat;
    }

    .content-quotes {
        display: flex;
        flex-direction: row;

        .content-icon-box {
            display: flex;
            min-width: 100px;
            align-items: center;

            .icon {
                margin: 0 auto;
                fill: $white;
                width: 50px;
                height: 50px;
            }

            @media screen and (max-width: $small-breakpoint) {
                display: none;
            }
        }

        .middle-content {
            display: flex;
            flex-direction: column;
            max-width: $slim-site-width;
            margin: 0 auto;

            .quotes {
                display: flex;
                flex-direction: row;

                .single-quote {
                    display: flex;
                    flex-direction: column;

                    &.hidden {
                        display: none;
                    }

                    .part-quote {
                        @include font-size(18);
                        @include line-height(24);
                        color: $white;
                    }

                    .part-person {
                        @include font-size(18);
                        @include line-height(24);
                        color: $white;
                        font-weight: 600;
                        margin: $base-padding-half auto;
                    }

                    @media screen and (max-width: $small-breakpoint) {
                        .part-quote {
                            @include font-size(14);
                            @include line-height(20);
                        }

                        .part-person {
                            @include font-size(14);
                            @include line-height(20);
                        }
                    }
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: $base-padding;

                .button {
                    &:last-child {
                        margin-left: 5px;
                    }
                }

                @media screen and (max-width: $small-breakpoint) {
                    margin-top: $base-padding-half;
                }
            }
        }
    }
}
