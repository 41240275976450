.user-controls {
    display: flex;
    margin-left: auto;

    .button {
        margin-left: $base-padding-half;
    }

    @media screen and (max-width: $small-breakpoint) {
        display: none;
    }
}
