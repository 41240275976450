.follow-container {
    display: grid;
    padding: $base-grid-padding;
    gap: $base-grid-padding;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-height: 100%;

    @import "./college-following.scss";
    @import "./player-following.scss";

    @media screen and (max-width: $medium-breakpoint) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        .college-following-card {
            grid-column: span 1;

            .content {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding-half;
        gap: $base-padding-half;
        max-height: none;

        .college-following-card,
        .player-following-card {
            .content {
                &.inactive {
                    max-height: 400px;
                }
            }
        }
    }
}
