.my-player-card {
    @include light-card;
    display: flex;
    align-items: center;

    .name-container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .username {
            @include headerFont;
            @include font-size(24);
            @include line-height(24);
            margin: 0;
        }
        .name {
            @include font-size(14);
            @include line-height(14);
            margin: 0;
            margin-top: 3px;
            text-transform: uppercase;
            font-weight: $weight-bold;
        }
        .email,
        .expiry {
            @include font-size(12);
            @include line-height(12);
            margin: 0;
            margin-top: 3px;
        }
    }

    .image-wrapper {
        @include image-wrapper(60px, 60px, 100%);
        flex-shrink: 0;
    }

    .subscription-container {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-left: auto;

        .button {
            max-width: 100%;
            margin-top: 5px;
        }
    }

    .player-subscription {
        height: 24px;
        padding: 0 10px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        @include font-size(12);
        @include line-height(12);
        text-align: center;
        font-weight: $weight-black;
        border: $border-thickness solid $off-white;
        background-color: $white;
        border-radius: 12px;
        align-self: flex-start;
        text-transform: uppercase;
        margin-top: 3px;

        &.premium {
            border-color: $blue;
            background-color: $light-blue;
            color: $blue;
        }
    }

    .button {
        width: 100%;
        max-width: 140px;
        height: 40px;
        margin-left: auto;
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        flex-direction: column;

        .name-container {
            flex-direction: column;
            margin-left: 0;
            margin-top: 10px;
            align-items: center;
            justify-content: center;

            .username {
                text-align: center;
            }
            .name {
                text-align: center;
            }
        }

        .button {
            max-width: 160px;
            margin: 0 auto;
            margin-top: 10px;
        }

        .subscription-container {
            margin: 0 auto;
            margin-top: 10px;
        }
    }

    @media screen and (max-width: $medium-breakpoint) {
        flex-direction: row;

        .name-container {
            margin-left: 10px;
            margin-top: 0px;
            align-items: flex-start;
            justify-content: flex-start;

            .username {
                text-align: left;
            }
            .name {
                text-align: left;
            }
        }

        .button {
            margin-left: auto;
            margin-right: 0;
            margin-top: 0px;
        }

        .subscription-container {
            margin-left: auto;
            margin-right: 0;
            margin-top: 0;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;

        .name-container {
            flex-direction: column;
            margin-left: 0;
            margin-top: 10px;
            align-items: center;
            justify-content: center;

            .username {
                text-align: center;
            }
            .name {
                text-align: center;
            }
        }

        .button {
            max-width: 160px;
            margin: 0 auto;
            margin-top: 10px;
        }

        .subscription-container {
            margin: 0 auto;
            margin-top: 10px;
        }
    }
}
