.college-verification-container {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    flex-shrink: 0;
    background-color: $light-blue;
    display: flex;
    align-items: center;
    justify-content: center;

    .verification-label {
        @include font-size(14);
        @include line-height(16);
        margin: 0;
        text-align: center;
        color: $blue;
        font-weight: $weight-medium;
    }

    @media screen and (max-width: $medium-breakpoint) {
        height: 50px;
    }
    @media screen and (max-width: $small-breakpoint) {
        margin-top: $mobile-navigation-height;
        .verification-label {
            @include font-size(12);
            @include line-height(14);
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        height: 60px;
        .verification-label {
            @include font-size(10);
            @include line-height(12);
        }
    }
}
