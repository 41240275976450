.feature-group {
    .feature-list-item {
        display: grid;
        @include gridColumns(3);
        background-color: transparent;
        border-bottom: $border-thickness solid $off-white;
        min-height: 60px;
        padding: 15px 0;
        align-items: center;
        margin: 0 $base-grid-padding;

        &:last-child {
            border-bottom: 0;
        }

        &.header {
            background-color: $light-blue;
            border: 0;
            border-radius: $border-radius;
            margin: 0 15px;
            min-height: 80px;
            padding: 0 15px;
            .item-label {
                font-weight: $weight-black;
            }
        }

        .item-label {
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            font-weight: $weight-bold;
            text-transform: uppercase;
        }
        .item-description {
            @include font-size(12);
            @include line-height(16);
            margin: 0;
            margin-top: 8px;
        }

        .checkmark-container {
            margin: 0 auto;
            .icon {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background-color: $off-white;
                border: $border-thickness solid $base-dark-tone;
                fill: $base-dark-tone;
                padding: 9px;
            }

            &.blue-background {
                .icon {
                    background-color: $light-blue;
                }
            }

            &.green-background {
                .icon {
                    background-color: $light-green;
                }
            }

            &.purple-background {
                .icon {
                    background-color: $light-purple;
                }
            }
        }

        @media screen and (max-width: $medium-breakpoint) {
            margin: 0;
            &.header {
                display: flex;
                .item-info-container {
                    margin: $base-padding-half 0;
                }
            }

            .item-info-container {
                padding-left: $base-padding-half;
            }
        }
    }

    &.high-school {
        .feature-list-item {            
            @include gridColumns(2);

            .checkmark-container {
                &.free {
                    margin-left: auto;
                    margin-right: 0;
                }

                
                @media screen and (max-width: 1500px) {
                    &.free {
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        display: none;
    }

    &.college {
        .feature-list-item {
            grid-template-columns: 25% repeat(4, minmax(0, 1fr));
        
            @media screen and (max-width: $xlarge-breakpoint) {
                margin: 0;
                &.header {
                    display: flex;
                    .item-info-container {
                        margin: $base-padding-half 0;
                    }
                }
    
                .item-info-container {
                    padding-left: $base-padding-half;
                }
            }
        }

        @media screen and (max-width: $large-breakpoint) {
            display: none;
        }
    }
}
