.sign-in-container {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    top: -80px;
    background-color: $off-white;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    margin-bottom: calc(#{$base-vertical-padding} - 80px);

    .title {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
    }
    .subtitle {
        @include font-size(14);
        @include line-height(14);
        margin: 0;
        margin-top: 5px;
    }

    .content-container {
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
        display: grid;
        margin-top: $base-grid-padding;
        gap: 10px;

        .button {
            width: 100%;
        }

        .sign-up {
            background-color: transparent;
            text-transform: none;
            font-weight: $weight-regular;
            @include font-size(14);
            @include line-height(14);
            height: 30px;
            margin-bottom: $base-padding-half;

            span {
                color: $blue;
                margin-left: 5px;
            }

            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        }

        .greyed-out-container {
            opacity: 0.3;

            .blue-button {
                cursor: default;
                margin-top: $base-padding-half;
                &[disabled],
                &:disabled {
                    background-color: $blue !important;
                }
            }

            @import "./event-ticket.scss";

            .ticket {
                cursor: default;
            }
        }
    }

    @media screen and (max-width: $large-breakpoint) {
        margin-bottom: calc(#{$large-vertical-padding} - 80px);
    }

    @media screen and (max-width: $medium-breakpoint) {
        max-width: 100%;
        margin-bottom: calc(#{$medium-vertical-padding} - #{$border-radius});
        top: -$border-radius;
    }
    @media screen and (max-width: $small-breakpoint) {
        margin-bottom: calc(#{$small-vertical-padding} - #{$border-radius});
        padding: $base-padding-half;
    }
}
