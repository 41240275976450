.unsubbed-container {
    padding: $base-padding-half;
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 200px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include linearGradient($white, 0%, rgba(255, 255, 255, 0.8), 100%, 0deg);

    .cta-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            @include font-size(28);
            @include line-height(28);
            margin: 0;
            text-align: center;
            text-transform: uppercase;
            font-weight: $weight-black;
        }
        .subtitle {
            @include font-size(14);
            @include line-height(18);
            margin: 0;
            margin-top: 3px;
            text-align: center;
        }

        .button {
            width: 180px;
            margin-top: $base-padding-half;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .cta-container {
            .title {
                @include font-size(24);
                @include line-height(24);
            }
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .cta-container {
            .title {
                @include font-size(18);
                @include line-height(18);
            }
            .subtitle {
                @include font-size(12);
                @include line-height(16);
            }
            .button {
                margin-top: 10px;
            }
        }
    }
}
