.pricing-main {
    @include defaultContainerPadding;

    .container {
        .pricing-description {
            display: flex;
            flex-direction: column;
            margin: 0;
            color: $base-dark-tone;

            .product-tagline {
                @include headerFont;
                @include font-size(50);
                @include line-height(50);
                margin: 0;

                @media screen and (max-width: $medium-breakpoint) {
                    @include font-size(40);
                    @include line-height(40);
                }

                @media screen and (max-width: $small-breakpoint) {
                    @include font-size(30);
                    @include line-height(30);
                }

                // @media screen and (max-width: $mobile-breakpoint) {
                //     @include font-size(20);
                //     @include line-height(40);
                // }
            }

            .product-description {
                @include font-size(18);
                @include line-height(24);
                margin: 0;
                margin-top: 10px;

                @media screen and (max-width: $small-breakpoint) {
                    @include font-size(14);
                    @include line-height(20);
                }
            }
        }
    }

    .description-icons {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin: 0 auto;
        margin-top: $base-padding;
        justify-content: center;
        align-items: center;
        width: 60%;
        gap: 60px;

        .gamer-icon-container {
            position: relative;
            margin: 0;
            .icon {
                width: 100%;
                fill: $blue;
            }
        }

        .coach-icon-container {
            position: relative;
            margin: 0;
            .icon {
                width: 100%;
                fill: $green;
            }
        }

        @media screen and (max-width: $small-breakpoint) {
            width: 80%;
            gap: 40px;
        }

        @media screen and (max-width: $mobile-breakpoint) {
            width: 90%;
            gap: 30px;
        }
    }
}
