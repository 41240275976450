.journey-info-card {
    display: flex;
    flex-direction: column;
    gap: $base-grid-padding;
    padding: $base-padding-half;
    background-color: $base-dark-tone;
    flex-grow: 1;
    border-radius: $border-radius;
    justify-content: space-evenly;

    .grad-class {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: auto;

        .label {
            @include headerFont;
            @include font-size(30);
            @include line-height(30);
            color: $white;
            white-space: nowrap;
            margin: 0;
        }

        .content {
            @include font-size(24);
            @include line-height(30);
            font-weight: $weight-bold;
            color: $purple;
            white-space: nowrap;
            margin: 0;
        }
    }

    .game-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: $base-grid-padding;

        .text-wrapper {
            display: flex;
            flex-direction: column;

            .label {
                @include headerFont;
                @include font-size(30);
                @include line-height(30);
                color: $white;
                white-space: nowrap;
                margin: 0;
            }

            .content {
                @include font-size(18);
                @include line-height(24);
                color: $white;
                white-space: nowrap;
                margin: 0;
            }
        }
    }
}
