.schools-of-interest {
    @include light-card;
    display: flex;
    flex-direction: column;
    padding: $base-padding-half;
    box-shadow: none;
    gap: 10px;

    .title-container {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 10px;
        .tag-select-label {
            margin: 0;
            margin-left: 20px;
            @include font-size(14);
            @include line-height(14);
            z-index: 1;

            span {
                margin-left: 15px;
                @include font-size(12);
                color: $base-text-on-white;
            }
        }

        .required {
            z-index: 1;
            color: $blue;
            @include font-size(24);
            font-weight: $weight-bold;
            position: absolute;
            left: 0px;
            top: -3px;
        }
    }

    .select-field-container {
        width: 100%;
        .select-container {
            width: 100%;
            .select__control {
                border: $border-thickness solid $off-white;

                &.select__control--is-focused,
                &:active,
                &:focus {
                    border-color: $blue;
                }
            }

            &.error {
                .select__control {
                    border-color: $red;
                    background-color: $light-red;
                }

                .select__menu {
                    border-color: $red;
                    background-color: $light-red;
                }
            }
        }
    }
}
