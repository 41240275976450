@import "./event-landing-body.scss";
@import "./event-landing-body-wide.scss";
@import "./event-landing-body-wide2.scss";
@import "./event-landing-card-image.scss";
@import "./event-landing-card-image-sponsors.scss";
@import "./event-landing-description.scss";
@import "./event-landing-card-colleges.scss";
@import "./event-landing-card-article.scss";

.venue-title {
    @include headerFont;
    @include font-size(60);
    text-align: center;
    margin-bottom: 0;
}