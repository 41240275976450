.ind-partners-description {
    @include defaultContainerPadding;

    .container {
        &.slim {
            max-width: $slim-site-width;
        }

        .partners-tagline {
            @include headerFont;
            @include font-size(50);
            @include line-height(50);
            margin: 0;
            padding-bottom: $base-padding;
            text-align: center;

            @media screen and (max-width: $medium-breakpoint) {
                @include font-size(40);
                @include line-height(40);
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(30);
                @include line-height(30);
            }
        }

        .partners-description {
            display: flex;
            flex-direction: column;
            margin: 0;
            color: $base-dark-tone;
            @include font-size(18);
            @include line-height(24);
            padding-bottom: $base-padding-half;

            &:last-child {
                padding-bottom: 0;
            }

            @media screen and (max-width: $small-breakpoint) {
                @include font-size(14);
                @include line-height(20);
            }
        }

        .button {
            margin: 0 auto;
        }
    }
}
