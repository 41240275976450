.college-card {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    text-decoration: none;
    color: $base-dark-tone;
    background-color: $white;
    @include baseShadowOnWhite;

    @import "./college-card-content.scss";

    .scholarships {
        width: 240px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        text-transform: uppercase;
        font-weight: $weight-black;
        background-color: $green;
        margin: 0 auto;
        text-align: center;
        border-bottom-left-radius: $border-radius-medium;
        border-bottom-right-radius: $border-radius-medium;
        @include font-size(14);
    }
}
