.response-selector {
    border-radius: $border-radius;
    border: $border-thickness solid $light-blue;
    background-color: $white;
    position: absolute;
    bottom: calc(100% + 2px);
    width: auto;
    z-index: 2;
    overflow: hidden;
    @include baseShadowOnWhiteReversed;

    .content {
        max-width: 600px;
        max-height: calc(100vh - (#{$navigation-height * 2}) - 30px - 60px - 60px - 15px);
        padding: 15px;
        padding-bottom: 5px;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        .response-button {
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 7px 15px;
            text-transform: none;
            font-weight: $weight-regular;
            background-color: $light-blue;
            text-align: left;

            &:hover {
                background-color: $blue;
                color: $white;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .content {
            max-width: 600px;
            max-height: calc(100vh - (#{$mobile-navigation-height * 2}) - 30px - 60px - 60px - 15px);
        }
    }
}
