.mobile-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 400px;
    height: 100%;
    border-top: $border-thickness solid $off-white;
    background-color: $white;
    padding: $base-padding;
    padding-top: 0;
    z-index: 10001;
    display: none;
    flex-direction: column;
    @include transition;
    transform: translateX(-100%);

    .mobile-nav-header {
        display: flex;
        align-items: center;
        height: $navigation-height;
        margin-bottom: $base-padding-half;

        .brand {
            flex-shrink: 0;
            .spin-logo {
                fill: $base-dark-tone;
            }
        }
        .close-button {
            width: 40px;
            height: 40px;
            background: transparent;
            outline: 0;
            border: 0;
            cursor: pointer;
            margin-left: auto;
            align-items: center;
            justify-content: right;
            -webkit-tap-highlight-color: transparent;
            .icon {
                fill: $blue;
            }
        }
    }

    &.open {
        transform: translateX(0);
    }

    @media screen and (max-width: $mobile-nav-breakpoint) {
        display: flex;
    }

    .navigation-item {
        text-transform: uppercase;
        color: $base-dark-tone;
        text-decoration: none;
        font-weight: $weight-bold;
        @include font-size(16);
        line-height: 40px;
        @include transition;

        &:hover,
        &.active {
            color: $blue;
        }
    }

    .mobile-nav-sub-list {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        .navigation-item-sub {
            text-transform: uppercase;
            color: $base-dark-tone;
            text-decoration: none;
            font-weight: $weight-bold;
            @include font-size(12);
            line-height: 30px;
            @include transition;

            &:hover,
            &.active {
                color: $blue;
            }
        }
    }

    .user-controls {
        margin-top: auto;
        display: grid;
        gap: $base-padding-half;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .button {
            width: 100%;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        padding: $base-padding $base-padding-half;
        padding-top: 0;
    }
}
