.our-team-card {
    display: flex;
    flex-direction: column;
    background-color: $base-dark-tone;
    border-radius: $border-radius;
    padding: $base-grid-padding;
    gap: 10px;

    .high-school-name {
        @include headerFont;
        @include font-size(18);
        @include line-height(24);
        margin: 0;
        color: $blue;
        text-decoration: none;
        @include transition;

        &:hover {
            color: $blue-hover;
        }
    }

    .team-name {
        @include headerFont;
        @include font-size(32);
        @include line-height(32);
        margin: 0;
        color: $white;
    }

    .game {
        display: flex;
        align-items: center;
        color: $white;

        .icon-container {
            width: 40px;
            height: 40px;
            margin-right: 10px;

            .icon {
                width: 22px;
                height: 22px;
            }
        }
    }

    .roster-label {
        @include font-size(12);
        @include line-height(14);
        text-transform: uppercase;
        margin: 0;
        color: $base-text-on-black;
    }

    .roster-list {
        display: grid;
        @include gridColumns(3);
        gap: 10px;

        &.captain {
            display: flex;
        }

        .player-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .control-button {
                flex-shrink: 0;
                cursor: pointer;
            }

            @media screen and (max-width: $mobile-breakpoint) {
                width: 100%;
            }
        }

        .info-button,
        .player {
            width: 100%;
            padding: 6px 15px;
            @include font-size(14);
            @include line-height(18);
            background-color: $off-white;
            border-radius: $border-radius;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &.captain {
                padding: 0;
                background-color: transparent;
                align-self: flex-start;
            }

            &.discord {
                .captain-name {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .captain-name {
                background-color: $off-white;
                border-radius: $border-radius;
                padding: 6px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 32px;
            }

            .discord-container {
                background-color: $discord;
                border-radius: $border-radius;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                padding: 6px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                position: relative;

                &:after {
                    width: 18px;
                    background: $discord;
                    position: absolute;
                    height: 100%;

                    content: ' ';
                    left: -9px;
                    top: 0;
                    transform: skew(-30deg);
                }
            }

            
            .icon {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                fill: $base-dark-tone;

                &.discord {
                    fill: $white;
                }
            }
            @media screen and (max-width: $mobile-breakpoint) {
            
                &.captain {
                    flex-direction: column;
    
                    .captain-name {
                        width: 100%;
                        border-radius: $border-radius;
                        padding-right: 15px;
                    }

                    &.discord {
                        .captain-name {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }

                    .discord-container {
                        width: 100%;
                        border-bottom-left-radius: $border-radius;
                        border-top-right-radius: 0;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        

    }

    @media screen and (max-width: $medium-breakpoint) {
        .roster-list {
            @include gridColumns(2);
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .game {
            .icon-container {
                width: 34px;
                height: 34px;
                margin-right: 10px;

                .icon {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .roster-list {
            @include gridColumns(1);
        }
    }
}
