.profile-picture-container {
    width: 100%;
    display: flex;
    align-items: center;
    border: $border-thickness solid transparent;
    @include transition;

    .profile-picture-wrapper {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        z-index: 1;
        position: relative;

        img {
            border: $border-thickness solid $white !important;
            border-radius: 100%;
        }
    }

    .button {
        margin-left: 5px;

        &:hover {
            background-color: $red;
            border-color: $red;
        }
    }
}
