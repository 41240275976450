.event-card {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    text-decoration: none;
    color: $base-dark-tone;
    @include baseShadowOnWhite;
    @import "./event-card-header.scss";
    @import "./event-card-content.scss";
}
