@media screen and (max-width: $medium-breakpoint) {
    h1 {
        @include font-size(36);
        @include line-height(36);
    }
    h2 {
        @include font-size(28);
        @include line-height(28);
    }
    h3 {
        @include font-size(20);
        @include line-height(20);
    }
    h4 {
        @include font-size(16);
        @include line-height(16);
    }
    h5 {
        @include font-size(14);
        @include line-height(14);
    }
    h6 {
        @include font-size(12);
        @include line-height(12);
    }

    p,
    li {
        @include font-size(16);
        @include line-height(22);
    }
}

@media screen and (max-width: $small-breakpoint) {
    h1 {
        @include font-size(28);
        @include line-height(28);
        margin-bottom: 1.25rem;
    }
    h2 {
        @include font-size(24);
        @include line-height(24);
        margin-bottom: 1.25rem;
    }
    h3 {
        @include font-size(18);
        @include line-height(18);
        margin-bottom: 1.25rem;
    }
    h4 {
        @include font-size(14);
        @include line-height(14);
        margin-bottom: 1.25rem;
    }
    h5 {
        @include font-size(13);
        @include line-height(13);
        margin-bottom: 1.25rem;
    }
    h6 {
        @include font-size(12);
        @include line-height(12);
        margin-bottom: 1.25rem;
    }
    li {
        @include font-size(14);
        @include line-height(20);
    }
    p {
        @include font-size(14);
        @include line-height(20);
        margin-bottom: 1.25rem;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    h1 {
        @include font-size(24);
        @include line-height(24);
        margin-bottom: 1rem;
    }
    h2 {
        @include font-size(20);
        @include line-height(20);
        margin-bottom: 1rem;
    }
    h3 {
        @include font-size(16);
        @include line-height(16);
        margin-bottom: 1rem;
    }
    h4 {
        @include font-size(12);
        @include line-height(12);
        margin-bottom: 1rem;
    }
    h5 {
        @include font-size(12);
        @include line-height(12);
        margin-bottom: 1rem;
    }
    h6 {
        @include font-size(11);
        @include line-height(1);
        margin-bottom: 1rem;
    }
    p {
        @include font-size(14);
        @include line-height(20);
        margin-bottom: 1rem;
    }
    li {
        @include font-size(14);
        @include line-height(20);
        margin-bottom: 0.5rem;
    }
}
