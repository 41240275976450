.games-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .game {
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-top: 10px;

        .icon-container {
            width: 40px;
            height: 40px;
            margin-right: 10px;

            .icon {
                width: 22px;
                height: 22px;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .game {
            .icon-container {
                width: 34px;
                height: 34px;
                margin-right: 10px;

                .icon {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
