.accordion {
    display: flex;

    .accordion-items {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        margin-top: $base-padding;

        .accordion-item {
            display: flex;
            flex-direction: column;
            background-color: $white;
            margin-bottom: $base-padding-half;
            color: $base-dark-tone;
            border-radius: $border-radius;
            width: 100%;
            overflow: hidden;

            &:last-child {
                margin-bottom: 0;
            }

            .question {
                display: flex;
                flex-direction: row;
                margin: 0;
                padding: $base-padding;
                min-width: 100%;
                justify-content: space-between;
                cursor: pointer;

                &.active {
                    border-bottom: $border-thickness solid $off-white;
                }

                .question-text {
                    flex-basis: 80%;
                    @include font-size(30);
                    @include line-height(30);
                    font-weight: 500;
                    margin: 0;
                }

                .icon {
                    flex-basis: 10%;
                    fill: $base-dark-tone;
                    transform: rotate(90deg);
                    align-self: center;
                    width: 20px;
                    height: 20px;
                    @include transition;

                    &.green {
                        fill: $blue;
                        transform: rotate(180deg);
                    }
                }
            }

            .answer {
                display: flex;
                // padding: $base-padding;

                .answer-text {
                    @include font-size(18);
                    @include line-height(24);
                    padding: $base-padding;
                    margin: 0;
                }
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .accordion-items {
            margin-top: $mobile-base-padding;

            .accordion-item {
                margin-bottom: $mobile-base-padding;

                .question {
                    padding: $mobile-base-padding;

                    .question-text {
                        @include font-size(20);
                        @include line-height(20);
                    }

                    .icon {
                        width: 15px;
                        height: 15px;
                    }
                }

                .answer {
                    .answer-text {
                        @include font-size(14);
                        @include line-height(18);
                        padding: $mobile-base-padding;
                    }
                }
            }
        }
    }
}
