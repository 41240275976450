.location {
    padding-bottom: 66.67%;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    width: 100%;
    margin-top: 20px;
    border-radius: $border-radius;

    .map-wrapper {
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        border-radius: $border-radius;
        img {
            border-radius: $border-radius;
        }
    }
}
