.invite-colleague-form-container {
    display: flex;
    flex-direction: column;

    .invite-colleague-form {
        margin-top: $base-padding-half;
        display: grid;
        gap: $base-padding-half;
        .tag-select-container {
            .tag-select__control {
                @include font-size(14);
                border-color: $off-white;

                &.tag-select__control--is-focused {
                    border-color: $blue;
                }
            }
        }

        .button {
            width: 100%;
            max-width: 240px;
            height: 50px;
            border-radius: 25px;
            margin-left: auto;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        .invite-colleague-form {
            margin-top: 10px;
            gap: 10px;
            .button {
                height: 40px;
                max-width: 180px;
            }
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        .invite-colleague-form {
            .button {
                margin: 0 auto;
            }
        }
    }
}
