.article-skeleton {
    display: flex;
    padding-top: 56.25%;
    border-radius: $border-radius;
    position: relative;
    overflow: hidden;
    .react-loading-skeleton {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
}

.stats-skeleton {
}

.recruits-skeleton {
    margin-top: $base-grid-padding;
    height: 550px;
    .react-loading-skeleton {
        border-radius: $border-radius;
        height: 100%;
    }
}

.event-card-skeleton {
    height: 340px;
    .react-loading-skeleton {
        border-radius: $border-radius;
        height: 100%;
    }
}
