.college-information-container {
    display: flex;
    flex-direction: column;
    background-image: url("/images/SPIN_Information_Collection_Background.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top right;

    @import "./college-profile-form/college-onboarding-form.scss";
    @import "./college-profile-form/college-information-form.scss";
    @import "./college-profile-form/college-whats-next.scss";
}
