.toggle-container {
    display: flex;
    background-color: $off-dark-tone-darker;
    padding: 15px;
    height: 80px;
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    border-radius: 40px;
    margin-top: $base-grid-padding;

    .button {
        @include button;
        @include font-size(18);
        width: 100%;
        max-width: 150px;
        height: 50px;
        border-radius: 25px;
        color: $white;
        background-color: transparent;

        &:hover {
            color: $blue;
        }

        &.active {
            background-color: $blue;
            color: $white;
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        height: 60px;
        padding: 10px;

        .button {
            height: 40px;
            @include font-size(14);
        }
    }
}
