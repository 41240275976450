.find-colleges-results {
    display: flex;
    flex-direction: column;
    gap: $base-padding-half;
    padding: $base-padding-half;
    max-height: calc(100vh - 102px - #{$base-padding} - 92px);
    overflow: auto;

    .no-results-found {
        @include font-size(18);
        @include line-height(24);
        margin: 0;
        font-weight: $weight-black;
        text-transform: uppercase;
        text-align: center;
    }

    .results-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;

        @import "./college-preview-card.scss";

        @media screen and (max-width: $small-breakpoint) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .cta-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @import "../cta-card.scss";
    }

    @media screen and (max-width: $small-breakpoint) {
        gap: 10px;
        padding: 10px;
        .no-results-found {
            @include font-size(16);
            @include line-height(20);
        }
    }
}
