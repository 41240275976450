.tuition-container {
    display: flex;
    align-self: flex-start;
    margin-left: auto;

    .tuition-card {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-left: 10px;
        border: $border-thickness solid $off-white;
        padding: 15px;
        border-radius: $border-radius;
        min-width: 205px;
        .title {
            text-transform: uppercase;
            @include font-size(14);
            @include line-height(14);
            text-align: center;
            margin: 0;
        }

        .value {
            text-transform: uppercase;
            @include font-size(20);
            @include line-height(20);
            text-align: center;
            margin: 0;
            font-weight: $weight-black;
        }
    }

    @media screen and (max-width: $xlarge-breakpoint) {
        flex-direction: column;

        .tuition-card {
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: $xmedium-breakpoint) {
        flex-direction: row;
        margin-left: 0;
        margin-top: 10px;

        .tuition-card {
            margin: 0;
            margin-top: 10px;
            margin-right: 10px;
        }
    }
    @media screen and (max-width: $small-breakpoint) {
        width: 100%;
        .tuition-card {
            min-width: calc(50% - 5px);
            &:last-child {
                margin-right: 0;
            }
        }
    }
    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        flex-direction: column;
        .tuition-card {
            min-width: 100%;
            margin-right: 0;
            padding: 10px;
        }
    }
}
