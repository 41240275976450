.pricing-feature-comparison {
    background-color: $white;
    border-radius: $border-radius;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: $base-padding;
    width: 100%;
    position: relative;
    z-index: 2;
    @include baseShadowOnWhite;

    @import "./feature-header.scss";
    @import "./feature-group.scss";
    @import "./mobile-journey/feature-list-mobile.scss";

    @media screen and (max-width: $medium-breakpoint) {
        margin-top: $base-padding-half;
        .feature-header {
            margin: 0;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            .filler {
                display: none;
            }
        }
    }
}
