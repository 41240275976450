.date-picker-popup-container {
    width: 100%;

    .date-picker-popup {
        width: 100%;
        font-family: $base-font-family;
        border: $border-thickness solid $blue;
        border-radius: $border-radius;
        overflow: hidden;

        .react-datepicker__triangle {
            &::before {
                border-bottom-color: $blue;
                top: -2px;
            }

            &::after {
                border-bottom-color: $white;
            }
        }

        .react-datepicker__month-container {
            width: 100%;
            .react-datepicker__header {
                background-color: $white;
                border-bottom: $border-thickness solid $off-white;
            }

            .react-datepicker__current-month {
                @include line-height(24);
            }
        }

        .react-datepicker__day {
            font-weight: $weight-medium;
            &:hover {
                background-color: $off-white;
            }

            &.react-datepicker__day--keyboard-selected {
                background-color: $blue;
            }
            &.react-datepicker__day--outside-month {
                color: $base-text-on-white;
                font-weight: $weight-regular;
            }
        }

        .react-datepicker__day-name {
            font-weight: $weight-medium;
        }
    }
}
