.checkbox-container {
    display: flex;
    flex-direction: row;

    &.disabled {
        opacity: 0.4;
        label {
            cursor: not-allowed;
        }
    }

    label {
        display: flex;
        align-items: center;
        width: 100%;
        @include noHighlight;
        cursor: pointer;

        .checkbox-label {
            @include font-size(16);
            display: inline;

            a {
                color: $blue;
                text-decoration: none;

                &:hover {
                    color: $blue-hover;
                    text-decoration: underline;
                }
            }
        }
    }

    .checkbox {
        position: absolute;
        left: -100vw;
    }

    .checkbox-display {
        margin-right: 10px;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        background-color: $white;
        border: $border-thickness solid $white;
        border-radius: $border-radius-medium;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition;

        .icon {
            width: 18px;
            height: 18px;
            fill: $base-dark-tone;
            opacity: 0;
            @include transition;
        }

        &.active {
            border-color: $blue;

            .icon {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: $small-breakpoint) {
        label {
            .checkbox-label {
                @include font-size(14);
            }
        }
    }
}
