.player-journey-video-card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-radius;
    width: 100%;
    padding: $base-padding;
    align-self: center;

    .title {
        @include headerFont;
        @include font-size(40);
        @include line-height(40);
        margin: 0;
    }

    .yt-wrapper {
        position: relative;

        .video {
            margin-top: $base-padding-half;
            position: relative;
            padding-bottom: 56.25%;

            .video-player {
                border-radius: $border-radius;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.player-journey-video-card-no-card {
    .video {
        margin-top: 0;
        position: relative;
        padding-bottom: 56.25%;

        .video-player {
            border-radius: $border-radius;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .lower-video-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: $base-padding-half;
        gap: $base-padding-half;

        .title {
            @include headerFont;
            @include font-size(40);
            @include line-height(40);
            margin: 0;
            color: $white;
            text-align: center;
        }

        .caption {
            @include font-size(18);
            @include line-height(24);
            margin: 0;
            text-align: center;
            color: $white;
        }
    }
}
