.controller-icon {
    .cls-1 {
        fill: none;
    }

    .cls-2 {
        clip-path: url(#clip-path);
    }

    .cls-3 {
        fill: #fff;
    }

    .cls-4 {
        fill: #11bd8f;
    }
}

.coach-dollar {
    .cls-1 {
        fill: none;
    }

    .cls-2 {
        clip-path: url(#clip-path);
    }

    .cls-3 {
        fill: #fff;
    }

    .cls-4 {
        fill: #59ba92;
    }
}

.coach-scholar {
    .cls-1 {
        fill: #fff;
    }

    .cls-2 {
        clip-path: url(#clip-path);
    }

    .cls-3 {
        fill: #11bd8f;
    }
}
