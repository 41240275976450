.conversation-card {
    display: flex;
    border: $border-thickness solid $off-white;
    border-radius: $border-radius;
    padding: 8px 10px;
    align-items: center;
    position: relative;
    @include transition;
    cursor: pointer;

    &:hover {
        background-color: $light-blue;
        border-color: $light-blue;
    }

    &.active {
        background-color: $light-blue;
        border-color: $light-blue;
    }

    .image-wrapper {
        @include image-wrapper(40px, 40px, 100%, 5px, 1px);
        flex-shrink: 0;
    }

    .notification-badge {
        border: $border-thickness solid $white;
        background-color: $red;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        position: absolute;
        top: -2px;
        right: -2px;
    }

    .user-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;

        .username-timestamp {
            display: flex;
            width: 100%;
            .username {
                @include font-size(14);
                @include line-height(14);
                text-transform: uppercase;
                margin: 0;
                font-weight: $weight-black;
                @include clampLines(1);
                padding-right: 5px;
            }
            .timestamp {
                @include font-size(12);
                @include line-height(12);
                margin: 0;
                @include clampLines(1);
                margin-left: auto;
                flex-shrink: 0;
            }
        }

        .last-message {
            @include font-size(12);
            @include line-height(12);
            margin: 0;
            margin-top: 3px;
            @include clampLines(1);
            color: $base-text-on-white;
        }
    }
}
