.chat-bubbles-container {
    width: 100%;
    display: grid;
    gap: $base-padding-half;
    margin-top: auto;

    .chat-bubble {
        display: flex;
        width: 100%;

        .image-wrapper {
            @include image-wrapper(40px, 40px, 100%, 5px, 1px);
            flex-shrink: 0;
            margin-right: 10px;
            margin-top: auto;
            margin-bottom: 12px;
        }

        &.admin {
            .message-container {
                max-width: 100%;

                @import "./admin-formatting.scss";
            }
        }

        &.my-chat-bubble {
            .message-container {
                margin-left: auto;
                max-width: 50%;
                .bubble {
                    align-self: flex-end;
                    background-color: $blue;
                    color: $white;
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: 0;
                }

                .message-stamp {
                    margin-left: auto;
                }
            }
        }

        .message-container {
            display: flex;
            flex-direction: column;
            max-width: calc(50% - 50px);

            .bubble {
                align-self: flex-start;
                border-radius: $border-radius;
                border-bottom-left-radius: 0;
                background-color: $light-blue;
                padding: 15px $base-padding-half;
                white-space: pre-line;
                @include font-size(14);
                @include line-height(18);
            }

            .message-stamp {
                display: flex;
                @include font-size(12);
                @include line-height(12);
                color: $base-text-on-white;
                margin-top: 2px;
                align-items: center;

                .author {
                    color: $base-dark-tone;
                    font-weight: $weight-medium;
                    margin-right: 2px;
                }
                .timestamp {
                    margin-left: 2px;
                }
            }
        }
        @media screen and (max-width: $small-breakpoint) {
            .message-container {
                max-width: calc(90% - 50px);
            }
            &.my-chat-bubble {
                .message-container {
                    max-width: 90%;
                }
            }
        }
        @media screen and (max-width: $mobile-breakpoint) {
            .message-container {
                max-width: calc(100% - 50px);
            }
            &.my-chat-bubble {
                .message-container {
                    max-width: 100%;
                }
            }
        }
    }
}
